import type { FC } from 'react'
import { useEffect } from 'react'
import useAuth from '../../../hooks/useAuth'
import gtm from '../../../lib/gtm'
import { AppTypeCareWell, getAppTypeByUrl } from '../../consts'
import LoginCallCenter from './LoginCallCenter'
import LoginHCenter from './LoginHCenter'
import LoginInstaller from './LoginInstaller'
import LoginOperator from './LoginOperator'

const Login: FC = () => {
  const {platform} = useAuth() as any

  useEffect(() => {
    gtm.push({event: 'page_view'})
  }, [])

  useEffect(() => {
    console.log(`getting apptype: ${getAppTypeByUrl()}`)
  }, [])

  function getComponent() {
    switch (getAppTypeByUrl()) {
      case AppTypeCareWell.OpManagerWeb: {
        return <LoginOperator />
        // break
      }
      case AppTypeCareWell.HCenterManagerWeb: {
        return <LoginHCenter />
        // break
      }
      case AppTypeCareWell.InstallerMobile: {
        return <LoginInstaller />
        // break
      }
      case AppTypeCareWell.CallCenterTablet: {
        return <LoginCallCenter />
        // break
      }
      default:
        return <div />
    }
  }
  return (
    <>
      {/* {getAppTypeByUrl() === AppTypeCareWell.HCenterManagerWeb ? <LoginHCenter /> : <LoginOperator /> } */}
      {getComponent()}
    </>
  )
}

export default Login
