import { Box, Container } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import useAuth from '../../../hooks/useAuth'
import { checkToken } from '../../utils/tokenChecker'
import useMounted from '../../../hooks/useMounted'
import useSettings from '../../../hooks/useSettings'
import gtm from '../../../lib/gtm'
import { apiClient } from '../../api/common'
import PageHeader from '../../components/dashboard/PageHeader'
import HCenterManagerQuitListTable from '../../components/dashboard/users/HCenterManagerQuitListTable'
import UserListSearch from '../../components/dashboard/users/UserListSearch'
import { CONTENT_LAYOUT_PY, PAGE_LIMITS } from '../../consts'
import { hCenterManagerQuitSearchOptions } from '../../options'
import { HCenterManager } from '../../types/user'
import HCenterManagerQuitListTableV2 from 'src/__CareWell__/components/dashboard/users/HCenterManagerQuitListTableV2'
import UserListSearchV2 from 'src/__CareWell__/components/dashboard/users/UserListSearchV2'
import { USER_TYPE } from 'src/__CareWell__/commons/commons'

const sheetHeader = ['지역', '경로당', '이름', '직군', '관리자ID', '휴대폰번호', '이메일', '최근접속', '탈퇴사유', '탈퇴일']
const sheetKeyNames = [
  'public_care_center_area', 'public_care_center_name', 'public_care_username', 'public_care_job', 'public_care_admin_id', 'public_care_tel', 'public_care_email', 'public_care_recent_connection_time',
  'public_care_del_reason', 'public_care_del_date'
]
const sortColumns = [
  'public_care_center_area', 'public_care_center_name', 'public_care_username', 'public_care_admin_id', 'public_care_del_reason', 'public_care_del_date', 'public_care_del_user'
]

export default function HCenterManagerQuitList() {
  const mounted = useMounted()
  const { settings } = useSettings()
  const {logout} = useAuth();
  const navigate = useNavigate();
  const [items, setItems] = useState<HCenterManager[]>([])
  // const [needTableUpdate, setNeedTableUpdate] = useState(false)
  const [searchDone, setSearchDone] = useState(false)
  const [pageTitle, setPageTitle] = useState('경로당관리자')
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(PAGE_LIMITS[1])
  const [total, setTotal] = useState<number>(0)
  const [quitList, setQuitList] = useState<any[]>([])
  const [searchFilter, setSearchFilter] = useState<any>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    gtm.push({event: 'page_view'})
  }, [])

  // const searchUrl = '/get_silver_list_search/3'
  const searchUrl = '/get_quit_user_list'
  const searchUrlCount = '/get_quit_user_list_total_count'
  const getData = async (_page, _limit) => {
    try {
      setQuitList(null)
      const url = `${searchUrl}/${USER_TYPE.CENTER}/${_limit}/${_page}`
      const config = await checkToken(logout, navigate);
      if (!config) return;
      const res = await apiClient.get(url, config)
      // console.log(`[API ${url}]: `, res.data)
      if (mounted.current) {
        // console.log('getItems: mounted.current is valid. items: ')
        setQuitList(res.data.rows)
        setPageTitle('탈퇴된 경로당 관리자')
      }
    } catch (err) {
      console.error(err)
    }
  }

  const LoadData = async (idx: number, config:any) => {
    setQuitList(null)
    const url = `${searchUrl}/${USER_TYPE.CENTER}/${limit}/${idx}`
    // console.log(url)
    return apiClient.get(url, config)
  };
  const getDataTotal = async (_page, _limit) => {
    try {
      const url = `${searchUrlCount}/${USER_TYPE.CENTER}/${_limit}/${_page}`
      const config = await checkToken(logout, navigate);
      if (!config) return;
      const res = await apiClient.get(url, config)
      // console.log(`[API ${url}]: `, res.data)
      if (res.data.state === 'ok') {
        setTotal(res.data.result.total)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const apiSearchTableLimit = '/get_quit_user_list_search'
  const apiSearchTableLimitCount = '/get_quit_user_list_search_count'
  const searchPage = async () => {
    const SEARCH_TYPE = USER_TYPE.CENTER
    try {
      setQuitList(null)

      // filter
      let filters = {}
      filters = {...filters, user_id: searchFilter.user_id, req_type: searchFilter.req_type}
      if (searchFilter.area !== '') {
        filters = {...filters, area: searchFilter.area}
      }
      if (searchFilter.sigungu !== '') {
        filters = {...filters, sigungu: searchFilter.sigungu}
      }
      if (searchFilter.from_date !== '') {
        filters = {...filters, from_date: searchFilter.from_date}
      }
      if (searchFilter.end_date !== '') {
        filters = {...filters, end_date: searchFilter.end_date}
      }
      if (searchFilter.search_item !== '') {
        if (searchFilter.search_item === 'public_care_username') {
          filters = {...filters, public_care_username: searchFilter.search_content}
        } else if (searchFilter.search_item === 'public_care_job') {
          filters = {...filters, public_care_job: searchFilter.search_content}
        } else if (searchFilter.search_item === 'public_care_admin_id') {
          filters = {...filters, public_care_admin_id: searchFilter.search_content}
        } else if (searchFilter.search_item === 'public_care_del_reason') {
          filters = {...filters, public_care_del_reason: searchFilter.search_content}
        }
      }
      //
      const getUser = `${apiSearchTableLimit}/${SEARCH_TYPE}/${limit}/${page}`
      const getTotal = `${apiSearchTableLimitCount}/${SEARCH_TYPE}/${limit}/0`
      // console.log(getUser, getTotal, filters)
      const config = await checkToken(logout, navigate);

      // full cnt
      const cnt = await apiClient.post(getTotal, filters, config)
      // console.log(cnt)

      if (cnt.data.state === 'ok') {
        // search list
        setTotal(cnt.data.rows.total)
        const res = await apiClient.post(getUser, filters, config)
        // console.log(`[API ${getUser}]: `, res.data.rows)
        if (res.data.state === 'ok') {
          const list = res.data.rows;
          setItems(list)
          setQuitList(list)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getData(0, 50)
    getDataTotal(0, 50)
  }, [mounted])

  useEffect(() => {
    if (searchFilter == null) {
      getData(page, limit)
      getDataTotal(page, limit)
    } else {
      searchPage()
    }
  }, [page, limit, searchFilter])

  const [updateTableCounter, setUpdateTableCounter] = useState<number>(0)
  const doUpdateTable = () => { setUpdateTableCounter(updateTableCounter + 1) }
  useEffect(() => {
    getData(0, 50)
    getDataTotal(0, 50)
  }, [updateTableCounter])



  return (
    <>
      <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: CONTENT_LAYOUT_PY}}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <PageHeader title={pageTitle} paths={[]} links={[]} />

          <Box sx={{mt: 3}}>
            <UserListSearchV2 setSearchFilter={setSearchFilter} records={items} setRecords={setItems} searchUrl={searchUrl} doUpdateTable={doUpdateTable}
              categorySearch={hCenterManagerQuitSearchOptions} setSearchDone={setSearchDone}
            />
            <Box sx={{mb: 2}} />
            <HCenterManagerQuitListTableV2 records={items} sheetHeader={sheetHeader} sheetKeyNames={sheetKeyNames} sortColumns={sortColumns}
              doUpdateTable={doUpdateTable} searchDone={searchDone} page={page} limit={limit} setPage={setPage} setLimit={setLimit}
              total={total} quitList={quitList} getData={LoadData} loading={loading}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}


