import {useEffect} from 'react'
import type {FC} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Link, List, ListItem, ListItemText,
  SvgIcon,
  Typography
} from '@material-ui/core'
import AuthBanner from '../../../components/authentication/AuthBanner'
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
} from '../../../components/authentication/login'
import LoginJWT from '../../components/auth/login/LoginJWT'
import Logo from '../../../components/Logo'
import useAuth from '../../../hooks/useAuth'
import gtm from '../../../lib/gtm'
import AIconLogo from '../../icons/AIconLogo'
import {getAppTypeByUrl, getAccountComment, MAIN_COLOR} from '../../consts'
import * as React from 'react'

const POINT_COLOR = '#545454'
const desc = [
  {
    amount: 21500,
    color: POINT_COLOR, // '#6C76C4',
    name: '승인되지 않은 접속의 경우 예고 없이 권한이 삭제 될 수 있습니다.'
  },
  {
    amount: 15300,
    color: POINT_COLOR, // '#33BB78',
    name: '센터 관리자 아이디/비밀번호 찾기는 운영관리자에게 문의하세요.'
  }
]

// 물리•직업치료사•⚫

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg'
}

export default function LoginCallCenter() {
  const {platform} = useAuth() as any
  const navigate = useNavigate()

  // useEffect(() => {
  //   gtm.push({event: 'page_view'})
  // }, [])

  return (
    <>
      <Box sx={{backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        {/* <AuthBanner /> */}
        <Container maxWidth="sm" sx={{py: 4}}>
          <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', mb: 4}}>
            {/*  <CardMedia component="img" image="/static/logo.svg" alt="Live from space album cover" */}
            {/*    sx={{height: 100, bgcolor: '#FF0000', color: '#FFFF00'}} color="#FF00FF" */}
            {/*  /> */}
            {/*  <SvgIcon target="/static/logo.svg" /> */}
            <AIconLogo sx={{width: 280, height: 40, color: MAIN_COLOR}} />
          </Box>
          <Card>
            <CardContent sx={{display: 'flex', flexDirection: 'column', p: 3}}>
              <Box sx={{alignItems: 'center', display: 'flex', justifyContent: 'space-between', mb: 3}}>
                <div>
                  {/* <Typography color="textSecondary" variant="body2" display="inline">어르신 건광관리 서비스 </Typography> */}
                  <Typography color="textPrimary" gutterBottom variant="h6" display="inline">센터 관리자 로그인</Typography>
                  {/* <Typography color="textSecondary" variant="body2">{getAccountComment(getAccountTypeByUrl())}</Typography> */}
                </div>

              </Box>
              <Box sx={{flexGrow: 1, mt: 1}}>
                {platform === 'Amplify' && <LoginAmplify />}
                {platform === 'Auth0' && <LoginAuth0 />}
                {platform === 'Firebase' && <LoginFirebase />}
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider sx={{my: 3}} />
              <Box>
                <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', my: 1, mt: 2}}>
                  <Button variant="outlined" sx={{m: 1}} onClick={() => navigate('/authentication/register-terms')}>회원가입</Button>
                  <Button variant="outlined" sx={{m: 1}} onClick={() => navigate('/authentication/find')} color="secondary">아이디/비밀번호 찾기</Button>
                </Box>
              </Box>
              <List disablePadding sx={{pt: 2, pb: 1, }}>
                {
                                    desc.map((currency) => (
                                      <ListItem disableGutters key={currency.name} sx={{pb: 0, pt: 0}}>
                                        <ListItemText disableTypography
                                          primary={(
                                            <Box>

                                              {/* <Typography display="inline" justifyContent="center" variant="h3">•</Typography> */}
                                              <Typography display="inline" color="textPrimary" variant="subtitle2" component="div">
                                                <strong>{'•  '}</strong>
                                                {currency.name}
                                              </Typography>

                                            </Box>
                                                          )}
                                        />
                                      </ListItem>
                                    ))
                                }
              </List>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}

