import type { ListItemProps } from '@material-ui/core'
import { Box, Button, Collapse, ListItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useMounted from '../../hooks/useMounted'
import ChevronDownIcon from '../../icons/ChevronDown'
import ChevronRightIcon from '../../icons/ChevronRight'
import { carewellAuthApi } from '../api/carewellAuthApi'
import { apiClient } from '../api/common'
import { AccountType, NavItemPY, SIDEBAR_3TH_DEPTH_TEXT_ML, SIDEBAR_DEPTH_GAP, SIDEBAR_TEXT } from '../consts'
import { usePrevious } from '../hooks/myHooks'
import useMyApp from '../hooks/useMyApp'
import { checkToken } from '../utils/tokenChecker'

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props
  const [open, setOpen] = useState<boolean>(openProp)
  const navigate = useNavigate();

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + SIDEBAR_DEPTH_GAP * depth
  }


  const prevPath: string = usePrevious(path)
  const location = useLocation();
  const {states, setStates} = useMyApp();
  const mounted = useMounted()
  const {logout} = useAuth();
  const isHCenterManager = carewellAuthApi.getAccountType() === AccountType.HCenterManager

  const onClick = async () => {
    if (prevPath && path === prevPath) {
      // console.log(`같은 Route 클릭 ! prevPath(${prevPath}) path(${path}) depth(${depth}) `)
      if (location.pathname === '/dashboard/users/recipients') {
        const url = '/get_silver_list_all/2'
        let reqData = {}
        if (isHCenterManager) {
          reqData = {
            user_id: carewellAuthApi.getAdminId(),
            req_type: 'hcenter'
          }
        }
        const config = await checkToken(logout, navigate);
        if (!config) return;
        const res = await apiClient.post(url, reqData, config)
        // console.log(`[API ${url}]: `, res.data)
        if (mounted.current) {
          // console.log('getItems: mounted.current is valid. items: ')
          setStates({...states, silverList: res.data.list})
        }
      }
      // window.location.reload();
      // console.log(`onClick --------- path(${path}) prevPath(${prevPath}) `, prevPath)
    }
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: SIDEBAR_TEXT, // 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: NavItemPY,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{flexGrow: 1}}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    )
  }

  //
  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={icon}
        sx={{
          color: SIDEBAR_TEXT, // 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          ml: `${depth === 2 ? SIDEBAR_3TH_DEPTH_TEXT_ML : '0'}`,
          // border: '1px dashed #FF0000',
          pr: '8px',
          py: NavItemPY,
          textTransform: 'none',
          width: '100%',
          ...(
            active && {
              color: 'primary.main',
              fontWeight: 'fontWeightBold',
              '& svg': {
                color: 'primary.main'
              }
            }
          )
        }}
        variant="text"
        to={path}
        onClick={onClick}
      >
        <Box sx={{flexGrow: 1}}>
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  )
}

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
}

NavItem.defaultProps = {
  active: false,
  open: false
}

export default NavItem
