/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
  Box, Button, Card,
  Container, Grid,
  TextField, ToggleButton,
  ToggleButtonGroup, Typography
} from '@material-ui/core'
import { AccountType, CONTENT_LAYOUT_PY, TableOptions, VERTICAL_TITLE_BAR } from '../../consts'
import PageHeader from 'src/__CareWell__/components/dashboard/PageHeader'
import useSettings from '../../../hooks/useSettings'
import useMounted from 'src/hooks/useMounted'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getDateYyyyMmDd } from 'src/__CareWell__/utils/serverTools'
import { carewellAuthApi } from 'src/__CareWell__/api/carewellAuthApi'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'
import { apiClient } from 'src/__CareWell__/api/common'
import { AttendInfo, getDateToString } from './StatisticsInfoCommons';
import { HeartSpinner } from 'react-spinners-kit';
import ReactApexChart from 'react-apexcharts'
import { BodyFatData } from '../../types/user';
import StatisticsModalV2 from './modal/StatisticsModalV2'
import { BLOODPRESSUREFLAG, BLOODPRESSURE_RESULT, BLOODSUGARFLAG, BLOODSUGAR_RESULT, DATETYPE, FILTER_TYPE, FLOOR, MODAL_TYPE, WEIGHTFLAG, WEIGHT_RESULT, dateOptions } from 'src/__CareWell__/commons/commons'

const Navi = styled.div`
  margin-top: 0.5rem;

  .navmenu{
    font-family: Noto Sans KR;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
  }
  .selectfloor{
    color:#172B4D;
  }
  .noselectfloor{
    color:#5664DE;
  }
`
const Loading = styled.div`
  width:100%;
  height:100%;
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:white;
  z-index: 999;
`
const Attend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding:2rem 1.2rem;
  flex-grow: 1;
  .title {
    font-size:1.3rem;
    font-weight: 700;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align: center;
    &:hover{
      color:#5664DE;
    }
  }
  .count {
    font-size:1.5rem;
    font-weight: 700;
    &:hover{
      color:#5664DE;
    }
  }
  .left_border{
    border-left:1px solid black;
  }
`
const NoData = styled.div`
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  position: absolute;
`



const DEFAULT_AREA = '경기도 의왕시'

export default function StatisticsInfoManagementPubV2() {
  const mounted = useMounted()
  const {logout} = useAuth();
  const navigate = useNavigate();
  const { settings } = useSettings()

  const [floor, setFloor] = useState(FLOOR.SI)
  const [si, setsi] = useState(DEFAULT_AREA)
  const [dong, setDong] = useState('')
  const [center, setCenter] = useState('')
  const [floorKeyword, setFloorKeyword] = useState(DEFAULT_AREA);
  const [floorlist, setFloorList] = useState<any>([])

  const [alignment, setAlignment] = useState('')
  const [searchFromDate, setSearchFromDate] = useState('')
  const [searchEndDate, setSearchEndDate] = useState('')
  const [init, setInit] = useState(false)

  const [attendLoading, setAttendLoading] = useState(false);
  const [pressureLoading, setPressureLoading] = useState(false);
  const [sugarLoading, setSugarLoading] = useState(false);
  const [weightLoading, setWeightLoading] = useState(false);

  const [bloodPressureSeries, setBloodPressureSeries] = useState<any>([])
  const [bloodSugarSeries, setBloodSugarSeries] = useState<any>([]);
  const [bodyFatSeries, setBodyFatSeries] = useState<any>([]);

  const [modalType, setModalType] = useState(null);
  const [modalDetailType, setModalDetailType] = useState('');
  const [showOverViewModal, setShowOverviewModal] = useState(false)
  const [modalTotal, setModalTotal] = useState(0);

  const [selectModalBaseItem, setSelectModalBaseItem] = useState(0);

  const TITLE_WIDTH = 80
  const HEIGHT = 58

  // UI
  const getFromDate = () => {
    const result = `${searchFromDate} 00:00:00`
    return result;
  }
  const getEndDate = () => {
    const result = `${searchEndDate} 23:59:59`
    return result;
  }

  const handleDateOptionsChange = (
    event: MouseEvent<HTMLElement>,
    datetype: string,
  ) => {
    const from = new Date()
    const end = new Date()
    setAlignment(datetype);
    const type = parseInt(datetype, 10);
    switch (type) {
      case DATETYPE.ALL:
        setSearchFromDate('')
        setSearchEndDate('')
        break;
      case DATETYPE.WEEK:
        from.setDate(from.getDate() - 7)
        break;
      case DATETYPE.MONTH:
        from.setMonth(from.getMonth() - 1)
        break;
      case DATETYPE.THREEMONTH:
        from.setMonth(from.getMonth() - 3)
        break;
      default:
        break;
    }

    setSearchFromDate(getDateYyyyMmDd(from));
    setSearchEndDate(getDateYyyyMmDd(end))
  }
  const handleChangeFromDate = (e) => {
    if (searchFromDate > searchEndDate) setSearchEndDate(e.target.value)
    setSearchFromDate(e.target.value)
  }
  const handleChangeEndDate = (e) => {
    if (searchEndDate < searchFromDate) setSearchFromDate(e.target.value)
    setSearchEndDate(e.target.value)
  }
  const getFloorList = async () => {
    const config = await checkToken(logout, navigate);
    const endDate = searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate();
    let url = 'get_dong_list' // 시
    if (floor === FLOOR.DONG)url = 'get_seniorcenter_list' // 동
    else if (floor === FLOOR.CENTER) url = 'get_senior_center_one'; // 경로당 검색 불필요
    url = `/${url}/${floorKeyword}/${endDate}`
    return apiClient.get(url, config)
      .then((res) => {
        if (res.data.state === 'ok') {
          return res.data.result;
        }
        return [];
      });
  }

  const getUseCount = async (keyword) => {
    const config = await checkToken(logout, navigate);
    let type = FLOOR.DONG
    if (floor === FLOOR.DONG)type = FLOOR.CENTER
    else if (floor === FLOOR.CENTER) type = FLOOR.CENTER
    const url = `/get_use_attend_count/${FILTER_TYPE[type]}/${keyword}`;
    const reqData = {
      from_date: getFromDate(),
      end_date: getEndDate()
    }
    // console.log(url, reqData)
    return apiClient.post(url, reqData, config).then((res) => {
      if (res.data.state === 'ok') {
        return res.data.result.count;
      } return null
    })
  }
  const getAttendCount = async () => {
    setAttendLoading(true)
    getFloorList()
      .then((list) => {
        // console.log(list)
        const promises = list.map((item) => getUseCount(item.name).then((count) => ({
          name: item.name,
          all_count: item.count,
          attend_count: count,
        })));

        // Promise.all을 사용하여 모든 프로미스가 해결될 때까지 기다립니다.
        Promise.all(promises)
          .then((rows) => {
            setFloorList(rows);
            setAttendLoading(false);
          })
          .catch((error) => {
          // 오류 처리
            console.error('Error fetching attend counts:', error);
          });
      })
      .catch((error) => {
      // 오류 처리
        console.error('Error fetching dong list:', error);
      });
  }
  const getPressureAttendCount = async (keyword) => {
    const config = await checkToken(logout, navigate);
    const url = `/get_pressure_attend_count/${FILTER_TYPE[floor]}/${keyword}`;
    // console.log(FILTER_TYPE[floor])
    const reqData = {
      from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
      end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
    }
    return apiClient.post(url, reqData, config).then((res) => {
      // console.log(res)
      if (res.data.state === 'ok') {
        return res.data.result;
      } return null
    })
  }
  const getSBloodAttendCount = async (keyword) => {
    const config = await checkToken(logout, navigate);
    const url = `/get_sugarblood_attend_count/${FILTER_TYPE[floor]}/${keyword}`;
    const reqData = {
      from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
      end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
    }
    return apiClient.post(url, reqData, config).then((res) => {
      if (res.data.state === 'ok') {
        return res.data.result;
      } return null
    })
  }
  const getWeightAttendCount = async (keyword) => {
    const config = await checkToken(logout, navigate);
    const url = `/get_weight_attend_count/${FILTER_TYPE[floor]}/${keyword}`;
    const reqData = {
      from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
      end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
    }
    return apiClient.post(url, reqData, config).then((res) => {
      if (res.data.state === 'ok') {
        return res.data.result;
      } return null
    })
  }
  const getDeviceAttendCount = async () => {
    setPressureLoading(true)
    setSugarLoading(true)
    setWeightLoading(true)
    getPressureAttendCount(floorKeyword).then((list) => {
      if (list != null) {
        const result = [0, 0, 0]

        for (let nIdx = 0; nIdx < list.length; nIdx++) {
          if (list[nIdx].result === BLOODPRESSUREFLAG.NORMAL) result[0] = list[nIdx].count;
          else if (list[nIdx].result === BLOODPRESSUREFLAG.ROW) result[1] = list[nIdx].count;
          else if (list[nIdx].result === BLOODPRESSUREFLAG.HIGH) result[2] = list[nIdx].count;
        }
        setBloodPressureSeries(result);
        setPressureLoading(false)
      }
    })
    getSBloodAttendCount(floorKeyword).then((list) => {
      if (list != null) {
        const result = [0, 0, 0]

        for (let nIdx = 0; nIdx < list.length; nIdx++) {
          if (list[nIdx].result === BLOODSUGARFLAG.NORMAL) result[0] = list[nIdx].count;
          else if (list[nIdx].result === BLOODSUGARFLAG.ROW) result[1] = list[nIdx].count;
          else if (list[nIdx].result === BLOODSUGARFLAG.HIGH) result[2] = list[nIdx].count;
        }
        setBloodSugarSeries(result);
        setSugarLoading(false)
      }
    })
    getWeightAttendCount(floorKeyword).then((list) => {
      if (list != null) {
        const result = [0, 0, 0]

        for (let nIdx = 0; nIdx < list.length; nIdx++) {
          if (list[nIdx].result === WEIGHTFLAG.NORMAL) result[0] = list[nIdx].count;
          else if (list[nIdx].result === WEIGHTFLAG.ROW) result[1] = list[nIdx].count;
          else if (list[nIdx].result === WEIGHTFLAG.HIGH) result[2] = list[nIdx].count;
        }
        setBodyFatSeries(result);
        setWeightLoading(false);
      }
    })
  }
  const handleClickFloorItem = (name) => {
    if (floor === FLOOR.SI) {
      setFloor(FLOOR.DONG)
      setDong(name)
      setFloorKeyword(name)
    } else if (floor === FLOOR.DONG) {
      setFloor(FLOOR.CENTER)
      setCenter(name)
      setFloorKeyword(name)
    }
  }

  const getBloodPressureSeniorList = async (page, limit, type) => {
    const config = await checkToken(logout, navigate);
    const url = `/get_pressure_attend_list/${FILTER_TYPE[floor]}/${floorKeyword}/${type}/${page}/${limit}`;
    // console.log(url)
    const reqData = {
      from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
      end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
    }
    return apiClient.post(url, reqData, config).then((res) => {
      if (res.data.state === 'ok') {
        return res.data.result;
      } return null
    })
  }

  const getBloodSugarSeniorList = async (page, limit, type) => {
    const config = await checkToken(logout, navigate);
    const url = `/get_bloodsugar_attend_list/${FILTER_TYPE[floor]}/${floorKeyword}/${type}/${page}/${limit}`;
    // console.log(url)
    const reqData = {
      from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
      end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
    }
    return apiClient.post(url, reqData, config).then((res) => {
      if (res.data.state === 'ok') {
        return res.data.result;
      } return null
    })
  }


  const getWeightSeniorList = async (page, limit, type) => {
    const config = await checkToken(logout, navigate);
    const url = `/get_weight_attend_list/${FILTER_TYPE[floor]}/${floorKeyword}/${type}/${page}/${limit}`;
    // console.log(url)
    const reqData = {
      from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
      end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
    }
    return apiClient.post(url, reqData, config).then((res) => {
      if (res.data.state === 'ok') {
        return res.data.result;
      } return null
    })
  }

  const getUseSeniorListCount = async (type, item) => {
    const config = await checkToken(logout, navigate);
    const url = `/get_use_attend_deduplication_count/${type}/${item}`;
    console.log(url)
    const reqData = {
      from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
      end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
    }
    return apiClient.post(url, reqData, config).then((res) => {
      if (res.data.state === 'ok') {
        return res.data.result;
      } return null
    })
  }

  const getUseSeniorList = async (page, limit, type) => { /// selectModalBaseItem ////
    let floortype = FILTER_TYPE[0];
    if (floor === FLOOR.SI)floortype = FILTER_TYPE[FLOOR.DONG];
    else if (floor === FLOOR.DONG) floortype = FILTER_TYPE[FLOOR.CENTER];
    else if (floor === FLOOR.CENTER) floortype = FILTER_TYPE[FLOOR.CENTER];
    const config = await checkToken(logout, navigate);

    return getUseSeniorListCount(floortype, selectModalBaseItem)
      .then((data) => {
        if (data != null) setModalTotal(data.count)
        const url = `/get_use_attend_list/${floortype}/${selectModalBaseItem}/${page}/${limit}`;
        console.log(url)
        const reqData = {
          from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
          end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
        }
        return apiClient.post(url, reqData, config).then((res) => {
          if (res.data.state === 'ok') {
            return res.data.result;
          } return null
        })
      })
  }

  const doUpdateModalTable = (page, limit) => {
    if (modalType === MODAL_TYPE.PRESSURE) {
      return getBloodPressureSeniorList(page, limit, modalDetailType)
    }
    if (modalType === MODAL_TYPE.SUGAR) {
      return getBloodSugarSeniorList(page, limit, modalDetailType)
    }
    if (modalType === MODAL_TYPE.WEIGHT) {
      return getWeightSeniorList(page, limit, modalDetailType)
    }
    if (modalType === MODAL_TYPE.SENIOR) {
      return getUseSeniorList(page, limit, modalDetailType)
    }
    return null;
  }


  // const getUserPressureHistoryDetail = async (uid) => {
  //   const config = await checkToken(logout, navigate);
  //   const url = `/get_user_pressure_attend_datail/${uid}`;
  //   console.log(url)
  //   const reqData = {
  //     from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
  //     end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
  //   }
  //   return apiClient.post(url, reqData, config).then((res) => {
  //     if (res.data.state === 'ok') {
  //       return res.data.result;
  //     } return null
  //   })
  // }
  // const getUserSugarHistoryDetail = async (uid) => {
  //   const config = await checkToken(logout, navigate);
  //   const url = `/get_user_bloodsugar_attend_datail/${uid}`;
  //   console.log(url)
  //   const reqData = {
  //     from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
  //     end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
  //   }
  //   return apiClient.post(url, reqData, config).then((res) => {
  //     if (res.data.state === 'ok') {
  //       return res.data.result;
  //     } return null
  //   })
  // }
  // const getUserWeightHistoryDetail = async (uid) => {
  //   const config = await checkToken(logout, navigate);
  //   const url = `/get_user_wight_attend_datail/${uid}`;
  //   console.log(url)
  //   const reqData = {
  //     from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
  //     end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
  //   }
  //   return apiClient.post(url, reqData, config).then((res) => {
  //     if (res.data.state === 'ok') {
  //       return res.data.result;
  //     } return null
  //   })
  // }
  // const getUserBrainHistoryDetail = async (uid) => {
  //   const config = await checkToken(logout, navigate);
  //   const url = `/get_user_brain_attend_datail/${uid}`;
  //   console.log(url)
  //   const reqData = {
  //     from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
  //     end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
  //   }
  //   return apiClient.post(url, reqData, config).then((res) => {
  //     if (res.data.state === 'ok') {
  //       return res.data.result;
  //     } return null
  //   })
  // }
  const getUserHistoryDetail = async (uid) => {
    const config = await checkToken(logout, navigate);
    const fromDate = searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate();
    const endDate = searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate();

    const requests = [
      { url: `/get_user_pressure_attend_datail/${uid}`, reqData: { from_date: fromDate, end_date: endDate } },
      { url: `/get_user_bloodsugar_attend_datail/${uid}`, reqData: { from_date: fromDate, end_date: endDate } },
      { url: `/get_user_wight_attend_datail/${uid}`, reqData: { from_date: fromDate, end_date: endDate } },
      { url: `/get_user_brain_attend_datail/${uid}`, reqData: { from_date: fromDate, end_date: endDate } },
    ];
    const results = await Promise.all(requests.map(({ url, reqData }) => apiClient.post(url, reqData, config)));
    return results.map((res) => (res.data.state === 'ok' ? res.data.result : null));
  };

  useEffect(() => {
    setSearchFromDate(getDateToString(new Date()))
    setSearchEndDate(getDateToString(new Date()))
    // setSearchFromDate('2023-09-23')
    // setSearchEndDate('2023-09-25')
    setInit(!init) // 전체 초기화
  }, [mounted])

  useEffect(() => {
    getAttendCount();
    getDeviceAttendCount();
  }, [init, floorKeyword])

  const handleSearch = () => {
    setInit(!init);
  }

  // const total = config.w.config.series[config.dataPointIndex]
  // await setModalTotal(total);
  // setModalType(MODAL_TYPE.PRESSURE)
  // setModalDetailType(BLOODPRESSURE_RESULT[config.dataPointIndex])
  // setShowOverviewModal(true);
  return (
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: CONTENT_LAYOUT_PY}}>
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <PageHeader title="통계 정보 조회" paths={[]} links={[]} />
        <Grid sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Navi>
            <span className={`navmenu ${floor === FLOOR.SI ? 'noselectfloor' : 'selectfloor'}`} onClick={() => { setFloor(FLOOR.SI); setFloorKeyword(si); setsi(si) }}>{si}</span>
            {(floor === FLOOR.DONG || floor === FLOOR.CENTER) && <span className={`navmenu ${floor === FLOOR.DONG ? 'noselectfloor' : 'selectfloor'}`} onClick={() => { setFloor(FLOOR.DONG); setFloorKeyword(dong); setDong(dong); }}>{` > ${dong}`}</span>}
            {floor === FLOOR.CENTER && <span className={`navmenu ${floor === FLOOR.CENTER ? 'noselectfloor' : 'selectfloor'}`} onClick={() => { setFloor(FLOOR.CENTER); setFloorKeyword(center); setCenter(center); }}>{` > ${center}`}</span>}
            <span> 통계</span>
          </Navi>
          <Box sx={{display: 'flex', alignItems: 'center', height: HEIGHT, justifyContent: 'flex-end', mr: 2}}>
            <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">기간 검색</Typography></Box>

            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="from_date" value={searchFromDate} onChange={(e) => { handleChangeFromDate(e) }}
            />
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="end_date" value={searchEndDate} onChange={(e) => { handleChangeEndDate(e) }}
              sx={{mx: 1}}
            />
            <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleDateOptionsChange}>
              {
                  dateOptions.map((item) => (
                    <ToggleButton sx={{width: 72}} value={item.id} key={item.id}>{item.label}</ToggleButton>
                  ))
                  }
            </ToggleButtonGroup>
            <Button variant="outlined" type="button" sx={{width: 100, ml: 2}} onClick={handleSearch}>
              검색
            </Button>
          </Box>
        </Grid>
        <Card sx={{p: 2}}>
          <Grid sx={{fontSize: '1.2rem', fontWeight: 700, display: 'flex', justifyContent: 'center'}}>
            {`${floorKeyword} 출석현황`}
          </Grid>
          <Grid sx={{display: 'flex', alignItems: 'center', mt: 2, position: 'relative', minHeight: '130px', overflowX: 'auto'}}>
            {attendLoading && <Loading><HeartSpinner color="#249ece" /></Loading>}
            {
              floorlist && floorlist.map((item, index) => {
                const keyName = `${item.name}_${index}`
                return (
                  <Attend key={keyName} style={{minWidth: '200px', height: '170px', borderLeft: `${index === 0 ? '1px solid white' : '1px solid #E2E2E2'}`}}>
                    <div onClick={() => handleClickFloorItem(item.name)} className="title">{`${item.name}(${item.all_count})`}</div>
                    <div className="count" onClick={async () => {
                      setModalType(MODAL_TYPE.SENIOR)
                      setSelectModalBaseItem(item.name); // 선택한 경로당 또는 동 정보
                      setShowOverviewModal(true);
                    }}
                    >
                      {`${item.attend_count}명`}

                    </div>
                  </Attend>
                )
              })
            }
          </Grid>
        </Card>
        <Card sx={{mt: 2, p: 2}}>
          <Grid sx={{fontSize: '1.2rem', fontWeight: 700, display: 'flex', justifyContent: 'center'}}>
            어르신 건강 디바이스 측정 정보
          </Grid>
          <Grid>
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '10px',
              gridAutoRows: 'minmax(100px, auto)',
            }}
            >
              <Card sx={{
                p: 1,
                m: 1,
                position: 'relative'
              }}
              >
                {pressureLoading && <Loading><HeartSpinner color="#249ece" /></Loading>}
                {(bloodPressureSeries[0] === 0 && bloodPressureSeries[1] === 0 && bloodPressureSeries[2] === 0) && <NoData>데이터가 없습니다</NoData>}
                {
                bloodPressureSeries.length > 0
                  ? <ReactApexChart
                      type="donut"
                      width="100%"
                      options={{
                        title: {
                          text: '혈압',
                          align: 'center',
                          style: {
                            fontSize: '18px',
                          }
                        },
                        dataLabels: {
                          enabled: true,
                          formatter: (val, opts) => `${(val as number).toFixed(1)}% (${opts.w.config.series[opts.seriesIndex]}명)`
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -90,
                            endAngle: 90,
                            donut: {
                              labels: {
                                show: bloodPressureSeries.some((item) => item !== 0),
                                total: {
                                  show: bloodPressureSeries.some((item) => item !== 0),
                                  formatter: (w) => `${w.config.series.reduce((pv, cv) => pv + cv, 0)}명`
                                }
                              }
                            }
                          }
                        },
                        yaxis: {
                          min: 400
                        },
                        legend: {
                          show: bloodPressureSeries.some((item) => item !== 0),
                          floating: true,
                          fontSize: '14px',
                          position: 'bottom'
                        },
                        labels: [
                          '정상 혈압',
                          '저혈압',
                          '높은 정상 혈압/고혈압',
                        ],
                        colors: ['#4fad5b', '#f5c242', '#ea3323'],
                        chart: {
                          events: {
                            dataPointSelection: async (event, chartContext, config) => {
                              const total = config.w.config.series[config.dataPointIndex]
                              await setModalTotal(total);
                              setModalType(MODAL_TYPE.PRESSURE)
                              setModalDetailType(BLOODPRESSURE_RESULT[config.dataPointIndex])
                              setShowOverviewModal(true);
                            }
                          }
                        }
                      }}
                      series={bloodPressureSeries}
                  /> : <></>
              }
              </Card>
              <Card sx={{
                p: 1,
                m: 1,
                position: 'relative'
              }}
              >
                {sugarLoading && <Loading><HeartSpinner color="#249ece" /></Loading>}
                {(bloodSugarSeries[0] === 0 && bloodSugarSeries[1] === 0 && bloodSugarSeries[2] === 0) && <NoData>데이터가 없습니다</NoData>}
                {bloodSugarSeries.length > 0
                  ? <ReactApexChart
                      type="donut"
                      width="100%"
                      options={{
                        title: {
                          text: '혈당',
                          align: 'center',
                          style: {
                            fontSize: '18px',
                          }
                        },
                        dataLabels: {
                          formatter: (val, opts) => `${(val as number).toFixed(1)}% (${opts.w.config.series[opts.seriesIndex]}명)`
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -90,
                            endAngle: 90,
                            donut: {
                              labels: {
                                show: bloodSugarSeries.some((item) => item !== 0),
                                total: {
                                  show: bloodSugarSeries.some((item) => item !== 0),
                                  formatter: (w) => `${w.config.series.reduce((pv, cv) => pv + cv, 0)}명`
                                }
                              }
                            }
                          }
                        },
                        legend: {
                          show: bloodSugarSeries.some((item) => item !== 0),
                          floating: true,
                          fontSize: '14px',
                          position: 'bottom'
                        },
                        labels: [
                          '정상',
                          '당뇨전단계',
                          '당뇨관리필요',
                        ],
                        colors: ['#4fad5b', '#f5c242', '#ea3323'],
                        chart: {
                          events: {
                            dataPointSelection: (event, chartContext, config) => {
                              const total = config.w.config.series[config.dataPointIndex]
                              setModalTotal(total);
                              setModalType(MODAL_TYPE.SUGAR)
                              setModalDetailType(BLOODSUGAR_RESULT[config.dataPointIndex])
                              setShowOverviewModal(true);
                            }
                          }
                        }
                      }}
                      series={bloodSugarSeries}
                  /> : <></>}
              </Card>
              <Card sx={{
                p: 1,
                m: 1,
                position: 'relative'
              }}
              >
                {weightLoading && <Loading><HeartSpinner color="#249ece" /></Loading>}
                {(bodyFatSeries[0] === 0 && bodyFatSeries[1] === 0 && bodyFatSeries[2] === 0) && <NoData>데이터가 없습니다</NoData>}
                {bodyFatSeries.length > 0
                  ? <ReactApexChart
                      type="donut"
                      width="100%"
                      options={{
                        title: {
                          text: '체지방',
                          align: 'center',
                          style: {
                            fontSize: '18px',
                          }
                        },
                        dataLabels: {
                          formatter: (val, opts) => `${(val as number).toFixed(1)}% (${opts.w.config.series[opts.seriesIndex]}명)`
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -90,
                            endAngle: 90,
                            donut: {
                              labels: {
                                show: bodyFatSeries.some((item) => item !== 0),
                                total: {
                                  show: bodyFatSeries.some((item) => item !== 0),
                                  formatter: (w) => `${w.config.series.reduce((pv, cv) => pv + cv, 0)}명`
                                }
                              }
                            }
                          }
                        },
                        legend: {
                          show: bodyFatSeries.some((item) => item !== 0),
                          floating: true,
                          fontSize: '14px',
                          position: 'bottom'
                        },
                        labels: [
                          '표준',
                          '야윔',
                          '경도 비만/비만',
                        ],
                        colors: ['#4fad5b', '#f5c242', '#ea3323'],
                        chart: {
                          events: {
                            dataPointSelection: (event, chartContext, config) => {
                              const total = config.w.config.series[config.dataPointIndex]
                              setModalType(MODAL_TYPE.WEIGHT)
                              setModalTotal(total);
                              setModalDetailType(WEIGHT_RESULT[config.dataPointIndex])
                              setShowOverviewModal(true);
                            }
                          }
                        }
                      }}
                      series={bodyFatSeries}
                  /> : <></>}
              </Card>
              <StatisticsModalV2 modalType={modalType} modalDetailType={modalDetailType} modalTotal={modalTotal} getUserHistoryDetail={getUserHistoryDetail}
                doUpdateModalTable={doUpdateModalTable} setShowOverviewModal={setShowOverviewModal} showOverviewModal={showOverViewModal}
              />
            </Box>
          </Grid>
        </Card>
      </Container>
    </Box>
  )
}
