/* eslint-disable no-await-in-loop */
/* eslint react/prop-types: 0 */
import { Box, Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { MODAL_TYPE, QUIT_TYPE } from 'src/__CareWell__/commons/commons';
import { checkToken } from 'src/__CareWell__/utils/tokenChecker';
import useAuth from 'src/hooks/useAuth';
import * as XLSX from 'xlsx-js-style';

const SheetJSFT = [
  'xlsx', 'xlsb', 'xlsm', 'xls', 'xml', 'csv', 'txt', 'ods', 'fods', 'uos', 'sylk', 'dif', 'dbf', 'prn', 'qpw', '123', 'wb*', 'wq*', 'html', 'htm'
].map((x) => `.${x}`).join(',')

/* generate an array of column objects */
// eslint-disable-next-line @typescript-eslint/naming-convention
const make_cols = (refstr) => {
  const o = []
  const
    C = XLSX.utils.decode_range(refstr).e.c + 1
  for (let i = 0; i < C; ++i) o[i] = {name: XLSX.utils.encode_col(i), key: i}
  return o
}

function DragDropFile({handleFile, children}) {
  const suppress = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const handleDrop = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const {files} = e.dataTransfer
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <div
      onDrop={handleDrop}
      onDragEnter={suppress}
      onDragOver={suppress}
    >
      {children}
    </div>
  )
}

interface Props<Type> {
  sx?: Type
  rows?: Type[]
  pageTotal? : number
  onImportData?: (rows: Type[]) => void
  filename?: string
  modalType?: string
  getAllExcelData?:()=>void
  LoadData?: (idx:number, config:any) => any
  setIsLoading?: (loading:boolean)=>void
}

export default function XlsxControl<Type>(props: Props<Type>) {
  const navigate = useNavigate()
  const {logout} = useAuth();
  const { sx, rows, onImportData, pageTotal, filename = 'sheet', getAllExcelData, LoadData, setIsLoading, modalType} = props

  const [data, setData] = useState([])
  const [cols, setCols] = useState([])

  const [selectedFilename, setSelectedFilename] = useState('선택한 파일이 없음')

  const handleFile = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      /* Parse data */
      const ab = e.target.result
      const wb = XLSX.read(ab, {type: 'array'})
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data0 = XLSX.utils.sheet_to_json(ws, {header: 1})
      /* Update state */
      setData(data0)
      setCols(make_cols(ws['!ref']))

      console.log('> imported Data: ', data0)
      let rowCount = data0.length
      for (let i = 0; i < data0.length; ++i) {
        // @ts-ignore
        if (data0[i].length === 0 || data0[i][0] === '') {
          rowCount = i
          break
        }
      }

      console.log(`rows(${data0.length}) valid rowCount(${rowCount}) `)
      const finalRows = data0.slice(0, rowCount)
      console.log('pre final rows: ', finalRows)
      // @ts-ignore
      const columns = finalRows[0].length
      for (let i = 0; i < finalRows.length; ++i) {
        // @ts-ignore
        for (let j = finalRows[i].length; j < columns; ++j) {
          // @ts-ignore
          finalRows[i].push('')
        }
      }
      console.log('final rows: ', finalRows)

      // @ts-ignore
      onImportData(finalRows)
      setSelectedFilename(file.name)
    }
    reader.readAsArrayBuffer(file)
  }
  const changeExcelData = (results) => {
    let list = [];
    switch (modalType) {
      case MODAL_TYPE.SENIOR:
      case MODAL_TYPE.SENIOR_ALL:
      case MODAL_TYPE.ONEDAY:
      case MODAL_TYPE.ONEDAY_ALL:
        list = results && results.map((item, index) => ({경로당명: item.senior_center_name,
          이름: item.senior_name,
          성별: item.senior_gender,
          복약유무: item.senior_medi === '없음' ? '무' : '유',
          위험군대상자: item.senior_medi}))
        break;
      case MODAL_TYPE.CENTER:
      case MODAL_TYPE.CENTER_ALL:
        list = results && results.map((item, index) => ({경로당명: item.senior_center_name,
          경로당주소: item.senior_center_address,
          경로당전화번호: item.senior_center_tel,
          관리자이름: item.senior_center_manager,
          관리자전화번호: item.senior_center_manager_tel}))
        break;
      case MODAL_TYPE.SPEAKER:
      case MODAL_TYPE.SPEAKER_ALL:
        list = results && results.map((item, index) => ({스피커아이디: item.senior_center_user_id,
          시리얼번호: item.ai_speaker_sn,
          설치된경로당명: item.senior_center_name,
          최근사용시간: item.ai_speaker_check_alive,
          펌웨어정보: item.ai_speaker_fw}))
        break;
      case QUIT_TYPE.SENIOR:
        list = results && results.map((item, index) => ({지역: item.senior_center_area,
          경로당명: item.senior_center_name,
          이름: item.senior_name,
          탈퇴사유: item.silver_deleted_reason,
          탈퇴일: item.silver_deleted_date,
          탈퇴처리자: item.silver_admin_id_delete_action}))
        break;
      case QUIT_TYPE.CENTER:
        list = results && results.map((item, index) => ({지역: item.public_care_area,
          경로당관리자ID: item.public_care_admin_id,
          이름: item.public_care_username,
          관리자: item.public_care_name,
          탈퇴사유: item.public_care_del_reason,
          탈퇴일: item.public_care_del_date,
          탈퇴처리자: item.public_care_del_user}))
        break;
      default:
        break;
    }
    return list;
  }
  // const exportFile = (dataToExp: []) => {
  async function exportFile<Type0>(dataToExp: Type0[]) {
    const excelRows = await changeExcelData(rows)
    const ws = XLSX.utils.json_to_sheet(excelRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filen = `${filename}.xlsx`;
    XLSX.writeFile(wb, filen);
  }

  const exportFileAll = async () => {
    setIsLoading(true)
    const config = await checkToken(logout, navigate);
    const len = pageTotal;


    const results = [];
    for (let nPage = 0; nPage < len; nPage++) {
      const result = await LoadData(nPage, config);
      if (result && result.data && result.data.rows) {
        results.push(...result.data.rows);
      }
    }

    const excelRows = await changeExcelData(results)

    const ws = XLSX.utils.json_to_sheet(excelRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filen = `${filename}.xlsx`;
    XLSX.writeFile(wb, filen);
    setIsLoading(false)
  }

  const handleChangeFile = (e) => {
    const {files} = e.target
    if (files && files[0]) handleFile(files[0])
  }

  return (
    <DragDropFile handleFile={handleFile}>
      {
        onImportData && (
          <Box>
            <Button variant="outlined" component="label">
              {/* <img src="/static/icons/excel.svg" alt="Download Excel" /> */}
              파일찾기
              <input
                type="file"
                hidden
                className="form-control"
                id="file"
                accept={SheetJSFT}
                onChange={(handleFile0) => handleChangeFile(handleFile0)}
              />
            </Button>
            <Button><Typography>{selectedFilename}</Typography></Button>
          </Box>
        )
       }
      <div className="row">
        <div className="col-xs-12">
          {rows
          && (
          <Button sx={sx} variant="outlined" onClick={() => exportFile(rows)} disabled={!(rows.length && rows.length > 0)}>
            {/* <Box component="img" sx={{height: 28, width: 30}} src="/static/icons/excel.svg" /> */}
            개별 엑셀 다운로드
          </Button>
          )}
          <Button sx={{ml: 1}} variant="outlined" onClick={() => exportFileAll()}>
            {/* <Box component="img" sx={{height: 28, width: 30}} src="/static/icons/excel.svg" /> */}
            전체 엑셀 다운로드
          </Button>
        </div>
      </div>
    </DragDropFile>
  )
}



