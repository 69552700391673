import axios from 'axios';
import { AccountType } from '../consts';

require('dotenv').config();
// AxiosProxyConfig

// console.log(`Api Post[${URL}] Req: `, reqData)
//         const res = await apiClient.post(URL, reqData)
//         console.log(`Api Post[${URL}]`, res.data)

// const SERVER_URL = `https://api2${process.env.REACT_APP_PUB_ENV === 'DEV' ? '-dev' : ''}.carewellplus.co.kr/api_cc/v1`;
// const SERVER_URL = 'https://api2.carewellplus.co.kr/api_cc/v1'
// const SERVER_URL = 'https://testhc.carewellplus.co.kr/api_cc/v1'
const SERVER_URL = 'https://api2-dev.carewellplus.co.kr/api_cc/v1'
// // 보류
// const SERVER_EMERGENCY_URL = 'https://api2.carewellplus.co.kr/api_emg/v1';
const SERVER_EMERGENCY_URL = 'https://api2-dev.carewellplus.co.kr/api_emg/v1';

interface Info {
  accountType: AccountType;
  avatar: string;
  email: string;
  id: string;
  name: string;
  plan: string;
  state: string;
}

export interface HCenterManagerInfo extends Info {
  admin_uid: string;
  public_care_id: string;
  public_care_admin_id: string;
  public_care_created_date: string;
  public_care_area: string;
  public_care_area_detail: string;
  public_care_name: string;
  public_care_del_date: string;
  public_care_del_reason: string;
  public_care_del_reason_detail: string;
  public_care_del_user: string;
  public_care_email: string;
  public_care_job: string;
  public_care_memo: any[];
  public_care_password: string;
  public_care_username: string;
  public_care_recent_connection_time: string;
  public_care_status: string;
  public_care_tel: string;
  public_care_temp_password_yn: string;
  state: string;
}

export const axiosConfig = {
  baseURL: '/', // SERVER_LOCAL, // SERVER_DEV, // API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Content-Type': 'application/json',
  },
};

export const axiosUploadConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'content-type': 'multipart/form-data',
  },
};

export const apiClientFormData = axios.create({
  baseURL: SERVER_URL,
  headers: { 'Content-Type': 'multipart/form-data' },
  timeout: 5000,
});

export const apiClient = axios.create({
  baseURL: SERVER_URL,
  timeout: 15000,
});

export const apiEmergencyClient = axios.create({
  baseURL: SERVER_EMERGENCY_URL,
  timeout: 15000,
});

export const findIdInUrl = (lastToken: string): string => {
  const tokens = window.location.href.split('/');
  let parsedLastToken = tokens[tokens.length - 1];
  if (parsedLastToken.includes('?')) {
    const [t] = parsedLastToken.split('?');
    parsedLastToken = t
  }
  if (parsedLastToken !== lastToken) {
    // console.error('could not find recipient Id: ', tokens)
    return null;
  }
  return tokens[tokens.length - 2];
};

export const findIdInLastTokenUrl = (lastToken: string): string => {
  const tokens = window.location.href.split('/');
  return tokens[tokens.length - 1];
};
