export interface UIOptionItem {
  label: string;
  value: string;
}

export const appUsableTimeOptions = [
  {
    label: '1년',
    value: '1년',
  },
  {
    label: '3년',
    value: '3년',
  },
];
export const fotaTypeOptions = [
  {
    label: '정규',
    value: '정규',
  },
  {
    label: '긴급',
    value: '긴급',
  },
];
export const fotaStatusOptions = [
  {
    label: '배포',
    value: '배포',
  },
  {
    label: '회수',
    value: '회수',
  },
  {
    label: '테스트',
    value: '테스트',
  },
];

export const statesOfKorea = [
  '서울',
  '경기',
  '인천',
  '광주',
  '대전',
  '강원',
  '충북',
  '충남',
  '전북',
  '전남',
  '경북',
  '경남',
  '제주',
  '세종',
  '부산',
  '대구',
  '울산',
  '세종'
];
export const statesOfKoreaOptions = [
  '',
  '서울',
  '경기',
  '인천',
  '광주',
  '대전',
  '강원',
  '충북',
  '충남',
  '전북',
  '전남',
  '경북',
  '경남',
  '제주',
  '세종',
  '부산',
];
export const areaSortOptions = [
  { label: '전국', value: '' },
  { label: '서울', value: '서울' },
  { label: '경기', value: '경기' },
  { label: '인천', value: '인천' },
  { label: '강원', value: '강원' },
  { label: '세종', value: '세종' },
  { label: '대전', value: '대전' },
  { label: '대구', value: '대구' },
  { label: '충북', value: '충북' },
  { label: '충남', value: '충남' },
  { label: '전북', value: '전북' },
  { label: '전남', value: '전남' },
  { label: '경북', value: '경북' },
  { label: '경남', value: '경남' },
  { label: '울산', value: '울산' },
  { label: '광주', value: '광주' },
  { label: '부산', value: '부산' },
  { label: '제주', value: '제주' },
];

export const ageSortOptions = [
  { label: '전체', value: Array(200).fill(1).map((_, index) => 1 + index) },
  { label: '65~74', value: Array(74 - 65 + 1).fill(1).map((_, index) => 65 + index)},
  { label: '75~84', value: Array(84 - 75 + 1).fill(1).map((_, index) => 75 + index) },
  { label: '85+', value: Array(200 - 85 + 1).fill(1).map((_, index) => 85 + index) },
];

export const genderOptions = [
  { label: '전체', value: '' },
  { label: '남성', value: '남성' },
  { label: '여성', value: '여성' }
];

export const hCenterSelectOptions = [
  {
    value: 'shirts',
    label: '송파구 경로당',
  },
  {
    value: 'Phones',
    label: '노원구 경로당',
  },
  {
    value: 'Cars',
    label: '마포구 경로당',
  },
];
// 간호사/의사/영양사/운동전문가/물리치료사/치과위생사
export const hCenterJobOptions = [
  {
    value: '',
    label: '',
  },
  {
    value: '행정담당자',
    label: '행정담당자',
  },
  {
    value: '선임간호사',
    label: '선임간호사',
  },
  {
    value: '담당간호사',
    label: '담당간호사',
  },
  {
    value: '영양사',
    label: '영양사',
  },
  {
    value: '운동전문가',
    label: '운동전문가',
  },
  {
    value: '치위생사',
    label: '치위생사',
  },
  {
    value: '물리•직업치료사',
    label: '물리직업치료사',
  },
];
// 슈퍼관리자,건장증진개발원,센터관리자
export const hCenterManagerType = [
  {
    value: '경로당관리자',
    label: '경로당관리자',
  },
];

export const operatorType = [
  {
    value: '슈퍼관리자',
    label: '슈퍼관리자',
  },
  {
    value: '설치관리자',
    label: '설치관리자',
  },
  {
    value: '센터관리자',
    label: '센터관리자',
  },
];

export const speakerModelNameOptions = [
  {
    label: 'IF-S1100',
    value: 'IF-S1100',
  },
  // {
  //   label: 'MODEL-001',
  //   value: 'MODEL-001'
  // }
];

export const speakerStatusOptions: UIOptionItem[] = [
  {
    label: '전체',
    value: '전체',
  },
  {
    label: '입고',
    value: '입고',
  },
  {
    label: '출고',
    value: '출고',
  },
  {
    label: 'AS',
    value: 'AS',
  },
  {
    label: '중고',
    value: '중고',
  },
  {
    label: '폐기',
    value: '폐기',
  },
  {
    label: '미사용',
    value: '미사용',
  },
  {
    label: '기타',
    value: '기타',
  },
];

export const speakerStatusHCenterOptions: UIOptionItem[] = [
  {
    label: '전체',
    value: '전체',
  },
  {
    label: '출고',
    value: '출고',
  },
  {
    label: 'AS',
    value: 'AS',
  },
];

export const speakerNetworkStatusOptions = [
  {
    label: '전체',
    value: '전체',
  },
  {
    label: '사용',
    value: '사용',
  },
  {
    label: '미사용',
    value: '미사용',
  },
];

export const speakerServiceOptions = [
  {
    label: '인증',
    value: 'authorized',
  },
  {
    label: '미인증',
    value: 'unauthorized',
  },
];

export const emailOptions = [
  {
    label: '직접입력',
    value: '1',
  },
  {
    label: 'naver.com',
    value: 'naver.com',
  },
  {
    label: 'gmail.com',
    value: 'gmail.com',
  },
  {
    label: 'kakao.com',
    value: 'kakao.com',
  },
  {
    label: 'daum.net',
    value: 'daum.net',
  },
  {
    label: 'hanmail.net',
    value: 'hanmail.net',
  },
  {
    label: 'nate.com',
    value: 'nate.com',
  },
  {
    label: 'marknova.co.kr',
    value: 'marknova.co.kr',
  },
];

export const hCenterManagerQuitReasonOptions = [
  {
    label: '담당 업무 변경',
    value: '담당 업무 변경',
  },
  {
    label: '퇴사 및 계약 종류',
    value: '퇴사 및 계약 종류',
  },
  {
    label: '기타',
    value: '기타',
  },
];

// 경로당관리자
export const deleteHCenterManagerApiUrl = '/delete_public_care_admin';
export const deleteHCenterManagerReasonOptions = [
  '담당 업무 변경으로 인한 개인정보 삭제 목적',
  '퇴사 및 계약 종료로 인한 개인정보 삭제 목적',
  '기타',
];

export const deleteOperatorApiUrl = '/delete_public_care_admin';
export const deleteOperatorReasonOptions = [
  '담당 업무 변경으로 인한 개인정보 삭제 목적',
  '퇴사 및 계약 종료로 인한 개인정보 삭제 목적',
  '기타',
];

export const oneToOneTermsAgreeOptions = [
  {
    label: '미동의',
    value: '미동의',
  },
  {
    label: '동의',
    value: '동의',
  },
];

export const oneToOneQuestionStatus = ['대기중', '답변완료'];

export const emergencyCallPhoneStatusOptions = [
  {
    value: '통화완료',
    label: '통화완료',
  },
  {
    value: '통화불가',
    label: '통화불가',
  },
];

// 119요청완료 , 어르신실수요청취소 , 건강완화응급요청취소 ,네트워크등일시적오류,기타

// 처리상태
export const emergencyCallProcessStatusOptions = [
  {
    value: '',
    label: '',
  },
  {
    value: '119요청완료',
    label: '119요청완료',
  },
  {
    value: '어르신실수요청취소',
    label: '어르신실수요청취소',
  },
  {
    value: '건강완화응급요청취소',
    label: '건강완화응급요청취소',
  },
  {
    value: '네트워크등일시적오류',
    label: '네트워크등일시적오류',
  },
  {
    value: '기타',
    label: '기타',
  },
];
