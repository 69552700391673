import DescriptionIcon from '@material-ui/icons/Description'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import SpeakerGroupIcon from '@material-ui/icons/SpeakerGroup'
import UsersIcon from '../icons/Users'
import { carewellAuthApi } from './api/carewellAuthApi'
import { AccountType } from './consts'


// 운영관리자 사이드바 메뉴
export const sectionsOperator = [
  // {
  //   title: 'General',
  //   items: [
  //     {
  //       title: '긴급요청 현황',
  //       path: '/dashboard/emergency-req',
  //     }
  //   ]
  // },
  {
    title: '회원 관리',
    path: '',
    items: [
      {
        title: '대상자',
        path: '/dashboard/users/recipients',
      },
      {
        title: '경로당 관리자',
        path: '/dashboard/users/hcenter-managers'
      },
      {
        title: '운영관리자',
        path: '/dashboard/users/operators',
      },
      {
        title: '탈퇴회원 조회',
        path: '/dashboard/users/recipients-quit',
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients-quit'
          },
          {
            title: '경로당 관리자',
            path: '/dashboard/users/hcenter-managers-quit'
          },
          {
            title: '운영 관리자',
            path: '/dashboard/users/operators-quit'
          },
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 관리',
        path: '/dashboard/speakers',
        children: [
          {
            title: '현황',
            path: '/dashboard/speakers/overview'
          },
          {
            title: '등록',
            path: '/dashboard/speakers/new',
          },
          // {
          //   title: '스피커 아이디 리스트',
          //   path: '/dashboard/speakers/idlist',
          // },
          {
            title: '로그인 정보 등록',
            path: '/dashboard/speakers/login',
          },
        ]
      },
    ]
  },
  {
    title: '소프트웨어 관리 X',
    items: [
      {
        title: '소프트웨어 관리',
        path: '/dashboard/softwares',
        children: [
          // {
          //   title: '앱',
          //   path: '/dashboard/softwares/apps'
          // },
          {
            title: 'FOTA',
            path: '/dashboard/softwares/fota'
          }
        ]
      }
    ]
  },
  // {
  //   title: '커뮤니티 관리 X',
  //   items: [
  //     {
  //       title: '커뮤니티 관리',
  //       path: '/dashboard/communities',
  //       children: [
  //         {
  //           title: '공지사항',
  //           path: '/dashboard/communities/notices'
  //         },
  //         {
  //           title: '어르신 공지사항',
  //           path: '/dashboard/communities/silver_notices'
  //         },
  //       ]
  //     },
  //   ]
  // },
  {
    title: '통계 정보 관리 X',
    items: [
      {
        title: '통계 정보 관리',
        path: '/dashboard/statistics',
        children: [
          {
            title: '조회',
            path: '/dashboard/statistics'
          },
        ]
      },
    ]
  }
]

// 경로당관리자 사이드바 메뉴
export const sectionsHCenterManager = [
  // {
  //   title: 'General',
  //   items: [
  //     {
  //       title: '긴급요청 현황',
  //       path: '/dashboard/emergency-req',
  //     }
  //   ]
  // },
  {
    title: '회원관리 X',
    items: [
      {
        title: '대상자',
        path: '/dashboard/users/recipients',
      }
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 관리',
        path: '/dashboard/speakers/overview',
        children: [
          {
            title: '스피커 현황',
            path: '/dashboard/speakers/overview'
          },
        ]
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        children: [
          // {
          //   title: '공지사항',
          //   path: '/dashboard/communities/notices'
          // },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
        ]
      },
    ]
  },
  {
    title: '통계 정보 관리 X',
    items: [
      {
        title: '통계 정보 관리',
        path: '/dashboard/statistics',
        children: [
          {
            title: '조회',
            path: '/dashboard/statistics'
          },
        ]
      },
    ]
  }
]

// 건강증진개발원 사이드바 메뉴
export const sectionsKHealthMember = [
  // {
  //   title: 'General',
  //   items: [
  //     {
  //       title: '긴급요청 현황',
  //       path: '/dashboard/emergency-req',
  //       icon: <NotificationsActiveIcon fontSize="small" />
  //     }
  //   ]
  // },
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
            ]
          }
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 현황',
        path: '/dashboard/speakers/overview',
        icon: <SpeakerGroupIcon fontSize="small" />,
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          // {
          //   title: '공지사항',
          //   path: '/dashboard/communities/notices'
          // },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
          {
            title: '자주 묻는 질문',
            path: '/dashboard/communities/hcenter-faqs'
          },
          // {
          //   title: '1:1 문의',
          //   path: '/dashboard/communities/1to1-questions'
          // },
          {
            title: '이용약관',
            path: '/dashboard/communities/hcenter-terms'
          }
        ]
      },
    ]
  }
]

// 설치관리자 사이드바 메뉴
export const sectionsInstaller = [
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원 관리',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
            ]
          }
        ]
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          // {
          //   title: '공지사항',
          //   path: '/dashboard/communities/notices'
          // },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
        ]
      },
    ]
  }
]

// 콜센터 사이드바 메뉴
export const sectionsCallCenterManager = [
  // {
  //   title: 'General',
  //   items: [
  //     {
  //       title: '긴급요청 현황 cc',
  //       path: '/dashboard/emergency-req',
  //       icon: <NotificationsActiveIcon fontSize="small" />
  //     }
  //   ]
  // },
  {
    title: '회원관리 X',
    items: [
      {
        title: '회원 관리',
        path: '', // '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: '대상자',
            path: '/dashboard/users/recipients',
            children: [
              {
                title: '조회',
                path: '/dashboard/users/recipients'
              },
              {
                title: '등록',
                path: '/dashboard/users/recipients/new'
              }
            ]
          }
        ]
      },
    ]
  },
  {
    title: '스피커 관리 X',
    items: [
      {
        title: '스피커 관리',
        path: '/dashboard/speakers',
        icon: <SpeakerGroupIcon fontSize="small" />,
        children: [
          {
            title: '스피커 현황',
            path: '/dashboard/speakers/overview'
          }
        ]
      },
    ]
  },
  {
    title: '커뮤니티 관리 X',
    items: [
      {
        title: '커뮤니티',
        path: '/dashboard/communities',
        icon: <DescriptionIcon fontSize="small" />,
        children: [
          // {
          //   title: '공지사항',
          //   path: '/dashboard/communities/notices'
          // },
          {
            title: '어르신 공지사항',
            path: '/dashboard/communities/silver_notices'
          },
          {
            title: '자주 묻는 질문',
            path: '/dashboard/communities/hcenter-faqs'
          },
          // {
          //   title: '1:1 문의',
          //   path: '/dashboard/communities/1to1-questions'
          // },
          {
            title: '이용약관',
            path: '/dashboard/communities/hcenter-terms'
          }
        ]
      },
    ]
  }
]

// export const currentSidebarSection = (): [] => {
export function currentSidebarSection() : Array<any> {
  switch (carewellAuthApi.getAccountType()) {
    // case  AccountType.Operator: // 운영관리자
    case AccountType.HCenterManager: // 경로당관리자
      return sectionsHCenterManager
    case AccountType.KHealthMember: // 건강증진개발원
      return sectionsKHealthMember
    case AccountType.InstallManager: // 설치 관리자
      return sectionsInstaller
    case AccountType.CallCenterManager:
      return sectionsCallCenterManager
    default:
      return sectionsOperator
  }

  // switch (getAppTypeByUrl()) {
  //   case AppTypeCareWell.HCenterManagerWeb:
  //     return sectionsHCenterManager
  //   case AppTypeCareWell.InstallerMobile:
  //     return sectionsInstaller
  //   case AppTypeCareWell.CallCenterTablet:
  //     return sectionsCallCenterManager
  //   default: {
  //     return sectionsOperator
  //   }
  // }
}
