import React, {FC} from 'react'
import {Box, Container, Grid, Typography} from '@material-ui/core'

export function applyPagination<Type>(items: Type[], page: number, limit: number): Type[] {
  return items.slice(page * limit, page * limit + limit)
}

export function applyStringSortG<Type>(items: Type[], key: string, sortType: -1 | 0 | 1): Type[] {
  if (sortType === 0) return items

  items.sort((aVal, bVal) => {
    // if (aVal[key]) console.log('a[key]: ', aVal[key])

    const a = aVal[key] ? aVal[key] : ' '
    const b = bVal[key] ? bVal[key] : ' '
    if (key.includes('date') || key.includes('time')) {
      if (a > b) return sortType
      if (a < b) return -sortType
      return 0
    }
    if (sortType === 1) {
      if (/[\u3131-\u3163\uac00-\ud7a3]/.test(a) && /[\u3131-\u3163\uac00-\ud7a3]/.test(b)) {
        return a.localeCompare(b, 'ko');
      }
      // Check if a and b are both English characters
      if (/[a-zA-Z]/.test(a) && /[a-zA-Z]/.test(b)) {
        return a.localeCompare(b, 'en');
      }
      // Check if a and b are both numbers
      if (/\d/.test(a) && /\d/.test(b)) {
        return (a - b);
      }
      // a and b are not in the same category, so compare their categories
      if (/[\u3131-\u3163\uac00-\ud7a3]/.test(a)) {
        return -1;
      }
      if (/[a-zA-Z]/.test(a)) {
        return -1;
      }
      if (/\d/.test(a)) {
        return -1;
      }
    }
    if (sortType === -1) {
      if (a === ' ' && b !== ' ') return -sortType;
      if (a !== ' ' && b === ' ') return sortType;
      if (/[a-z]/.test(a) && !/[a-z]/.test(b)) return -sortType;
      if (!/[a-z]/.test(a) && /[a-z]/.test(b)) return sortType;
      if (/[\u3131-\u3163\uac00-\ud7a3]/.test(a) && !/[\u3131-\u3163\uac00-\ud7a3]/.test(b)) return -sortType;
      if (!/[\u3131-\u3163\uac00-\ud7a3]/.test(a) && /[\u3131-\u3163\uac00-\ud7a3]/.test(b)) return sortType;

      // If both are the same type, sort alphabetically or numerically
      if (a < b) return -sortType;
      if (a > b) return sortType;
      return 0;
    }
    return sortType;
  })
  items.forEach((a) => {
    // console.log(a[key])
  })
  return items
}

export const handleClickSort = (sortType: number, sortStats: number[], statsSetter: React.Dispatch<React.SetStateAction<number[]>>) => {
  // console.log(`handleClickSort sortStats[${sortStats}] `)
  const index = sortType - 1
  const newValues = [...sortStats]
  // if (++newValues[index] > 1) newValues[index] = -1
  if (newValues[index] === 0) newValues[index] = 1;
  else if (newValues[index] === 1) newValues[index] = -1;
  else if (newValues[index] === -1) newValues[index] = 1;
  for (let i = 0; i < newValues.length; ++i) {
    if (i !== index) newValues[i] = 0
  }
  // console.log(`handleSort sortStats[${newValues}] after `)
  statsSetter(newValues)
}


const SpeakerRegisterOne00: FC = () => (
  <Container>
    <Grid container>
      <Grid container item xs={4}>
        <Grid item xs={12} md={4}>1</Grid>
        <Grid item xs={12} md={4}>2</Grid>
        <Grid item xs={12} md={4}>3</Grid>
      </Grid>
      <Grid container item xs={4}>
        <Grid item xs={12} md={4}>1</Grid>
        <Grid item xs={12} md={4}>2</Grid>
        <Grid item xs={12} md={4}>3</Grid>
      </Grid>
      <Grid container item xs={4}>
        <Grid item xs={12} md={4}>1</Grid>
        <Grid item xs={12} md={4}>2</Grid>
        <Grid item xs={12} md={4}>3</Grid>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} md={4}>4</Grid>
      <Grid item xs={12} md={4}>5</Grid>
      <Grid item xs={12} md={4}>6</Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} md={4}>7</Grid>
      <Grid item xs={12} md={4}>8</Grid>
      <Grid item xs={12} md={4}>9</Grid>
    </Grid>
  </Container>
)



const TEMP_ELEM = () => (
  <Box sx={{p: 1, bgcolor: 'grey.300'}}><Typography width={200} align="right">지역</Typography></Box>
)


// const getCPEStatusLabel00 = (inventoryType: string): JSX.Element => {
//   const {text, color}: any = map[inventoryType]
//
//   return (
//     <Label color={color}>
//       {text}
//     </Label>
//   )
// }

// const handleClickSort00 = (sortType: number, statsSetter:React.Dispatch<React.SetStateAction<number[]>>) => {
//   console.log(`handleSort sortStats[${sortStats}] `)
//   // let val = sort01Area
//   // // if (val === -1) val = 1
//   // // else
//   // if (++val > 1) val = -1
//
//   const index = sortType - 1
//   const newValues = [...sortStats]
//   if (++newValues[index] > 1) newValues[index] = -1
//   for (let i = 0; i < newValues.length; ++i) {
//     if (i !== index) newValues[i] = 0
//   }
//   console.log(`handleSort sortStats[${newValues}] after `)
//   // setSortStats(newValues)
//   statsSetter(newValues)
//
//   // newValues[sortType - 1] = 1
//   // setSortStats(newValues[sortType - 1])
//
//   switch (sortType) {
//     case 1: {
//       let val = sort01Area
//       // if (val === -1) val = 1
//       // else
//       if (++val > 1) val = -1
//       // @ts-ignore
//       setSort01Area(val)
//       setSort02Status(0)
//       setSort03CpeStatus(0)
//       console.log(`sort[${sortType}]: prev(${sort01Area}) new(${val}) `)
//       break
//     }
//     case 2: {
//       let val = sort02Status
//       // if (val === -1) val = 1
//       // else
//       if (++val > 1) val = -1
//       setSort01Area(0)
//       // @ts-ignore
//       setSort02Status(val)
//       setSort03CpeStatus(0)
//       console.log(`sort[${sortType}]: prev(${sort02Status}) new(${val}) `)
//       break
//     }
//     case 3: {
//       let val = sort03CpeStatus
//       // if (val === -1) val = 1
//       // else
//       if (++val > 1) val = -1
//       // @ts-ignore
//       setSort03CpeStatus(val)
//       setSort01Area(0)
//       setSort02Status(0)
//       console.log(`sort[${sortType}]: prev(${sort03CpeStatus}) new(${val}) `)
//       break
//     }
//     default:
//       break
//   }
// }
