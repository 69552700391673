import { carewellAuthApi } from '../api/carewellAuthApi'
import { apiClient } from '../api/common'
import { AccountType } from '../consts'
import { getCookie, setCookie } from './cookieTools'

const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
const isHCenter = carewellAuthApi.getAccountType() === AccountType.HCenterManager

export const checkToken = async (logout, navigate) => {
  let reqType;
  if (isOperator) {
    reqType = 'operator';
  } else if (isHCenter) {
    reqType = 'hcenter';
  }
  const accessToken = getCookie('access_token');
  const refreshToken = getCookie('refresh_token');
  if (!accessToken || !refreshToken) {
    await logout();
    await carewellAuthApi.logout();
    navigate('/')
  }
  const checkTokenUrl = `/check_token?req_type=${reqType}`
  const config = {
    headers: {
      'x-access-token': accessToken,
      'x-refresh-token': refreshToken,
    }
  }
  const preRes = await apiClient.post(checkTokenUrl, {}, config)
  if (preRes.data.state === 'reissue') {
    setCookie('access_token', preRes.data.token, 1)
    config.headers['x-access-token'] = preRes.data.token
  }
  return config;
}
