import {
  Box, Button, Card, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, ToggleButton,
  ToggleButtonGroup, Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import { MouseEvent, useState } from 'react'
import * as Yup from 'yup'
import { carewellAuthApi } from '../../../api/carewellAuthApi'
import { apiClient } from '../../../api/common'
import {
  AccountType, SelectInputOptions
} from '../../../consts'
import {
  speakerNetworkStatusOptions, UIOptionItem
} from '../../../typesServiceDesign'
import {
  getDateYyyyMmDd
} from '../../../utils/serverTools'


const HEIGHT = 58
const TITLE_WIDTH = 80

// export interface CommonTableSearch {
//   from_date: string
//   end_date: string
//   search_item: string
//   search_content: string
//   area: string
//   admin_role: string
// }

// const initialValuesCommonTableSearch = {
//   from_date: '',
//   end_date: '',
//   search_item: '',
//   search_content: '',
//   area: '',
//   admin_role: ''
// }

const dateOptions = [
  {id: '1', label: '전체'},
  {id: '2', label: '어제'},
  {id: '3', label: '오늘'},
  {id: '4', label: '6개월전'}
]

interface Props<Type> {
  records: Type[]
  setRecords: (items: Type[]) => void
  searchUrl: string
  doUpdateTable?: () => void
  categorySearch?: SelectInputOptions
  hasRegionSearch?: boolean
  setLoadingDone: (hasDoneLoading: boolean) => void
  setSearchDone?: (hasDoneSearching: boolean) => void
  useNetworkStatus?: boolean
  initialValuesSpeakerTableSearch?: Record<string, string>
  statusOptions: UIOptionItem[]
  setSearchFilter?: (_filter:any) => void
}

export default function SpeakerListSearch<Type>(props: Props<Type>) {
  const {records, setRecords, searchUrl, doUpdateTable, categorySearch, hasRegionSearch = true,
    initialValuesSpeakerTableSearch, useNetworkStatus = true, statusOptions, setLoadingDone, setSearchFilter,
    ...other
  } = props
  const [alignment, setAlignment] = useState('');
  const [hideTextField, setHideTextField] = useState<boolean>(true);

  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const isHCenter = carewellAuthApi.getAccountType() === AccountType.HCenterManager

  let type;
  if (isOperator) {
    type = ''
  } else if (isHCenter) {
    type = 'hcenter'
  }

  const formik = useFormik({
    initialValues: initialValuesSpeakerTableSearch,
    validationSchema: Yup.object({
      // firstName: Yup.string()
      //   .max(5, 'Must be 5 characters or less !!!')
      //   .required('Required'),
    }),
    onSubmit: async (values) => {
      setLoadingDone(true)
      setSearchFilter({})
      const reqData = {
        ...values,
        from_date: values.from_date ? `${values.from_date} 00:00:00` : '',
        end_date: values.end_date ? `${values.end_date} 23:59:59` : '',
        ai_speaker_status: values.ai_speaker_status === '전체' ? '' : values.ai_speaker_status,
        ai_speaker_use_yn: values.ai_speaker_use_yn === '전체' ? '' : values.ai_speaker_use_yn,
        user_id: carewellAuthApi.loggedInUser.id,
        req_type: type
      }
      console.log(reqData)
      if (reqData.ai_speaker_status === ''
      && reqData.ai_speaker_use_yn === ''
      && reqData.from_date === ''
      && reqData.end_date === ''
      && values.area === ''
      && values.search_content === ''
      && values.search_item === '') { setSearchFilter(null); } else {
        setSearchFilter(reqData)
      }
      // console.log(reqData)
    },
  })

  const handleResetForm = () => {
    setHideTextField(true);
    formik.resetForm({values: initialValuesSpeakerTableSearch})
    setAlignment('')
    setSearchFilter(null)
    doUpdateTable()
  }

  const handleDateOptionsChange = (
    event: MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    // console.log('handleDateOptionsChange', newAlignment)
    setAlignment(newAlignment)
    // eslint-disable-next-line default-case
    switch (newAlignment) {
      case '1': { // 전체
        console.log('전체')
        // setDate(toStartDateFormatOnApi(new Date(2021, 0)), toEndDateFormatOnApi(new Date()))
        formik.setFieldValue('from_date', '')
        formik.setFieldValue('end_date', '')
        break
      }
      case '2': { // 어제
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        const val = getDateYyyyMmDd(yesterday)
        // console.log('yesterday: ', val)
        formik.setFieldValue('from_date', val)
        formik.setFieldValue('end_date', val)
        // setDate(toStartDateFormatOnApi(yesterday), toEndDateFormatOnApi(yesterday))
        break
      }
      case '3': { // 오늘
        const today = new Date()
        const val = getDateYyyyMmDd(today)
        // console.log('today: ', val)
        formik.setFieldValue('from_date', val)
        formik.setFieldValue('end_date', val)
        // setDate(toStartDateFormatOnApi(today), toEndDateFormatOnApi(today))
        break
      }
      case '4': { // 6개월전
        const SIX_MONTHS_AGO = new Date()
        SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 6)
        const val = getDateYyyyMmDd(SIX_MONTHS_AGO)
        const now = getDateYyyyMmDd(new Date())
        // console.log(`from(${val}) now(${now}) `)
        formik.setFieldValue('from_date', val)
        formik.setFieldValue('end_date', now)
        // setDate(toStartDateFormatOnApi(SIX_MONTHS_AGO), toEndDateFormatOnApi(new Date()))
        break
      }
    }
  }

  return (
    <Card {...other}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{pt: 1}}>


          <Grid container justifyContent="space-between">
            <Grid item>
              <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
                <Box sx={{p: 1}}><Typography width={TITLE_WIDTH} align="right">검색</Typography></Box>
                <Box sx={{display: 'flex'}}>
                  <TextField
                    size="small" select SelectProps={{native: true}} variant="outlined"
                    label="" name="search_item" sx={{ml: 1, width: 120}} onChange={(e) => {
                      if (e.target.value === '') {
                        setHideTextField(true);
                      } else {
                        setHideTextField(false);
                      }
                      formik.handleChange(e)
                    }}
                    value={formik.values.search_item}
                  >
                    {
                      categorySearch.items.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))
                    }
                  </TextField>
                  <Box sx={{px: 1}}>
                    {
                      hideTextField
                        ? <Typography />
                        : <TextField size="small" placeholder="텍스트를 입력해 주세요."
                            name="search_content" onChange={formik.handleChange} value={formik.values.search_content}
                        />
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Button variant="outlined" sx={{mt: 1, mr: 2}} onClick={handleResetForm}>초기화</Button>
            </Grid>
          </Grid>


          <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
            <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">기기 상태</Typography></Box>
            <RadioGroup name="ai_speaker_status" sx={{ flexDirection: 'row' }} onChange={formik.handleChange}
              value={formik.values.ai_speaker_status}
            >
              {statusOptions.map((item) => (
                <FormControlLabel checked={item.value === formik.values.ai_speaker_status}
                  control={(<Radio color="primary" sx={{ ml: 0 }} />)} key={item.value} value={item.value}
                  label={(<Typography color="textPrimary" variant="body1">{item.label}</Typography>)}
                />
              ))}
            </RadioGroup>
          </Box>

          {useNetworkStatus
              && (
              <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
                <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">사용 여부</Typography></Box>
                <RadioGroup name="ai_speaker_use_yn" sx={{flexDirection: 'row'}} onChange={formik.handleChange}
                  value={formik.values.ai_speaker_use_yn}
                >
                  {speakerNetworkStatusOptions.map((item) => (
                    <FormControlLabel checked={item.value === formik.values.ai_speaker_use_yn}
                      control={(<Radio color="primary" sx={{ml: 0}} />)} key={item.value}
                      value={item.value}
                      label={(
                        <Typography color="textPrimary" variant="body1">{item.label}</Typography>)}
                    />
                  ))}
                </RadioGroup>
              </Box>
              )}

          <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
            <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">기간 검색</Typography></Box>
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="from_date" value={formik.values.from_date} onChange={(e) => {
                if (formik.values.end_date === '' || formik.values.end_date < formik.values.from_date) {
                  formik.values.end_date = e.target.value
                }
                return formik.handleChange(e)
              }}
            />
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="end_date" value={formik.values.end_date} onChange={(e) => {
                if (formik.values.from_date === '' || formik.values.from_date > formik.values.end_date) {
                  formik.values.from_date = e.target.value
                }
                return formik.handleChange(e)
              }}
              sx={{mx: 1}}
            />
            {/* <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="from_date" value={formik.values.from_date} onChange={formik.handleChange}
            />
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="end_date" value={formik.values.end_date} onChange={formik.handleChange}
              sx={{mx: 1}}
            /> */}
            {/* <Button variant="outlined" sx={{ml: 1, height: 40}} onClick={() => { */}
            {/*  console.log('current: ', formik.values.from_date) */}
            {/*  formik.setFieldValue('from_date', serverTimeFormatToUI(getDateYyyyMmDd(new Date()))) */}
            {/* }} */}
            {/* > */}
            {/*  현재시간 사용 */}
            {/* </Button> */}
            <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleDateOptionsChange}>
              {
                  dateOptions.map((item) => (
                    <ToggleButton sx={{width: 72}} value={item.id} key={item.id}>{item.label}</ToggleButton>
                  ))
                }
            </ToggleButtonGroup>
          </Box>

          <Box sx={{display: 'flex', justifyContent: 'center', p: 1, m: 1, bgcolor: 'background.paper', }}>
            <Button variant="outlined" type="submit" sx={{width: 100}}>검색</Button>
            <Divider />
          </Box>
        </Box>
      </form>
    </Card>
  )
}
