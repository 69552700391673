import { Box, Pagination, TableBody, TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';
import { useEffect, useState } from 'react';
import type {ChangeEvent, FC, MouseEvent} from 'react'
import Scrollbar from '../../../../components/Scrollbar'
import { MyTableA, SX_TABLE_HEADER_A } from 'src/__CareWell__/commonStyles';
import { MODAL_TYPE, TABLE_OPTION, getDateTimeToString, getDateToStringUTC } from 'src/__CareWell__/commons/commons';
import { CSS_CENTER } from 'src/__CareWell__/consts';
import { getDateYyyyMmDd } from 'src/__CareWell__/utils/serverTools';

interface Props {
  modalType: string,
  total:number,
  limit:number,
  rows:any[],
  page:number,
  selectRows:any[],
  setSelectRows: (rows:any[])=>void
  setPage: (page: number)=>void,
  setDisalbeXlsx: (disabled: boolean)=>void
}

export default function StatisticsTableV2(props:Props) {
  const {modalType, total, limit, rows, page, setPage, selectRows, setSelectRows, setDisalbeXlsx} = props;

  const [checkedAll, setCheckedAll] = useState(false);
  const [checkRows, setCheckRows] = useState<boolean[]>([])


  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage - 1)
  }
  const handleChangeAllCheckBox = async () => {
    setCheckedAll((prevCheckedAll) => {
      const checked = !prevCheckedAll;

      const newCheckRows = Array(checkRows.length).fill(checked);
      setCheckRows(() => newCheckRows);

      if (checked) {
        setSelectRows(rows)
        setDisalbeXlsx(false)
      } else {
        setSelectRows([])
        setDisalbeXlsx(true)
      }
      return checked;
    });
  };
  useEffect(() => {
    setCheckedAll(false) // limit 변경시 헤더 체크 해제
  }, [limit])
  const handleChangeCheckBox = async (index) => {
    const checked = !selectRows[index]
    await setCheckRows((prev) => {
      const prevrows = [...prev];
      prevrows[index] = checked;
      let newData = selectRows;
      if (checked) {
        newData.push(rows[index]);
      } else {
        const seniorname = rows[index].senior_name;
        newData = newData.filter((item) => item.senior_name !== seniorname);
      }
      setSelectRows(newData);
      console.log(!(newData.length > 0))
      setDisalbeXlsx(!(newData.length > 0));
      return prevrows;
    });
  }
  useEffect(() => {
    setCheckRows(rows && rows.map((item, index) => false))
  }, [rows])
  return (
    <Box sx={{minWidth: '100%', border: 0, borderColor: '#f0f0f0'}}>
      <Scrollbar id="scroll_table">
        <Box sx={{height: 400}}>
          <MyTableA stickyHeader size="small">
            <TableHead>
              <TableRow sx={SX_TABLE_HEADER_A}>
                <TableCell align="center">
                  <div><Checkbox checked={checkedAll} onChange={handleChangeAllCheckBox} /></div>
                </TableCell>
                {
                    (modalType === MODAL_TYPE.SENIOR) && TABLE_OPTION.SENIOR.map((label, index) => <TableCell key={label} align="center">{label}</TableCell>)
                  }
                {
                    (modalType === MODAL_TYPE.PRESSURE) && TABLE_OPTION.PRESSURE.map((label, index) => <TableCell key={label} align="center">{label}</TableCell>)
                  }
                {
                    (modalType === MODAL_TYPE.SUGAR) && TABLE_OPTION.SUGAR.map((label, index) => <TableCell key={label} align="center">{label}</TableCell>)
                  }
                {
                    (modalType === MODAL_TYPE.WEIGHT) && TABLE_OPTION.WEIGHT.map((label, index) => <TableCell key={label} align="center">{label}</TableCell>)
                  }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                modalType === MODAL_TYPE.PRESSURE && rows && rows.map((item, index) => {
                  const keyName = `${item.senior_name}_${index}`
                  return (
                    <TableRow key={keyName}>
                      <TableCell align="center"><Checkbox checked={checkRows && checkRows.length > 0 && checkRows[index]} onChange={() => { handleChangeCheckBox(index) }} /></TableCell>
                      <TableCell align="center">{item.public_care_name}</TableCell>
                      <TableCell align="center">{item.senior_center_name}</TableCell>
                      <TableCell align="center">{item.senior_name}</TableCell>
                      <TableCell align="center">{item.senior_gender}</TableCell>
                      <TableCell align="center">{getDateToStringUTC(new Date(item.measure_date))}</TableCell>
                      <TableCell align="center">{item.systolic}</TableCell>
                      <TableCell align="center">{item.diastole}</TableCell>
                      <TableCell align="center">{item.pulse}</TableCell>
                    </TableRow>
                  )
                })
              }
              {
                modalType === MODAL_TYPE.SUGAR && rows && rows.map((item, index) => {
                  const keyName = `${item.senior_name}_${index}`
                  return (
                    <TableRow key={keyName}>
                      <TableCell align="center"><Checkbox checked={checkRows && checkRows.length > 0 && checkRows[index]} onChange={() => { handleChangeCheckBox(index) }} /></TableCell>
                      <TableCell align="center">{item.public_care_name}</TableCell>
                      <TableCell align="center">{item.senior_center_name}</TableCell>
                      <TableCell align="center">{item.senior_name}</TableCell>
                      <TableCell align="center">{item.senior_gender}</TableCell>
                      <TableCell align="center">{getDateToStringUTC(new Date(item.measure_date))}</TableCell>
                      <TableCell align="center">{item.classification === 'MES_BEFORE' ? item.value : ''}</TableCell>
                      <TableCell align="center">{item.classification === 'MES_AFTER1H' ? item.value : ''}</TableCell>
                      <TableCell align="center">{item.classification === 'MES_AFTER2H' ? item.value : ''}</TableCell>
                    </TableRow>
                  )
                })
              }
              {
                modalType === MODAL_TYPE.WEIGHT && rows && rows.map((item, index) => {
                  const keyName = `${item.senior_name}_${index}`
                  return (
                    <TableRow key={keyName}>
                      <TableCell align="center"><Checkbox checked={checkRows && checkRows.length > 0 && checkRows[index]} onChange={() => { handleChangeCheckBox(index) }} /></TableCell>
                      <TableCell align="center">{item.public_care_name}</TableCell>
                      <TableCell align="center">{item.senior_center_name}</TableCell>
                      <TableCell align="center">{item.senior_name}</TableCell>
                      <TableCell align="center">{item.senior_gender}</TableCell>
                      <TableCell align="center">{getDateToStringUTC(new Date(item.measure_date))}</TableCell>
                      <TableCell align="center">{item.weight}</TableCell>
                      <TableCell align="center">{item.muscle_mass}</TableCell>
                      <TableCell align="center">{item.body_fat}</TableCell>
                      <TableCell align="center">{item.bmi}</TableCell>
                    </TableRow>
                  )
                })
              }
              {
                modalType === MODAL_TYPE.SENIOR && rows && rows.map((item, index) => {
                  const keyName = `${item.senior_name}_${index}`
                  return (
                    <TableRow key={keyName}>
                      <TableCell align="center"><Checkbox checked={checkRows && checkRows.length > 0 && checkRows[index]} onChange={() => { handleChangeCheckBox(index) }} /></TableCell>
                      <TableCell align="center">{item.senior_center_name}</TableCell>
                      <TableCell align="center">{item.senior_name}</TableCell>
                      <TableCell align="center">{item.senior_gender}</TableCell>
                      <TableCell align="center">{item.senior_medi === '' ? '없음' : item.senior_medi}</TableCell>
                      <TableCell align="center">{(item.senior_medi === '없음') ? '무' : '유'}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </MyTableA>
          <Box sx={{...CSS_CENTER, my: 2, pb: 2}}>
            <Pagination
              showFirstButton showLastButton siblingCount={5}
              count={Math.ceil(total / limit)}
              page={page + 1}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  )
}

export const dummyExport = {};
