import {
  Box,
  Button, Dialog, DialogContent, DialogProps, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as React from 'react'
import { toast } from 'react-hot-toast'
import { apiClient } from '../../../api/common'
import { currentTimeForApi } from '../../../utils/serverTools'
import SimpleYesNoModal from '../../widgets/modals/SimpleYesNoModal'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'


interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  userId: string
  rowId: string
  onFinish: (success:boolean) => void
  dataFields?: string[]
  reasonOptions?: string[]
  apiUrl?: string
}

// const deleteReasonOptions = [
//   '담당 업무 변경으로 인한 개인정보 삭제 목적',
//   '퇴사 및 계약 종료로 인한 개인정보 삭제 목적',
//   '기타'
// ]

export default function HCenterManagerDeleteModal(props: Props) {
  //
  const { open, setOpen, userId, rowId, onFinish, dataFields, reasonOptions, apiUrl} = props
  const [fullWidth, setFullWidth] = React.useState(true)
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm')
  const [open2, setOpen2] = React.useState(false);
  const {logout} = useAuth();
  const navigate = useNavigate();

  const reqDelete = async (values) => {
    const radio = values.reasonRadio
    let finalMsg = reasonOptions[0]
    if (radio === '2') {
      // eslint-disable-next-line prefer-destructuring
      finalMsg = reasonOptions[1]
    } else if (radio === '3') {
      finalMsg = values.reasonEtc
    }

    const reqData = {
      public_care_admin_id: rowId,
      public_care_del_reason: reasonOptions[radio - 1],
      public_care_del_date: currentTimeForApi(),
      public_care_del_user: userId,
      public_care_del_reason_detail: finalMsg
    }

    const config = await checkToken(logout, navigate);

    // console.log('reqData: ', reqData)
    try {
      if (config) {
        const url = '/delete_public_care_admin'
        const res = await apiClient.post(url, reqData, config)
        console.log(`[API ${url}]: `, res.data)

        if (res.data.state === 'ok') toast.success('해당 유저가 삭제되었습니다.')
        else toast.error('삭제에 실패하였습니다.')

        onFinish(res.data.state === 'ok')
      }
    } catch (err) {
      console.error(err)
    }

    setOpen(false)
  }

  const initialValues = {
    reasonRadio: '1',
    reasonEtc: ''
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      // console.log('submit: ', values)
      setOpen2(true)
    }
  })

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={() => setOpen(false)}>
      <DialogTitle sx={{
        textAlign: 'center'
      }}
      >
        회원 탈퇴
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">사유 선택</FormLabel>
            <RadioGroup name="reasonRadio" onChange={formik.handleChange} value={formik.values.reasonRadio}>
              <FormControlLabel value="1" control={<Radio />} label={reasonOptions[0]} />
              <FormControlLabel value="2" control={<Radio />} label={reasonOptions[1]} />
              <FormControlLabel value="3" control={<Radio />} label={reasonOptions[2]} />
            </RadioGroup>
          </FormControl>
          {formik.values.reasonRadio === '3' && (
            <TextField variant="outlined" multiline rows={6} fullWidth
              name="reasonEtc" placeholder="기타 사유를 입력해 주세요. 최소 4자 이상, 최대 500자까지 입력 가능합니다."
              onChange={formik.handleChange} value={formik.values.reasonEtc}
              sx={{mt: 2, mb: 1}}
            />
          )}
        </DialogContent>
        <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', my: 1, mt: 2}}>
          <Button variant="outlined" sx={{m: 1, }} disabled={formik.values.reasonRadio === '3' && (!formik.values.reasonEtc || (formik.values.reasonEtc && formik.values.reasonEtc.length < 4))} type="submit">저장</Button>
          <Button variant="outlined" sx={{m: 1, mr: 5}} color="secondary" onClick={() => setOpen(false)}>취소</Button>
        </Box>
      </form>
      <SimpleYesNoModal
        message="정말 탈퇴하시겠습니까? 탈퇴 시 해당 계정의 모든 기록이 삭제됩니다."
        open={open2}
        setOpen={setOpen2}
        onOk={async () => {
          await reqDelete(formik.values)
          formik.setValues(initialValues)
          setOpen2(false);
        }}
      />
    </Dialog>
  )
}
