import {useCallback, useEffect, useState} from 'react'
import {Box, Container} from '@material-ui/core'
import useMounted from '../../../hooks/useMounted'
import useSettings from '../../../hooks/useSettings'
import gtm from '../../../lib/gtm'
import {AccountType, CONTENT_LAYOUT_PY, PAGE_LIMITS} from '../../consts'
import {apiClient} from '../../api/common'
// import {HCenterManager} from '../../types/user'
// import UserListSearch from '../../components/dashboard/users/UserListSearch'
// import HCenterManagerListTable from '../../components/dashboard/users/HCenterManagerListTable'
// import {hCenterManagerSearchOptions} from '../../genderSelectOptions'
import PageHeader from '../../components/dashboard/PageHeader'
import SpeakerOverviewNumbers from '../../components/dashboard/speakers/SpeakerOverviewNumbers'
import {AiSpeaker, initialValuesSpeakerStock, SpeakerStockData} from '../../types/speaker'
import SpeakerOverviewTable from '../../components/dashboard/speakers/SpeakerOverviewTable'
import {carewellAuthApi} from '../../api/carewellAuthApi';
import SpeakerListSearch from '../../components/dashboard/speakers/SpeakerListSearch';
import {speakerSearchOptions} from '../../options';
import {speakerStatusHCenterOptions, speakerStatusOptions} from '../../typesServiceDesign';
import { useLocation, useNavigate } from 'react-router'
import SpeakerOverviewTableV2 from 'src/__CareWell__/components/dashboard/speakers/SpeakerOverviewTableV2'
import SpeakerListSearchV2 from 'src/__CareWell__/components/dashboard/speakers/SpeakerListSearchV2'
import useAuth from 'src/hooks/useAuth'
import { checkToken } from 'src/__CareWell__/utils/tokenChecker'

const sheetHeader = ['지역', '경로당', '이름', '직군', '관리자ID', '휴대폰번호', '이메일', '최근접속']
const sheetKeyNames = ['public_care_address', 'public_care_company_name', 'public_care_username', 'public_care_job', 'public_care_admin_id', 'public_care_tel', 'public_care_email', 'public_care_recent_connection_time']
const sortColumns = [
  'ai_speaker_status', 'ai_speaker_use_yn', 'ai_speaker_regist_yn'
]

interface LocationState {
  prevPath: string
}

export default function SpeakerOverviewV2() {
  const mounted = useMounted()
  const location = useLocation()
  const { settings } = useSettings()
  const [items, setItems] = useState<AiSpeaker[]>([])
  const [stock, setStock] = useState<SpeakerStockData>(initialValuesSpeakerStock)
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(PAGE_LIMITS[1])
  // const [needTableUpdate, setNeedTableUpdate] = useState(false)
  const [loadingDone, setLoadingDone] = useState<boolean>(false)
  const [speakerList, setSpeakerList] = useState<any[]>([])
  // const [status, setState] = useState<string>(Array.from() as Notice[])
  const [status, setStatus] = useState(location.state ? new Map(Object.entries(location.state)).get('status') : '전체');

  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const isHCenter = carewellAuthApi.getAccountType() === AccountType.HCenterManager
  const userId = carewellAuthApi.loggedInUser.id
  const [searchFilter, setSearchFilter] = useState<any>()

  useEffect(() => {
    gtm.push({event: 'page_view'})
  }, [])

  const apiUrlReadTable = '/get_ai_speaker_list'
  const apiGetStockTable = '/get_ai_speaker_stock_info'
  const apiSearchTable = '/get_ai_speaker_list_search'

  const apiUrlReadTableLimit = '/get_ai_speaker_list_limit'
  const apiSearchTableLimit = '/get_ai_speaker_list_limit_search'
  const apiSearchTableLimitCount = '/get_ai_speaker_list_limit_search_count'

  const initialValuesSpeakerTableSearch = {
    from_date: '',
    end_date: '',
    search_item: '',
    search_content: '',
    area: '',
    // admin_role: '',
    ai_speaker_status: '전체',
    ai_speaker_use_yn: '전체'
  }

  const [updateTableCounter, setUpdateTableCounter] = useState<number>(0)
  const [fullCnt, setFullCnt] = useState<number>(0)
  const navigate = useNavigate();
  const {logout} = useAuth();

  const LoadPage = async () => {
    setLoadingDone(true)
    try {
      setSpeakerList(null)
      // console.log('SpeakerOverview page -')
      // const data = await aiSpeakerApi.getSpeakers() // speakerApiFake.getItems()
      // if (carewellAuthApi.getAccountType() === AccountType.HCenterManager)
      // const url = !isOperator ? `${apiUrlReadTable}?user_id=${userId}` : apiUrlReadTable
      let url;
      if (isOperator) {
        url = `${apiUrlReadTableLimit}/${limit}/${page}?user_id=${userId}&req_type=operator`
        // console.log('this!!', url)
      } else if (isHCenter) {
        url = `${apiUrlReadTableLimit}/${limit}/${page}?user_id=${userId}&req_type=hcenter`
      }
      // console.log(url)
      if (mounted.current) {
        // console.log('getItems: mounted.current is valid. items: ')
        const res = await apiClient.get(url)
        // console.log(`[API ${url}]: `, res.data)
        // console.table('speakers: ', res.data.rows)
        const filtered = res && res.data && res.data.rows && res.data.rows.filter((datum: AiSpeaker) => datum.ai_speaker_network_status === status);
        const list = status === '전체' ? res.data.rows : filtered;
        setItems(list)
        setSpeakerList(list)
      }
    } catch (err) {
      console.error(err)
    }
    setLoadingDone(false)
  }

  const searchPage = async () => {
    try {
      setSpeakerList(null)

      // filter
      let filters = {}

      filters = {...filters, user_id: searchFilter.user_id, req_type: isOperator ? 'operator' : 'hcenter'}
      if (searchFilter.ai_speaker_status !== '') {
        filters = {...filters, ai_speaker_status: searchFilter.ai_speaker_status}
      }
      if (searchFilter.ai_speaker_use_yn !== '') {
        filters = {...filters, ai_speaker_use_yn: searchFilter.ai_speaker_use_yn}
      }
      if (searchFilter.from_date !== '') {
        filters = {...filters, from_date: searchFilter.from_date}
      }
      if (searchFilter.end_date !== '') {
        filters = {...filters, end_date: searchFilter.end_date}
      }
      if (searchFilter.search_item !== '') {
        // console.log(searchFilter.search_item === 'ai_speaker_model_name', searchFilter.search_item === 'ai_speaker_sn')
        if (searchFilter.search_item === 'ai_speaker_model_name') {
          filters = {...filters, ai_speaker_model_name: searchFilter.search_content}
        } else if (searchFilter.search_item === 'ai_speaker_sn') {
          filters = {...filters, ai_speaker_sn: searchFilter.search_content}
        } else if (searchFilter.search_item === 'ai_speaker_fw') {
          filters = {...filters, ai_speaker_fw: searchFilter.search_content}
        }
      }
      //
      const config = await checkToken(logout, navigate);

      // full cnt
      const cnt = await apiClient.post(apiSearchTableLimitCount, filters, config)
      // console.log(filters)
      if (cnt.data.state === 'ok') {
        // search list
        setFullCnt(cnt.data.rows.fullCnt)
        let url;
        if (isOperator) {
          url = `${apiSearchTableLimit}/${limit}/${page}`
        } else {
          url = `${apiSearchTableLimit}/${limit}/${page}`
        }
        const res = await apiClient.post(url, filters, config)
        console.log(`[API ${url}]: `, res.data)
        const filtered = res.data.rows.filter((datum: AiSpeaker) => datum.ai_speaker_network_status === status);
        const list = status === '전체' ? res.data.rows : filtered;
        setItems(list)
        setSpeakerList(list)
        setLoadingDone(false)
      }
    } catch (err) {
      console.error(err)
      setLoadingDone(false)
    }
  }

  const getItems = useCallback(async () => {
    LoadPage()
  }, [mounted])

  useEffect(() => {
    if (searchFilter == null) {
      // console.log('searchFilte1r')
      setFullCnt(stock.full_cnt)
      LoadPage()
    } else {
      // console.log('searchFilte2r')
      searchPage()
    }
  }, [page, limit, searchFilter])

  const getStocks = async () => {
    try {
      let url;
      if (isOperator) {
        url = `${apiGetStockTable}?user_id=${userId}`
      } else if (isHCenter) {
        url = `${apiGetStockTable}?user_id=${userId}&req_type=hcenter`
      }
      // console.log(`[API Req-${url}]: `)
      const res = await apiClient.get(url)
      // console.log(`[API ${url}]: `, res.data)
      const stockData: SpeakerStockData = res.data
      // console.log('stock: ', stockData)
      setStock(stockData)
      setFullCnt(stock.full_cnt)
    } catch (err) {
      console.error(err)
    }
  }

  // const path = (location.state as LocationState)?.prevPath;

  // useEffect(() => {
  //   if (!path || !path?.includes('details')) {
  //     localStorage.removeItem('ai_speaker_list');
  //     getItems();
  //   }
  // }, [path]);

  const doUpdateTable = () => {
    setUpdateTableCounter(updateTableCounter + 1);
  }

  useEffect(() => {
    setLoadingDone(true);
    getItems().then(() => {
      setLoadingDone(false);
    })
    getStocks().then()
  }, [updateTableCounter])

  useEffect(() => {
    setFullCnt(stock.full_cnt)
  }, [stock])

  const paths = []

  return (
    <>
      <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: CONTENT_LAYOUT_PY}}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <PageHeader title="스피커 현황" paths={paths} links={[]} />
          <Box sx={{mt: 3}}>
            <Box sx={{my: 2}}>
              <SpeakerOverviewNumbers stock={stock} fullData={isOperator} />
            </Box>

            <SpeakerListSearchV2 initialValuesSpeakerTableSearch={initialValuesSpeakerTableSearch} records={items} setRecords={setItems} searchUrl={apiSearchTableLimit}
              categorySearch={speakerSearchOptions} doUpdateTable={doUpdateTable} setLoadingDone={setLoadingDone}
              statusOptions={isOperator ? speakerStatusOptions : speakerStatusHCenterOptions} setSearchFilter={setSearchFilter}
            />

            <Box sx={{my: 2}} />

            <SpeakerOverviewTableV2 speakerList={speakerList} doUpdateTable={doUpdateTable} fullCnt={fullCnt}
              sortColumns={sortColumns} loadingDone={loadingDone} setPage={setPage} page={page} setLimit={setLimit} limit={limit}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}


