import { format, getDay } from 'date-fns'

const weekdayMap = ['일', '월', '화', '수', '목', '금', '토']

// Date -> "2021-01-01 12:00:00" 형식으로.
export const toStartDateFormatOnApi = (date: Date) : string => format(date, 'yyyy-MM-dd 00:00:00')
// export const toEndDateFormatOnApi = (date: Date) : string => format(date, 'yyyy-MM-dd 24:00:00')
export const toEndDateFormatOnApi = (date: Date) : string => format(date, 'yyyy-MM-dd 23:59:59')

export const toDateTimeFormatOnApi = (date: Date) : string => format(date, 'yyyy-MM-dd HH:mm:ss')

export const currentTimeForApi = () : string => format(new Date(), 'yyyy-MM-dd HH:mm:ss')
export const currentTimeForApiNoSecond = (date) : string => format(date, 'yyyy-MM-dd HH:mm:ss')
export const currentTimeForApiWithLocalDay = () : string => format(new Date(), `yyyy-MM-dd ${weekdayMap[getDay(new Date())]}요일 HH:mm`)

// eslint-disable-next-line @typescript-eslint/naming-convention
export const toDateTimeFormatOnApi_yMd_hm = (date: Date) : string => format(date, 'yyyy-MM-dd HH:mm')
export const toDateTimeLocalTemp = (date: Date) : string => format(date, 'yyyy-MM-dd HH:mm:ss')
export const serverTimeFormatToUI = (date: string) : string => date.replace(' ', 'T')
export const uiToServerTimeFormat = (date: string) : string => date.replace('T', ' ')

// dateFns.format(item.cpe_reset_time, 'yyyy-MM-dd hh:mm:ss')
// export const apiTimeFormatToUI = (date: stri)

export const getDateYyyyMmDd = (date: Date) : string => format(date, 'yyyy-MM-dd')

// export const getDateDefaultValue = (date: Date) : string => format(date, 'yyyy-MM-dd')
