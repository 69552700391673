export const getCookie = (cookie_name) => {
  let x; let y;
  const val = document.cookie.split(';');

  for (let i = 0; i < val.length; i++) {
    x = val[i].substring(0, val[i].indexOf('='));
    y = val[i].substring(val[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
    if (x === cookie_name) {
      return unescape(y); // unescape로 디코딩 후 값 리턴
    }
  }
  return ''
}

export const setCookie = (cookie_name, value, days) => {
  console.log('setting token!!!');
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + days);
  // 설정 일수만큼 현재시간에 만료값으로 지정

  const cookieValue = escape(value) + ((days == null) ? '' : `; expires=${exdate.toUTCString()}`);
  document.cookie = `${cookie_name}=${cookieValue}`;
}

export const deleteCookie = (name) => {
  if (document.cookie.split(';').some((c) => c.trim().startsWith(`${name}=`))) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
