export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const BLOODPRESSURE_RESULT = [
  '정상', '저혈압', '고혈압'
]
export const BLOODSUGAR_RESULT = [
  '정상', '주의', '위험'
]
export const WEIGHT_RESULT = [
  '표준', '야윔', '비만'
]

export const MODAL_TYPE = {
  SENIOR: 'senior',
  CENTER: 'center',
  SPEAKER: 'speaker',
  ONEDAY: 'oneday',
  SENIOR_ALL: 'senior_all',
  CENTER_ALL: 'center_all',
  SPEAKER_ALL: 'speaker_all',
  ONEDAY_ALL: 'oneday_all',
  PRESSURE: 'bloodpressure',
  SUGAR: 'bloodsugar',
  WEIGHT: 'weight'
}

export const MODAL_ALL_TYPE = {
  SENIOR: 'senior',
  CENTER: 'center',
  UNUSED_SPEAKER: 'unused_speaker',
  ONEDAY: 'oneday',
}

export const QUIT_TYPE = {
  SENIOR: 'quit_senior',
  CENTER: 'quit_hcenter',
  OPERATOR: 'quit_operator'
}

export const USER_TYPE = {
  SENIOR: 'senior',
  CENTER: 'hcenter',
  OPERATOR: 'operator'
}

export const FILTER_TYPE = [
  'area', 'dong', 'center'
]
export const TABLE_OPTION = {
  SENIOR: [
    '경로당명', '이름', '성별', '복약 유무', '위험군 대상자'
  ],
  CENTER: [
    '경로당명', '경로당 주소', '경로당 전화번호', '관리자 이름', '관리자 전화번호'
  ],
  SPEAKER: [
    '스피커 아이디', '시리얼 번호', '설치된 경로당명', '최근 사용 시간', '펌웨어 정보'
  ],
  ONEDAY: [
    '경로당명', '이름', '성별', '복약 유무', '위험군 대상자'
  ],
  PRESSURE: [
    '소속기관', '경로당명', '이름', '성별', '혈압 측정 일자', '수축기', '이완기', '맥박'
  ],
  SUGAR: [
    '소속기관', '경로당명', '이름', '성별', '혈당 측정 일자', '식전', '식후 1시간', '식후 2시간'
  ],
  WEIGHT: [
    '소속기관', '경로당명', '이름', '성별', '체지방 측정 일자', '체중', '근육량', '체지방량', 'BMI'
  ]
}
export const dateOptions = [
  {id: '1', label: '오늘'},
  {id: '2', label: '일주일'},
  {id: '3', label: '한달'},
  {id: '4', label: '3개월'}
]

export const BLOODPRESSUREFLAG = {
  NORMAL: '정상',
  HIGH: '고혈압',
  ROW: '저혈압'
}

export const BLOODSUGARFLAG = {
  NORMAL: '정상',
  HIGH: '위험',
  ROW: '주의'
}
export const WEIGHTFLAG = {
  NORMAL: '표준',
  HIGH: '비만',
  ROW: '야윔'
}


export const FLOOR = {
  SI: 0, // oo시
  DONG: 1, // oo동
  CENTER: 2, // oo경로당
}
export const DATETYPE = {
  ALL: 1, WEEK: 2, MONTH: 3, THREEMONTH: 4
}
export const getDateToString = (date) => {
  const inputDate = new Date(date)

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  return formattedDate;
}


export const getDateTimeToString = (date) => {
  const inputDate = new Date(date)

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:00`;
  return formattedDate;
}


export const getDateToStringUTC = (date) => {
  const inputDate = new Date(date)

  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:00`;
  return formattedDate;
}

export const getDateToStringUTC2 = (date) => {
  const inputDate = new Date(date)

  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const second = String(inputDate.getUTCSeconds()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${second}`;
  return formattedDate;
}


export const getStringUTCYYYYMMDD = (date) => {
  const inputDate = new Date(date)

  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export const calculateAge = (birthdate) => {
  // 입력받은 생년월일을 Date 객체로 변환
  const birthDate = new Date(birthdate);

  // 현재 날짜를 가져옴
  const currentDate = new Date();

  // 생년월일에서 현재 날짜를 빼고 연도 차이 계산
  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // 만약 생일이 지났는지 체크
  // 현재 달이 생일의 달보다 작거나 같고, 현재 일이 생일의 일보다 작거나 같으면 아직 생일이 오지 않은 것이므로 나이에서 1을 빼줌
  if (currentDate.getMonth() < birthDate.getMonth()
      || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}
