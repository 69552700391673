/* eslint-disable no-await-in-loop */
import { Button } from '@material-ui/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MODAL_TYPE, calculateAge, getDateToStringUTC, getStringUTCYYYYMMDD } from 'src/__CareWell__/commons/commons';
import { checkToken } from 'src/__CareWell__/utils/tokenChecker';
import useAuth from 'src/hooks/useAuth';
import * as XLSX from 'xlsx-js-style';

interface Props<Type> {
  rows?: Type[],
  modalType : string,
  xlsxDisabled: boolean,
  getUserHistoryDetail: (uid:number) => Promise<any>,
  setDownloadLoading?: (isLoading:boolean)=>void,
  setDonwloadLoadingText?: (text:string)=>void,
}

const SheetJSFT = [
  'xlsx', 'xlsb', 'xlsm', 'xls', 'xml', 'csv', 'txt', 'ods', 'fods', 'uos', 'sylk', 'dif', 'dbf', 'prn', 'qpw', '123', 'wb*', 'wq*', 'html', 'htm'
].map((x) => `.${x}`).join(',')

export default function StatisticsIndividualXlsxV2<Type>(props:Props<Type>) {
  const navigate = useNavigate()
  const {logout} = useAuth();
  const {rows, modalType, xlsxDisabled, getUserHistoryDetail, setDownloadLoading, setDonwloadLoadingText} = props;

  const textboldStyle = {font: {name: '맑은고딕', bold: true}}
  const headerboxstyle = { width: 260, font: { name: '맑은 고딕', bold: true }, alignment: { horizontal: 'center' }, border: { top: { style: 'thin', color: 'black' }, bottom: { style: 'thin', color: 'black' }, left: { style: 'thin', color: 'black' }, right: { style: 'thin', color: 'black' } } }
  const cellboxstyle = { font: { name: '맑은 고딕'}, alignment: { horizontal: 'center' }, border: { top: { style: 'thin', color: 'black' }, bottom: { style: 'thin', color: 'black' }, left: { style: 'thin', color: 'black' }, right: { style: 'thin', color: 'black' } }, width: 160 }
  const celldangerousboxstyle = { fill: { fgColor: { rgb: 'E5331B' } }, alignment: { horizontal: 'center' }, border: { top: { style: 'thin', color: 'black' }, bottom: { style: 'thin', color: 'black' }, left: { style: 'thin', color: 'black' }, right: { style: 'thin', color: 'black' } } }
  //   const cellwarningboxstyle = { fill: { fgColor: { rgb: 'FF7F00' } }, alignment: { horizontal: 'center' }, border: { top: { style: 'thin', color: 'black' }, bottom: { style: 'thin', color: 'black' }, left: { style: 'thin', color: 'black' }, right: { style: 'thin', color: 'black' } } }
  const cellwarningboxstyle = cellboxstyle;
  const addString = (value, style) => ({
    t: 's',
    v: value,
    s: style
  })
  const addNumber = (value, style) => ({
    t: 'n',
    v: value,
    s: style
  })

  //   const getPressureDetail = async (useruid) => {
  //     const config = await checkToken(logout, navigate);
  //     const url = `/get_user_pressure_attend_datail/${useruid}`;
  //     // console.log(url)
  //     const reqData = {
  //       from_date: searchFromDate === '' ? `${getDateToString(new Date())} 00:00:00` : getFromDate(),
  //       end_date: searchEndDate === '' ? `${getDateToString(new Date())} 23:59:59` : getEndDate()
  //     }
  //     return apiClient.post(url, reqData, config).then((res) => {
  //       if (res.data.state === 'ok') {
  //         return res.data.result;
  //       } return null
  //     })
  //   }

  const changeExcelData = async (results) => {
    const xl = [];
    let item = []
    let value = null
    // 제목
    value = addString('1. 경로당별 등록자 리스트(가나다순)', textboldStyle);
    item.push(value);
    xl.push(item);
    // 경로당별 등록자 리스트 헤더
    const titles = ['연번', '이름', '성별', '소속 경로당', '연락처', '복약종류', '등록일자'];
    xl.push(titles.map((name) => addString(name, headerboxstyle)))
    // 경로당별 등록자 리스트 내용
    console.log(rows)
    setDownloadLoading(true)
    setDonwloadLoadingText(`다운로드 중(${0}/${rows.length})`)

    for (let nIdx = 0; nIdx < rows.length; nIdx++) {
      const element = rows[nIdx] as any;
      item = [];
      item.push(addString(nIdx + 1, cellboxstyle))
      item.push(addString(element.senior_name, cellboxstyle))
      item.push(addString(element.senior_gender, cellboxstyle))
      item.push(addString(element.senior_center_name, cellboxstyle))
      item.push(addString(element.senior_tel, cellboxstyle))
      item.push(addString(element.senior_medi, cellboxstyle))
      item.push(addString(getStringUTCYYYYMMDD(element.senior_agreement_date), cellboxstyle))
      xl.push(item)
    }
    xl.push([])
    xl.push([])

    // 사용자 리스트 타이틀 추가
    value = addString('2. 사용자 리스트', textboldStyle);
    item = [];
    item.push(value);
    xl.push(item);

    for (let nIdx = 0; nIdx < rows.length; nIdx++) {
      const element = rows[nIdx] as any;
      // 사용자 정보 헤더
      const elementheader = ['이름', '성별', '나이', '신장', '복약종류', '등록일자', '경로당명'];
      xl.push(elementheader.map((name) => addString(name, headerboxstyle)))

      item = [];
      item.push(addString(element.senior_name, cellboxstyle))
      item.push(addString(element.senior_gender, cellboxstyle))
      item.push(addString(calculateAge(element.senior_date_of_birth), cellboxstyle))
      item.push(addString(element.senior_stature, cellboxstyle))
      item.push(addString(element.senior_medi, cellboxstyle))
      item.push(addString(getStringUTCYYYYMMDD(element.senior_agreement_date), cellboxstyle))
      item.push(addString(element.senior_center_name, cellboxstyle))
      xl.push(item)
      xl.push([])

      // eslint-disable-next-line no-await-in-loop
      const res = await getUserHistoryDetail((element as any).uid)
      await setDonwloadLoadingText(`다운로드 중(${nIdx + 1}/${rows.length})`)
      const temp = [].concat(...res);
      const sortedHistoryData = temp.sort((a, b) => (new Date(a.measure_date) as any) - (new Date(b.measure_date) as any));

      const historyheader = ['측정날짜', '혈압', '복약유무', '수축기', '이완기', '맥박', '혈당', '복약유무', '식전', '식후1시간', '식후2시간', '체지방', '체중', '체지방', '근육량', 'BMI', '뇌운동사용']
      xl.push(historyheader.map((name) => addString(name, headerboxstyle)))

      for (let num = 0; num < sortedHistoryData.length; num++) {
        const historyone = sortedHistoryData[num] as any;
        item = [];
        if ('systolic' in historyone) { // 혈압
          let cellstyle = null;
          if (historyone.systolic < 120) cellstyle = cellwarningboxstyle;
          else if (historyone.systolic >= 140) cellstyle = celldangerousboxstyle;
          else cellstyle = cellboxstyle;

          item.push(addString(getDateToStringUTC(historyone.measure_date), cellboxstyle));
          item.push(addString('혈압', cellboxstyle));
          item.push(addString(historyone.medication === 'Y' ? 'O' : 'X', cellboxstyle));
          item.push(addString(historyone.systolic, cellstyle));
          item.push(addString(historyone.diastole, cellstyle));
          item.push(addString(historyone.pulse, cellstyle));
          item.push(addString('혈당', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('체지방', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('', cellboxstyle));
        } else if ('classification' in historyone) { // 혈당
          let cellstyle = null;
          if (historyone.classification === 'MES_BEFORE') {
            if (historyone.value < 99) cellstyle = cellboxstyle;
            else if (historyone.value < 125) cellstyle = cellwarningboxstyle;
            else cellstyle = celldangerousboxstyle;
          } else if (historyone.classification === 'MES_AFTER1H') {
            if (historyone.value < 180) cellstyle = cellboxstyle;
            else if (historyone.value <= 200) cellstyle = cellwarningboxstyle;
            else cellstyle = celldangerousboxstyle;
          } else if (historyone.classification === 'MES_AFTER2H') {
            if (historyone.value < 140) cellstyle = cellboxstyle;
            else if (historyone.value < 200) cellstyle = cellwarningboxstyle;
            else cellstyle = celldangerousboxstyle;
          } else {
            cellstyle = cellboxstyle;
          }
          item.push(addString(getDateToStringUTC(historyone.measure_date), cellboxstyle));
          item.push(addString('혈압', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('혈당', cellboxstyle));
          item.push(addString(historyone.medication === 'Y' ? 'O' : 'X', cellboxstyle));
          item.push(addString(historyone.classification === 'MES_BEFORE' ? historyone.value : [], cellstyle));
          item.push(addString(historyone.classification === 'MES_AFTER1H' ? historyone.value : [], cellstyle));
          item.push(addString(historyone.classification === 'MES_AFTER2H' ? historyone.value : [], cellstyle));
          item.push(addString('체지방', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('', cellboxstyle));
        } else if ('bmi' in historyone) { // 체지방
          const age = calculateAge(element.senior_date_of_birth);
          const gender = element.senior_gender;
          const {bmi} = historyone;

          let cellstyle = null;
          if (age > 17 && age < 40) {
            if (gender === '여') {
              if (bmi <= 20)cellstyle = cellwarningboxstyle;
              else if (bmi > 20 && bmi <= 33)cellstyle = cellboxstyle;
              else if (bmi > 33)cellstyle = celldangerousboxstyle;
              else cellstyle = cellwarningboxstyle;
            } else if (bmi <= 8)cellstyle = cellwarningboxstyle;
            else if (bmi > 8 && bmi <= 20)cellstyle = cellboxstyle;
            else if (bmi > 20)cellstyle = celldangerousboxstyle;
            else cellstyle = cellwarningboxstyle;
          } else if (age >= 40 && age < 60) {
            if (gender === '여') {
              if (bmi <= 22)cellstyle = cellwarningboxstyle;
              else if (bmi > 22 && bmi <= 34)cellstyle = cellboxstyle;
              else if (bmi > 34)cellstyle = celldangerousboxstyle;
              else cellstyle = cellwarningboxstyle;
            } else if (bmi <= 11)cellstyle = cellwarningboxstyle;
            else if (bmi > 11 && bmi <= 22)cellstyle = cellboxstyle;
            else if (bmi > 22)cellstyle = celldangerousboxstyle;
            else cellstyle = cellwarningboxstyle;
          } else if (age >= 60) {
            if (gender === '여') {
              if (bmi <= 23)cellstyle = cellwarningboxstyle;
              else if (bmi > 23 && bmi <= 36)cellstyle = cellboxstyle;
              else if (bmi > 22)cellstyle = celldangerousboxstyle;
              else cellstyle = cellwarningboxstyle;
            } else if (bmi <= 13)cellstyle = cellwarningboxstyle;
            else if (bmi > 13 && bmi <= 25)cellstyle = cellboxstyle;
            else if (bmi > 22)cellstyle = celldangerousboxstyle;
            else cellstyle = cellwarningboxstyle;
          } else {
            cellstyle = cellwarningboxstyle;
          }
          item.push(addString(getDateToStringUTC(historyone.measure_date), cellboxstyle));
          item.push(addString('혈압', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('혈당', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('체지방', cellboxstyle));
          item.push(addString(historyone.weight, cellstyle));
          item.push(addString(historyone.body_fat, cellstyle));
          item.push(addString(historyone.muscle_mass, cellstyle));
          item.push(addString(historyone.bmi, cellstyle));
          item.push(addString('', cellboxstyle));
        } else { // 브레인
          item.push(addString(getDateToStringUTC(historyone.measure_date), cellboxstyle));
          item.push(addString('혈압', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('혈당', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('체지방', cellboxstyle));
          item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle)); item.push(addString('', cellboxstyle));
          item.push(addString('O', cellboxstyle));
        }
        xl.push(item)
      }
      xl.push([])

    //   getUserHistoryDetail((element as any).uid)
    //     .then((res) => {
    //       console.log(res)
    //     })
    }
    setDownloadLoading(false)
    return xl;
  }


  const exportFile = async (seniorlist) => {
    const len = seniorlist.length;
    let filename = '사용자정보'
    if (modalType === MODAL_TYPE.SENIOR) filename = '경로당사용자정보'
    else if (modalType === MODAL_TYPE.PRESSURE) filename = '혈압측정정보'
    else if (modalType === MODAL_TYPE.SUGAR) filename = '혈당측정정보'
    else if (modalType === MODAL_TYPE.WEIGHT) filename = '체지방측정정보'

    const excelRows = await changeExcelData(rows)
    const ws = XLSX.utils.aoa_to_sheet(excelRows);
    ws['!cols'] = [{ width: 15.00 }, { width: 11.83 }, { width: 8.83 }, { width: 14.33 }, { width: 16.83 }, { width: 16.83 }, { width: 11.83 }];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const date = new Date();
    const filen = `${filename}${date.getTime()}.xlsx`;
    XLSX.writeFile(wb, filen);
  }
  useEffect(() => {
  }, [rows]);
  return (
    <div className="row">
      <div className="col-xs-12">
        <Button id="xlsxButton" variant="outlined" onClick={() => exportFile(rows)} disabled={xlsxDisabled}>
          {/* <Box component="img" sx={{height: 28, width: 30}} src="/static/icons/excel.svg" /> */}
          개별 엑셀 다운로드
        </Button>
      </div>
    </div>
  )
}
