/* eslint-disable react/jsx-closing-tag-location */
import { Box, Card, Modal, Pagination, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CSS_CENTER, PAGE_LIMITS, RIGHT_VERTICAL_BAR_COUNT } from 'src/__CareWell__/consts';
import X from 'src/icons/X';

import MyRowsPerPage from 'src/__CareWell__/components/MyRowsPerPage';
import StatisticsTableV2 from './StatisticsTableV2';
import { FILTER_TYPE, FLOOR, MODAL_TYPE } from 'src/__CareWell__/commons/commons';
import useAuth from 'src/hooks/useAuth';
import { checkToken } from 'src/__CareWell__/utils/tokenChecker';
import styled from 'styled-components';
import { HeartSpinner } from 'react-spinners-kit';
import useMounted from 'src/hooks/useMounted'
import XlsxControlIndividual from 'src/__CareWell__/components/widgets/XlsxControlIndividual';
import XlsxControl from 'src/__CareWell__/components/widgets/XlsxControl';
import StatisticsXlsxV2 from './StatisticsXlsxV2';
import StatisticsIndividualXlsxV2 from './StatisticsIndividualXlsxV2';

const Loading = styled.div`
  width:100%;
  min-width:300px;
  height:100%;
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:999;
  background-color:white;
`

const DownLoading = styled.div`
  width:100%;
  min-width:300px;
  height:100%;
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:999;
  background-color:white;

  .wrap{
    height:100px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  .exp{
    margin-top:5px;
    font-size:12px;
  }
`

type Props = {
  modalType:string,
  modalDetailType:string,
  modalTotal: number,
  showOverviewModal: boolean,
  setShowOverviewModal: (show: boolean) => void,
  doUpdateModalTable: (page:number, limit:number, type:string) => Promise<any>,
  getUserHistoryDetail: (uid:number) => Promise<any>,
}

const HEIGHT = 72
const KEYWORD_DEFAILT = '경기도 의왕시';
const TITLE_WIDTH = 100

export default function StatisticsModalV2(props: Props) {
  const {modalType, modalDetailType, doUpdateModalTable, showOverviewModal, setShowOverviewModal, modalTotal, getUserHistoryDetail
  } = props;
  const mounted = useMounted()
  const {logout} = useAuth();
  const navigate = useNavigate();

  const [limit, setLimit] = useState<number>(PAGE_LIMITS[1]);
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<any>([]);
  const [selectRows, setSelectRows] = useState<any>([]);
  const [disableXlsx, setDisalbeXlsx] = useState(true);

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadLoadingText, setDownloadLoadingText] = useState('...');

  const handlePageChange = () => {

  }
  const onRowsPerPageChange = (limitNumber: number): void => {
    setLimit(limitNumber)
  }

  useEffect(() => {
    setLoading(true)
    if (showOverviewModal) {
      setRows([])
      setSelectRows([])
      setDisalbeXlsx(true);
      doUpdateModalTable(page, limit, modalType)
        .then((data) => {
          if (data != null) {
            setRows(data)
          }
          setLoading(false)
        })
    }
  }, [showOverviewModal, page, limit])

  useEffect(() => {
    console.log(selectRows)
  }, [selectRows])
  useEffect(() => {
    console.log('donwloading', downloadLoading)
  }, [downloadLoading, downloadLoadingText])

  return (
    <Modal
      open={showOverviewModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: '24',
        p: 5,
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end'
        }}
        >
          <X sx={{ml: 1, cursor: 'pointer', float: 'right', width: '20px'}} onClick={(e) => {
            e.preventDefault();
            setPage(0);
            setSelectRows([]);
            setDisalbeXlsx(true);
            setShowOverviewModal(false);
          }}
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 0, mt: 1, bgcolor: 'background.paper', height: HEIGHT}}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{p: 1, ml: 2}}>
              <Typography width={TITLE_WIDTH} align="right" sx={RIGHT_VERTICAL_BAR_COUNT}>{`전체: ${modalTotal}`}</Typography>
            </Box>
            <Box sx={{p: 1}}>
              <MyRowsPerPage rowsPerPage={limit} setRowsPerPage={setLimit} rowsPerPageOptions={PAGE_LIMITS} onRowsPerPageChange={onRowsPerPageChange} />
            </Box>
          </Box>
          <Box sx={{display: 'absolute', right: 0}}>
            {modalType === MODAL_TYPE.SENIOR && <StatisticsIndividualXlsxV2 rows={selectRows} modalType={modalType} xlsxDisabled={disableXlsx} getUserHistoryDetail={getUserHistoryDetail} setDownloadLoading={setDownloadLoading} setDonwloadLoadingText={setDownloadLoadingText} />}
            {modalType !== MODAL_TYPE.SENIOR && <StatisticsXlsxV2 rows={selectRows} modalType={modalType} xlsxDisabled={disableXlsx} setDownloadLoading={setDownloadLoading} setDonwloadLoadingText={setDownloadLoadingText} />}
          </Box>
        </Box>
        <Card sx={{ mt: 1, height: 400, display: 'flex', alignItems: 'center', position: 'relative'}}>
          {downloadLoading && <DownLoading>
            <div className="wrap">
              <HeartSpinner color="#249ece" />
              <div className="exp">{downloadLoadingText}</div>
            </div>
          </DownLoading>}
          {loading && <Loading><HeartSpinner color="#249ece" /></Loading>}
          <StatisticsTableV2 rows={rows} selectRows={selectRows} setSelectRows={setSelectRows} modalType={modalType} total={modalTotal} limit={limit}
            page={page} setPage={setPage} setDisalbeXlsx={setDisalbeXlsx}
          />
        </Card>
      </Box>
    </Modal>
  )
}
