import {
  Box,
  Button, Dialog, DialogContent, DialogProps, DialogTitle, Typography
} from '@material-ui/core'
import * as React from 'react'


interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  title?: string
  message: string
  onOk: () => void
  // onCancel: () => void
}

export default function SimpleYesNoModal(props: Props) {
  //
  const { open, setOpen, title = '', message, onOk } = props
  const [fullWidth, setFullWidth] = React.useState(true)
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xs')

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography align="center">{message}</Typography>
      </DialogContent>

      <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', my: 1, mt: 2}}>
        <Button variant="outlined" sx={{m: 1}} onClick={onOk}>확인</Button>
        <Button variant="outlined" sx={{m: 1}} color="secondary" onClick={() => setOpen(false)}>취소</Button>
      </Box>
    </Dialog>
  )
}
