import {Box, experimentalStyled, Paper, Table, TableRow, Typography} from '@material-ui/core'
import SortIcon from '@material-ui/icons/Sort'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '../icons/ChevronRight' //  '../../../icons/ChevronRight'
import {ArrowRight} from '@material-ui/icons'
import ArrowRightIcon from '../icons/ArrowRight'
import Label from '../components/Label'
import React from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';

// export const CSS_CENTER = {
//   justifyContent: 'center',
//   display: 'flex'
// }

export const sxFormErrorText = {color: '#F44336', fontSize: 12, ml: 2}

export const SX_SORT_COLOR = ['#26AAD6', 'primary', '#26AAD6']
export const SORT_A = () => (<ExpandLessIcon sx={{color: SX_SORT_COLOR[0]}} />)
export const SORT_B = () => (<ExpandMoreIcon sx={{color: SX_SORT_COLOR[1]}} />)
export const SORT_C = () => (<ExpandMoreIcon sx={{color: SX_SORT_COLOR[2]}} />)
export const SORT_ICONS = [SORT_A, SORT_B, SORT_C]

export const SX_INPUT_TEXTFIELD = {width: 220}
export const SX_TEMP_BORDER = {border: 0}


export function sortD() {
  return <ExpandMoreIcon />
}
export function getSortIcon(index: number) {
  switch (index) {
    case -1:
      return <ExpandLessIcon sx={{color: SX_SORT_COLOR[0]}} />
    case 1:
      return <ExpandMoreIcon sx={{color: SX_SORT_COLOR[2]}} />
    default:
      return <ExpandMoreIcon sx={{color: SX_SORT_COLOR[1]}} />
  }
}

// const TEMP_ELEM = (<Typogra)
// const TEMP_ELEM = () => (
//   <Box sx={{p: 1, bgcolor: 'grey.300'}}><Typography width={TITLE_WIDTH} align="right">지역</Typography></Box>
// )

// export const SORT_ICONS = [(<ExpandLessIcon sx={{color: SX_SORT_COLOR[0]}}/>)]

export const SX_SEARCH_TITLE = {p: 1, mr: 2, bgcolor: 'grey.300', borderRadius: '4px'}


// export const RecordDetailsIcon = ChevronRightIcon

export const RecordDetailsIcon2 = () => (
  <ArrowRightIcon fontSize="small" />
)

export const ROW_DETAILS_COLOR = '#50717e'

export const MyTableA = experimentalStyled(Table)(({theme}) => ({
  // mt: theme.spacing(1),
  // size: 'small',
  // '& thead th': {
  //   fontWeight: '600',
  //   color: theme.palette.primary.main,
  //   backgroundColor: theme.palette.primary.light
  // },
  // '& tbody td': {
  //   fontWeight: '350'
  // },
  '& tbody tr:hover': {
    backgroundColor: '#fffbf2',
    cursor: 'pointer'
  }
}))

export const SX_TABLE_HEADER_A = {
  height: 60,
  bgcolor: '#fafaf4',
  border: '1px solid #FF0000',
  borderColor: '#F0F0F0',
  borderRadius: '40px 4px 0px 0px'
}



export const getCPEStatusLabel = (inventoryType: string): JSX.Element => {
  console.log('inventoryType: ', inventoryType)
  if (!inventoryType) {
    return (<Label color="warning">없음</Label>)
  }
  if (inventoryType === '정상') return (<Label color="success">{inventoryType}</Label>)
  if (inventoryType === '실패') return (<Label color="error">{inventoryType}</Label>)
  // if (inventoryType === '없음') return (<Label color="warning">{inventoryType}</Label>)
  return (<Label color="warning">{inventoryType}</Label>)
}

export const DomDuplicateId = (arg: number) => {
  if (arg === 1) {
    return <Typography variant="caption" display="block" sx={{ml: 1}} color="#0000FF">사용 가능합니다.</Typography>
  }
  if (arg === 2) {
    return <Typography variant="caption" display="block" sx={{ml: 1}} color="#FF0000">사용 중인 아이디입니다.</Typography>
  }
  return (<div />)
}

export const CenteredStackItem = experimentalStyled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))


export const domCheckIdDuplication = (arg: number) => {
  if (arg === 1) {
    return <Typography variant="caption" display="block" sx={{ml: 1}} color="#0000FF">사용 가능합니다.</Typography>
  }
  if (arg === 2) {
    return <Typography variant="caption" display="block" sx={{ml: 1}} color="#FF0000">사용 중인 아이디입니다.</Typography>
  }
  return (<div />)
}

export const fileAttachedIcon = (filename: string) => (filename ? <AttachFileIcon /> : <div />)
