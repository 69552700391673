import { SelectInputOptions, TableOptions } from './consts';

export const globalUseMemo = false;

export const genderSelectOptions = [
  {
    label: '남',
    value: '남',
  },
  {
    label: '여',
    value: '여',
  },
];
export const appFreeOptions = [
  {
    label: '무료',
    value: '무료',
  },
  {
    label: '유료',
    value: '유료',
  },
];
export const appTypeOptions = [
  {
    label: 'AI 콘텐츠',
    value: 'AI 콘텐츠',
  },
  {
    label: '일반',
    value: '일반',
  },
];
// export const recipientSearchOptions = [
//   {label: '', value: ''},
//   {label: '경로당명', value: 'silver_center_name'},
//   {label: '이름', value: 'silver_name'},
//   {label: '스피커 ID', value: 'ai_speaker_id'},
//   {label: 'CPE 번호', value: 'cpe_id'}
// ]

export const recipientSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '경로당명', value: 'silver_center_name' },
    { label: '이름', value: 'silver_name' },
    { label: '위험군 대상자', value: 'silver_in_danger' },
    { label: '복약 유무', value: 'silver_in_pharmacy' },
  ],
};

export const recipientQuitSearchOption: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '경로당명', value: 'silver_center_name' },
    { label: '이름', value: 'silver_name' },
    { label: '탈퇴 처리자', value: 'silver_admin_id_delete_action' },
  ],
};

export const opOverviewTotalSeniorCenterCount: TableOptions = { // 총 경로당 수
  items: [
    { label: '소속 기관', value: 'public_care_name' },
    { label: '경로당명', value: 'senior_center_name' },
    { label: '모델명', value: 'ai_speaker_model_name' },
    { label: '시리얼 번호', value: 'ai_speaker_sn' },
    { label: '기기상태', value: 'ai_speaker_status' },
    { label: '사용여부', value: 'ai_speaker_use_yn' },
    { label: '서비스인증', value: 'ai_speaker_regist_yn' },
  ],
};

export const opOverviewTotalSeniorsCountWOPubName: TableOptions = { // 총 등록자 수, 당일 출석 인원 수
  items: [
    { label: '경로당명', value: 'senior_center_name' },
    { label: '이름', value: 'silver_name' },
    { label: '성별', value: 'silver_gender' },
    { label: '복약 유무', value: 'silver_medi' },
    { label: '위험군 대상자', value: 'senior_in_danger' },
    { label: '어르신 uid', value: 'silver_uid' },
  ],
};

export const opOverviewTotalSeniorsCount: TableOptions = { // 총 등록자 수, 당일 출석 인원 수
  items: [
    { label: '소속 기관', value: 'public_care_name' },
    { label: '경로당명', value: 'senior_center_name' },
    { label: '이름', value: 'senior_name' },
    { label: '성별', value: 'senior_gender' },
    { label: '복약 유무', value: 'medi_yn' },
    { label: '위험군 대상자', value: 'senior_in_danger' },
    { label: '어르신 uid', value: 'silver_uid' },
  ],
};

export const opOverviewUnusedSpeakersCount: TableOptions = { // 전국 미사용 스피커
  items: [
    { label: '소속 기관', value: 'public_care_name' },
    { label: '경로당명', value: 'senior_center_name' },
    { label: '모델명', value: 'ai_speaker_model_name' },
    { label: '시리얼 번호', value: 'ai_speaker_sn' },
    { label: '기기상태', value: 'ai_speaker_status' },
    { label: '사용여부', value: 'ai_speaker_use_yn' },
    { label: '서비스인증', value: 'ai_speaker_regist_yn' },
  ],
};

export const opLocalSpeakersCount: TableOptions = {
  items: [
    { label: '스피커 아이디', value: 'user_id' },
    { label: '시리얼 번호', value: 'ai_speaker_sn' },
    { label: '설치된 경로당명', value: 'senior_center_name' },
    { label: '사용여부', value: 'ai_speaker_use_yn' },
    { label: '서비스인증', value: 'ai_speaker_regist_yn' },
  ],
};

export const opLocalSeniorsCount: TableOptions = {
  items: [
    { label: '경로당명', value: 'senior_center_name' },
    { label: '이름', value: 'senior_name' },
    { label: '성별', value: 'senior_gender' },
    { label: '복약 유무', value: 'medi_yn' },
    { label: '위험군 대상자', value: 'senior_in_danger' },
    { label: '어르신 uid', value: 'silver_uid' },
  ],
};

export const opLocalCentersCount: TableOptions = {
  items: [
    { label: '', value: 'senior_center_uid'},
    { label: '경로당명', value: 'senior_center_name'},
    { label: '경로당 주소', value: 'senior_center_address'},
    { label: '경로당 전화번호', value: 'senior_center_tel'},
    { label: '관리자 이름', value: 'senior_center_manager'},
    { label: '관리자 전화번호', value: 'senior_center_manager_tel'},
  ]
}

export const opSpecificAiSpeakerData: TableOptions = {
  items: [
    // { label: '모델명', value: 'ai_speaker_model_name' },
    // { label: '시리얼 번호', value: 'ai_speaker_sn' },
    // { label: '기기상태', value: 'ai_speaker_status' },
    // { label: '사용여부', value: 'ai_speaker_use_yn' },
    // { label: '서비스인증', value: 'ai_speaker_regist_yn' },
    { label: '스피커 아이디', value: 'user_id' },
    { label: '시리얼 번호', value: 'ai_speaker_sn' },
    { label: '사용여부', value: 'ai_speaker_use_yn' },
    { label: '서비스인증', value: 'ai_speaker_regist_yn' },
  ]
}

export const opSpecificUserData: TableOptions = {
  items: [
    { label: '어르신 uid', value: 'silver_uid' },
    { label: '이름', value: 'senior_name' },
    { label: '성별', value: 'senior_gender' },
    { label: '복약 유무', value: 'medi_yn' },
    { label: '위험군 대상자', value: 'senior_in_danger' },
  ]
}

export const opBloodPressure: TableOptions = {
  items: [
    { label: '소속기관', value: 'silver_center_area' },
    { label: '경로당명', value: 'silver_center_name' },
    { label: '이름', value: 'silver_name' },
    { label: '성별', value: 'silver_gender' },
    { label: '혈압 측정 일자', value: 'last_measure_date' },
    { label: '수축기', value: 'systolic' },
    { label: '이완기', value: 'diastole' },
    { label: '맥박', value: 'pulse' },
    { label: '어르신 uid', value: 'silver_uid' },
  ],
};

export const opBloodSugar: TableOptions = {
  items: [
    { label: '소속기관', value: 'silver_center_area' },
    { label: '경로당명', value: 'silver_center_name' },
    { label: '이름', value: 'silver_name' },
    { label: '성별', value: 'silver_gender' },
    { label: '혈당 측정 일자', value: 'last_measure_date' },
    { label: '식전', value: 'before_dine' },
    { label: '식후 1시간', value: 'after_1h' },
    { label: '식후 2시간', value: 'after_2h' },
    { label: '어르신 uid', value: 'silver_uid' },
  ],
};

export const opBodyFat: TableOptions = {
  items: [
    { label: '소속기관', value: 'silver_center_area' },
    { label: '경로당명', value: 'silver_center_name' },
    { label: '이름', value: 'silver_name' },
    { label: '성별', value: 'silver_gender' },
    { label: '체지방 측정 일자', value: 'last_measure_date' },
    { label: '체중', value: 'weight' },
    { label: '근육량', value: 'muscle_mass' },
    { label: '체지방량', value: 'body_fat' },
    { label: 'BMI', value: 'bmi' },
    { label: '어르신 uid', value: 'silver_uid' },
  ],
};

// silver_uid: data.silver_uid,
//             measure_date: data.last_measure_date,
//             who: data.silver_name,
//             what: '혈압측정'
export const opOverviewAiSpeakerUsage: TableOptions = { // 총 경로당 수
  items: [
    { label: '어르신 uid', value: 'silver_uid' },
    { label: '측정날짜', value: 'measure_date' },
    { label: '어르신 이름', value: 'who' },
    { label: '사용이력', value: 'what' },
  ],
};

export const hCenterManagerSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '관할', value: 'public_care_company_name' },
    { label: '이름', value: 'public_care_username' },
    { label: '관리자 ID', value: 'public_care_admin_id' },
  ],
};

export const hCenterManagerQuitSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '이름', value: 'public_care_username' },
    { label: '직군', value: 'public_care_job' },
    { label: '관리자 ID', value: 'public_care_admin_id' },
    { label: '탈퇴 사유', value: 'public_care_del_reason' },
    // { label: '탈퇴일', value: 'public_care_del_date' },
  ],
};

export const operatorSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '아이디', value: 'admin_user_id' },
    { label: '이름', value: 'admin_username' },
  ],
};

export const operatorQuitSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '운영 관리자 ID', value: 'admin_user_id' },
    { label: '탈퇴 사유', value: 'admin_del_reason' },
    { label: '탈퇴 처리자', value: 'admin_del_user' },
  ],
};

// ai_speaker_id,ai_speaker_model_name,ai_speaker_sn
export const speakerSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    // {label: '대상자ID', value: 'ai_speaker_id'},
    { label: '모델명', value: 'ai_speaker_model_name' },
    { label: '시리얼 번호', value: 'ai_speaker_sn' },
    { label: '버전', value: 'ai_speaker_fw' },
  ],
};

export const noticeSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '제목', value: 'notify_title' },
    { label: '제목 + 내용', value: 'notify_content' },
  ],
};

export const noticeCategorySearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '일반', value: '일반공지' },
    { label: '긴급', value: '긴급공지' },
  ],
};

export const faqSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '제목', value: 'faq_title' },
    { label: '제목 + 내용', value: 'faq_content' },
  ],
};

// ???
export const recipientQuestionSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '제목', value: 'silver_question_tile' },
    { label: '제목 + 내용', value: 'silver_question_contents' },
  ],
};

export const recipientQuestionSimpleSelectOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '스피커', value: '스피커' },
    { label: 'CPE', value: 'CPE' },
    { label: '기타', value: '기타' },
  ],
};

export const hcenterQuestionSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '제목', value: 'public_care_question_tile' },
    { label: '제목 + 내용', value: 'public_care_question_content' },
  ],
};

export const hcenterQuestionSimpleSelectOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '관리자WEB', value: '관리자WEB' },
    { label: 'AI스피커', value: 'AI스피커' },
  ],
};

export const emergencyCallSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '이름', value: 'silver_name' },
    { label: '성별', value: 'silver_gender' },
    { label: '소속기관', value: 'public_care_name_belong' },
    { label: '경로당명', value: 'senior_center_name' },
    { label: '발화내용', value: 'emergency_call_source_detail' },
  ],
};

// 긴급요청현황 검색 옵션
export const emergencyCallCategoryOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '발화', value: '발화' },
    { label: '화면터치', value: '화면터치' },
  ],
};

export const appSearchOptions: SelectInputOptions = {
  items: [
    { label: '전체', value: '' },
    { label: '모델명', value: 'app_model_name' },
    { label: '앱이름', value: 'app_name' },
    { label: '팩키지ID', value: 'app_package_id' },
  ],
};

export const hasUsed = [
  {
    label: '전체',
    value: 'sum',
  },
  {
    label: '사용',
    value: 'used',
  },
  {
    label: '미사용',
    value: 'notUsed',
  },
];
export const publicOptions = [
  {
    label: '공개',
    value: 'Y',
  },
  {
    label: '비공개',
    value: 'N',
  },
];
export const ynOptions = [
  {
    label: 'Y',
    value: 'Y',
  },
  {
    label: 'N',
    value: 'N',
  },
];

export const nowOrLater = [
  {
    label: '현재',
    value: '현재',
  },
  {
    label: '예약',
    value: '예약',
  },
];

export const noticeCategoryOptions = [
  { label: '일반공지', value: '일반공지' },
  { label: '긴급공지', value: '긴급공지' },
];

export const noticeToOptionsAdmin = [{ label: '전체', value: '전체' }];

export const noticeRepeatOptions = [
  { label: '매일', value: 1 },
  { label: '매주', value: 2 },
  { label: '매월', value: 3 },
];

export const noticeToOptions = [
  { label: '전체', value: '0' },
  { label: '경로당', value: '경로당' }
];

export const faqCategoryOptions = [
  { label: '관리자', value: '관리자' },
  { label: 'AI 스피커', value: 'AI 스피커' },
  { label: 'CPE', value: 'CPE' },
  { label: '인증 문의', value: '인증 문의' },
  { label: '기타', value: '기타' },
];

export const hCenterQuestionTypeOptions = [
  { label: '관리자 WEB', value: '관리자 WEB' },
  { label: 'AI 스피커', value: 'AI 스피커' },
];

export const hCenterQuestionCategoryOptions = [
  { label: 'AI 스피커', value: 'AI 스피커' },
  { label: 'CPE', value: 'CPE' },
  { label: '기타', value: '기타' },
];

export const termsChannelOptions = [
  { label: '경로당관리자', value: '경로당관리자' },
  { label: 'AI스피커', value: 'AI스피커' },
];

export const termsTypeOptions = [
  { label: '이용약관', value: '이용약관' },
  { label: '개인정보처리방침', value: '개인정보처리방침' },
];

export const termsEnableOptions = [
  { label: '미사용', value: '미사용' },
  { label: '사용', value: '사용' },
];

export const recipientStatus = ['등록', '삭제'];

export const appStatusOptions = [
  {
    label: '개발중',
    value: '개발중',
  },
  {
    label: '출시',
    value: '출시',
  },
];
