import {useEffect} from 'react'
import type {FC} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Link, List, ListItem, ListItemText,
  SvgIcon,
  Typography
} from '@material-ui/core'
import AuthBanner from '../../../components/authentication/AuthBanner'
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
} from '../../../components/authentication/login'
import LoginJWT from '../../components/auth/login/LoginJWT'
import Logo from '../../../components/Logo'
import useAuth from '../../../hooks/useAuth'
import gtm from '../../../lib/gtm'
import AIconLogo from '../../icons/AIconLogo'
import {getAppTypeByUrl, getAccountComment, MAIN_COLOR} from '../../consts'
import * as React from 'react'

const POINT_COLOR = '#545454'

export default function LoginOperator() {
  const {platform} = useAuth() as any
  const navigate = useNavigate()

  useEffect(() => {
    console.log(platform);
  }, [])

  return (
    <>
      <Helmet><title>Login</title></Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        {/* <AuthBanner /> */}
        <Container maxWidth="xs" sx={{py: 4}}>
          <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', mb: 4}}>
            {/*  <CardMedia component="img" image="/static/logo.svg" alt="Live from space album cover" */}
            {/*    sx={{height: 100, bgcolor: '#FF0000', color: '#FFFF00'}} color="#FF00FF" */}
            {/*  /> */}
            {/*  <SvgIcon target="/static/logo.svg" /> */}
            <AIconLogo sx={{width: 280, height: 40, color: MAIN_COLOR}} />
          </Box>
          <Card>
            <CardContent sx={{display: 'flex', flexDirection: 'column', p: 4}}>
              <Box sx={{alignItems: 'center', display: 'flex', justifyContent: 'space-between', mb: 3}}>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h6" display="inline">운영 관리자 로그인</Typography>
                  {/* <Typography color="textSecondary" variant="body2">{getAccountComment(getAccountTypeByUrl())}</Typography> */}
                </div>

              </Box>
              <Box sx={{flexGrow: 1, mt: 1}}>
                {platform === 'Amplify' && <LoginAmplify />}
                {platform === 'Auth0' && <LoginAuth0 />}
                {platform === 'Firebase' && <LoginFirebase />}
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider sx={{my: 3}} />
              <Box>
                <Box component="span" alignItems="center" sx={{display: 'flex', justifyContent: 'center', my: 1, mt: 2}}>
                  <Button variant="outlined" sx={{m: 1}} onClick={() => navigate('/authentication/find')} color="secondary">아이디 찾기</Button>
                  <Button variant="outlined" sx={{m: 1}} onClick={() => navigate('/authentication/find')} color="secondary">비밀번호 찾기</Button>
                </Box>
              </Box>
              <Box>
                <Typography sx={{
                  flexGrow: 1,
                  textAlign: 'center'
                }} component="h2"
                >
                  계정관리 070-1234-5678
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}
