import type {FC, ReactNode} from 'react'
import {useState} from 'react'
import {Outlet} from 'react-router-dom'
import {experimentalStyled} from '@material-ui/core/styles'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
import {AppTypeCareWell, getAppTypeByUrl, NAVBAR_Y_PX, SIDEBAR_X} from '../../consts'
import {carewellAuthApi} from '../../api/carewellAuthApi';
import DashboardNavbarCenterManager from './DashboardNavbarCenterManager'
import DashboardNavbarInstaller from './DashboardNavbarInstaller';


interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({theme}) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
)

// const themeData =     {
//     display: 'flex',
//     flex: '1 1 auto',
//     overflow: 'hidden',
//     paddingTop: `${NAVBAR_Y_PX}px`, // '64px',
//     [theme.breakpoints.up('lg')]: {
//         paddingLeft: `${SIDEBAR_X}px` // '280px'
//     },
//     // border: '2px dashed #bcbcbc'
// }

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({theme}) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: `${NAVBAR_Y_PX}px`, // '64px',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: carewellAuthApi.hasHiddenSidebar() ? '0px' : `${SIDEBAR_X}px` // '280px'
      },
      // border: '2px dashed #bcbcbc'
    }
  )
)

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
})

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
})

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false)

  // get dashboard type by port
  const isCenterManager = getAppTypeByUrl() === AppTypeCareWell.CallCenterTablet
  const isInstaller = getAppTypeByUrl() === AppTypeCareWell.InstallerMobile

  // eslint-disable-next-line no-nested-ternary
  const navbarMode = isCenterManager ? 1 : (isInstaller ? 2 : 0)

  // @ts-ignore
  return (
    <DashboardLayoutRoot>
      {/** 인스톨러 */}
      {navbarMode === 2 && <DashboardNavbarInstaller onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />}
      {/** 콜센터 */}
      {navbarMode === 1 && <DashboardNavbarCenterManager onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />}
      {/** 일반 */}
      {navbarMode === 0 && <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />}

      {(carewellAuthApi.hasHiddenSidebar() === false)
          && (
          <DashboardSidebar
            onMobileClose={(): void => setIsSidebarMobileOpen(false)}
            openMobile={isSidebarMobileOpen}
          />
          )}

      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  )
}

export default DashboardLayout
