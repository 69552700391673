import {
  Box, Button, Card, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, ToggleButton,
  ToggleButtonGroup, Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { carewellAuthApi } from '../../../api/carewellAuthApi'
import useMyApp from '../../../hooks/useMyApp'
import * as Yup from 'yup'
import { apiClient } from '../../../api/common'
import {
  AccountType, KEY_USER, SelectInputOptions
} from '../../../consts'
import sigunguJson from '../../../static/sigungu.json'
import { areaSortOptions, statesOfKorea } from '../../../typesServiceDesign'
import {
  getDateYyyyMmDd
} from '../../../utils/serverTools'


const HEIGHT = 58
const TITLE_WIDTH = 80

const initialValuesCommonTableSearch = {
  del_from_date: '',
  del_end_date: '',
  from_date: '',
  end_date: '',
  search_item: '',
  search_content: '',
  op: '',
  notify_type: '',
  area: '',
  sigungu: '',
  user_id: '',
  simpleSelect: '',
  simpleRadio: ''
}

const dateOptions = [
  {id: '1', label: '전체'},
  {id: '2', label: '일주일'},
  {id: '3', label: '한달'},
  {id: '4', label: '3개월'}
]

interface Props<Type> {
  records: Type[]
  hCenterList?: string[]
  setRecords: (items: Type[]) => void
  searchUrl: string
  doUpdateTable: () => void
  categorySearch?: SelectInputOptions
  noticeCategorySearchOptions?: SelectInputOptions
  simpleSelectTitle?: string
  simpleSelectOptions?: SelectInputOptions
  simpleSelectKey?: string
  simpleRadioTitle?: string
  simpleRadioOptions?: SelectInputOptions
  simpleRadioKey?: string
  hasRegionSearch?: boolean
  setSearchDone: (hasDoneSearch: boolean) => void
  userId?: string
  isHCenterManagerMode?: boolean
  isRecipientSearch?: boolean
  setPage?: (page: number) => void
  searchTable?: (value: any, isFirstSearch: boolean, willLookingForCenterName?: boolean) => void
  onResetForm?: () => void
  setSearchFilter?: (_filter:any) => void
}

export default function UserListSearch<Type>(props: Props<Type>) {
  const location = useLocation();
  const {hCenterList, records, setRecords, searchUrl, doUpdateTable, categorySearch, noticeCategorySearchOptions,
    simpleSelectTitle, simpleSelectOptions, simpleSelectKey,
    simpleRadioTitle, simpleRadioOptions, simpleRadioKey,
    hasRegionSearch = true, setSearchDone, userId, setSearchFilter,
    isHCenterManagerMode, isRecipientSearch, setPage, searchTable, onResetForm, ...other
  } = props
  const [alignment, setAlignment] = useState('');
  const [hideTextField, setHideTextField] = useState<boolean>(true);
  const [showHCenterListField, setShowHCenterListField] = useState<boolean>(false);
  const [showGenderSelection, setShowGenderSelection] = useState<boolean>(false);
  const [showCertSelection, setShowCertSelection] = useState<boolean>(false);
  const [showSigunguOption, setShowSigunguOption] = useState(false);
  const [showIndangerOption, setShowIndangerOption] = useState(false);
  const [showPharmacyOrNotOption, setShowPharmacyOrNotOption] = useState(false);
  const [showDelDateSelectOption, setShowDelDateSelectOption] = useState(false);
  const [sigunguTable, setSigunguTable] = useState({});
  const [currentArea, setCurrentArea] = useState('');
  const [selectedDangerProps, setSelectedDangerProps] = useState([]);
  const [currentSigungu, setCurrentSigungu] = useState([]);

  const curLoc = localStorage.getItem('currentLocation');

  const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  const isHCenter = carewellAuthApi.getAccountType() === AccountType.HCenterManager
  let type;
  if (isOperator) {
    type = ''
  } else if (isHCenter) {
    type = 'hcenter'
  }

  const formik = useFormik({
    initialValues: initialValuesCommonTableSearch,
    validationSchema: Yup.object({
      // firstName: Yup.string()
      //   .max(5, 'Must be 5 characters or less !!!')
      //   .required('Required'),
    }),
    onSubmit: async (values) => {
      console.log(values);
      const reqData = {
        ...values,
        from_date: values.from_date ? `${values.from_date} 00:00:00` : '',
        end_date: values.end_date ? `${values.end_date} 23:59:59` : '',
        area: values.area === '전국' ? '' : values.area,
        sigungu: values.sigungu === '전국' ? '' : values.sigungu,
        search_content: values.search_content ? values.search_content : '',
        search_item: values.search_item ? values.search_item : '',
        user_id: carewellAuthApi.loggedInUser.id,
        req_type: type
      }
      if (reqData.area === ''
      && reqData.sigungu === ''
      && reqData.from_date === ''
      && reqData.end_date === ''
      && reqData.search_content === ''
      && reqData.search_item === '') { setSearchFilter(null); } else {
        setSearchFilter(reqData)
      }
      // const myStorage = window.localStorage;
      // let role = '';
      // if (isHCenterManagerMode) {
      //   role = JSON.parse(myStorage.getItem(KEY_USER)).public_care_role;
      // }

      // let reqType;
      // const isHCenterManager = carewellAuthApi.getAccountType() === AccountType.HCenterManager
      // const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator

      // if (isHCenterManager) {
      //   reqType = 'hcenter';
      // } else if (isOperator) {
      //   reqType = 'operator';
      // }

      // if (values.area) {
      //   localStorage.setItem('currentLocation', values.area);
      // }

      // const reqData = {
      //   ...values,
      //   from_date: values.from_date ? `${values.from_date} 00:00:00` : '',
      //   end_date: values.end_date ? `${values.end_date} 23:59:59` : '',
      //   del_from_date: values.del_from_date ? `${values.del_from_date} 00:00:00` : '',
      //   del_end_date: values.del_end_date ? `${values.del_end_date} 23:59:59` : '',
      //   user_id: userId || '',
      //   req_type: reqType,
      //   // public_care_question_type: values.simpleSelect
      // }

      // if (simpleSelectKey) {
      //   reqData[simpleSelectKey] = values.simpleSelect
      // }

      // const pathName = window.location.pathname;

      // let res;
      // if (carewellAuthApi.getAccountType() === AccountType.CallCenterManager) { // 콜센터
      //   const res1 = await apiClient.post(searchUrl, reqData);
      //   const temp = res1.data.list;
      //   temp.sort((a, b) => Date.parse(b.emergency_call_created_date.replace(/ /g, 'T')) - Date.parse(a.emergency_call_created_date.replace(/ /g, 'T')))
      //   res = {
      //     data: {
      //       state: 'ok',
      //       list: temp,
      //     }
      //   }
      //   console.log(`[API ${searchUrl}]: `, res)
      // } else if (!pathName.includes('emergency-req')) {
      //   if (!isRecipientSearch) {
      //     console.log(`[API Req-${searchUrl}]: `, reqData)
      //     const res1 = await apiClient.post(searchUrl, reqData);
      //     console.log(`[API ${searchUrl}]: `, res1.data)
      //     res = {
      //       data: {
      //         state: 'ok',
      //         list: res1.data.list,
      //       }
      //     }
      //   } else {
      //     console.log(`[API Req-${searchUrl}]: `, reqData)
      //     // eslint-disable-next-line no-lonely-if
      //     if (Object.values({ ...values}).every(((element) => !element))) { // 전체검색
      //       // list = states.silverList
      //       delete reqData.from_date;
      //       delete reqData.end_date;
      //     }
      //     setPage(0);
      //     searchTable(reqData, true, formik.values.search_item === 'silver_center_name')
      //   }
      // } else { // emergency-req(긴급요청 현황)
      //   const res1 = await apiClient.post(searchUrl, reqData);
      //   const temp = res1.data.list;
      //   temp.sort((a, b) => Date.parse(b.emergency_call_created_date.replace(/ /g, 'T')) - Date.parse(a.emergency_call_created_date.replace(/ /g, 'T')))
      //   res = {
      //     data: {
      //       state: 'ok',
      //       list: temp,
      //     }
      //   }
      //   console.log(`[API ${searchUrl}]: `, res)
      // }
      // if (!searchUrl.includes('notify')) {
      //   // 필터링: 공지사항 검색 아닐때만
      //   if (!isRecipientSearch) {
      //     switch (carewellAuthApi.getAccountType()) {
      //       case AccountType.HCenterManager: {
      //         // 경로당관리자
      //         setRecords(res.data.list)
      //         if (pathName.includes('users/recipients')) {
      //           localStorage.setItem('silver_list', JSON.stringify(res.data.list));
      //         }
      //         break;
      //       }
      //       case AccountType.CallCenterManager: {
      //         setRecords(res.data.list);
      //         break;
      //       }
      //       case AccountType.Operator: {
      //         // 운영관리자
      //         setRecords(res.data.list)
      //         if (pathName.includes('users/recipients')) {
      //           localStorage.setItem('silver_list', JSON.stringify(res.data.list));
      //         } else if (pathName.includes('users/hcenter-managers')) {
      //           localStorage.setItem('hcenter_admin_list', JSON.stringify(res.data.list));
      //         } else if (pathName.includes('users/operators')) {
      //           localStorage.setItem('operator_list', JSON.stringify(res.data.list));
      //         }
      //         break;
      //       }
      //       default: {
      //         setRecords(res.data.list);
      //       }
      //     }
      //   }
      // } else {
      //   setRecords(res.data.list)
      // }
      // setSearchDone(true)
    },
  })

  const handleResetForm = () => {
    formik.resetForm({values: initialValuesCommonTableSearch})
    setAlignment('')
    setSearchDone(false)
    doUpdateTable()
    formik.setFieldValue('area', '')
    formik.setFieldValue('sigungu', '')
    setHideTextField(true);
    setShowGenderSelection(false);
    setShowCertSelection(false);
    setShowIndangerOption(false);
    setShowSigunguOption(false);
    setShowDelDateSelectOption(false)
    if (isRecipientSearch) {
      onResetForm();
    }
  }

  const handleDateOptionsChange = (
    event: MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    console.log(event);
    console.log('handleDateOptionsChange', newAlignment)
    if (!newAlignment) {
      return
    }
    setAlignment(newAlignment)
    // eslint-disable-next-line default-case
    switch (newAlignment) {
      case '1': { // 전체
        console.log('전체')
        // setDate(toStartDateFormatOnApi(new Date(2021, 0)), toEndDateFormatOnApi(new Date()))
        formik.setFieldValue('from_date', '')
        formik.setFieldValue('end_date', '')
        break
      }
      case '2': { // 일주일
        const today = new Date()
        const aWeekAgo = new Date(today)
        aWeekAgo.setDate(aWeekAgo.getDate() - 7)
        const fromDate = getDateYyyyMmDd(aWeekAgo)
        const endDate = getDateYyyyMmDd(today);
        console.log('aWeekAgo: ', fromDate)
        formik.setFieldValue('from_date', fromDate)
        formik.setFieldValue('end_date', endDate)
        // setDate(toStartDateFormatOnApi(aWeekAgo), toEndDateFormatOnApi(aWeekAgo))
        break
      }
      case '3': { // 한달
        const today = new Date()
        const now = getDateYyyyMmDd(today)
        const A_MONTH_AGO = new Date()
        A_MONTH_AGO.setMonth(A_MONTH_AGO.getMonth() - 1)
        const monthAgo = getDateYyyyMmDd(A_MONTH_AGO)
        formik.setFieldValue('from_date', monthAgo)
        formik.setFieldValue('end_date', now)
        console.log(`from(${monthAgo}) now(${now}) `)
        // setDate(toStartDateFormatOnApi(today), toEndDateFormatOnApi(today))
        break
      }
      case '4': { // 3개월
        const THREE_MONTHS_AGO = new Date()
        THREE_MONTHS_AGO.setMonth(THREE_MONTHS_AGO.getMonth() - 3)
        const val = getDateYyyyMmDd(THREE_MONTHS_AGO)
        const now = getDateYyyyMmDd(new Date())
        console.log(`from(${val}) now(${now}) `)
        formik.setFieldValue('from_date', val)
        formik.setFieldValue('end_date', now)
        // setDate(toStartDateFormatOnApi(THREE_MONTHS_AGO), toEndDateFormatOnApi(new Date()))
        break
      }
    }
  }

  const search = (nameKey, array) => {
    let i = 0
    for (; i < array.length && !(array[i] === nameKey); i++);
    return i < array.length;
  }

  const nameResolver = (name: string) => {
    switch (name) {
      case '서울특별시':
        return '서울';
      case '경기도':
        return '경기';
      case '인천광역시':
        return '인천';
      case '강원도':
        return '강원';
      case '충청북도':
        return '충북';
      case '충청남도':
        return '충남';
      case '전라북도':
        return '전북';
      case '전라남도':
        return '전남';
      case '경상북도':
        return '경북';
      case '경상남도':
        return '경남';
      case '제주특별자치도':
        return '제주';
      case '부산광역시':
        return '부산';
      case '대구광역시':
        return '대구';
      case '광주광역시':
        return '광주';
      case '대전광역시':
        return '대전';
      case '울산광역시':
        return '울산';
      case '세종특별자치시':
        return '세종';
      default:
        return '기타';
    }
  }

  useEffect(() => {
    if (!location.pathname.includes('/users/recipients')) {
      localStorage.removeItem('currentLocation');
    } else {
      formik.setFieldValue('area', curLoc)
    }
  }, [location.pathname, curLoc])

  useEffect(() => {
    const sigunguMap = {};
    statesOfKorea.forEach((state) => {
      sigunguMap[state] = [];
    });
    sigunguJson.forEach((sidoMap) => {
      const resolvedStateName = nameResolver(sidoMap.sido);
      if (search(resolvedStateName, statesOfKorea) && sidoMap.sigungu != null) {
        if (sigunguMap[resolvedStateName][sidoMap.sigungu] === undefined) {
          sigunguMap[resolvedStateName][sidoMap.sigungu] = [];
        }
        // sigunguMap[resolvedStateName][sidoMap.sigungu].push(sidoMap.eupmyeondong);
      }
    });
    setSigunguTable(sigunguMap);
  }, [setSigunguTable]);

  useEffect(() => {
    if (sigunguTable[currentArea]) {
      setCurrentSigungu(Object.keys(sigunguTable[currentArea]));
    }
  }, [currentArea]);

  useEffect(() => {
    if (showIndangerOption) {
      formik.setFieldValue('search_content', selectedDangerProps);
    }
  }, [selectedDangerProps, showIndangerOption])

  const handleSelectedOneRow = (
    event: ChangeEvent<HTMLInputElement>,
    prop: string
  ): void => {
    if (!selectedDangerProps.includes(prop)) {
      if (prop === '없음') {
        setSelectedDangerProps(['없음']);
      } else {
        setSelectedDangerProps((prevSelected) => [...prevSelected, prop].filter((id) => id !== '없음'))
      }
    } else {
      setSelectedDangerProps((prevSelected) => prevSelected.filter((id) => id !== prop))
    }

    console.log('handleSelectedOneRow: ', prop)
  }
  return (
    <Card {...other}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{pt: 1}}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {hasRegionSearch && (
              <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
                <Box sx={{p: 1}}><Typography width={TITLE_WIDTH} align="right">지역</Typography></Box>
                <TextField
                  size="small" select SelectProps={{native: true}} variant="outlined"
                  label="" name="area" sx={{ml: 1, width: 140}} onChange={(e) => {
                    if (e.target.value) {
                      setShowSigunguOption(true);
                    } else {
                      setShowSigunguOption(false);
                    }
                    setCurrentArea(e.target.value);
                    try {
                      formik.setFieldValue('sigungu', Object.keys(sigunguTable[e.target.value])[0]);
                    } catch (ex) {
                      formik.setFieldValue('sigungu', '');
                    }
                    formik.handleChange(e)
                  }}
                  value={formik.values.area === null ? '' : formik.values.area}
                >
                  {
                    areaSortOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>{opt.label}</option>
                    ))
                  }
                </TextField>
                {showSigunguOption && currentSigungu && (
                  <TextField
                    size="small" select SelectProps={{native: true}} variant="outlined"
                    label="" name="sigungu" sx={{ml: 1, width: 140}} onChange={formik.handleChange}
                    value={formik.values.sigungu}
                  >
                    {
                      currentSigungu.map((opt) => (
                        <option key={opt} value={opt}>{opt}</option>
                      ))
                    }
                  </TextField>
                )}
              </Box>
              )}
            </Grid>
            <Grid item>
              {hasRegionSearch && <Button variant="outlined" sx={{mt: 1, mr: 2}} onClick={handleResetForm}>초기화</Button>}
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid item>
              <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
                <Box sx={{p: 1}}><Typography width={TITLE_WIDTH} align="right">검색</Typography></Box>
                <Box sx={{display: 'flex'}}>
                  <TextField
                    size="small" select SelectProps={{native: true}} variant="outlined"
                    label="" name="search_item" sx={{ml: 1, width: 140}} onChange={(e) => {
                      console.log(e.target.value);
                      if (e.target.value === '') {
                        formik.setFieldValue('area', '')
                        formik.setFieldValue('sigungu', '')
                        setHideTextField(true);
                        formik.setFieldValue('op', '');
                      } else if (e.target.value === 'public_care_name' || e.target.value === 'silver_gender' || e.target.value === 'ai_speaker_certificate_yn' || e.target.value === 'public_care_del_date') {
                        formik.setFieldValue('area', '')
                        formik.setFieldValue('sigungu', '')
                        setHideTextField(true);
                        formik.setFieldValue('op', '');
                      } else if (e.target.value === 'silver_in_danger' || e.target.value === 'silver_in_pharmacy') {
                        formik.setFieldValue('area', '')
                        formik.setFieldValue('sigungu', '')
                        setHideTextField(true);
                        formik.setFieldValue('op', 'AND');
                      } else {
                        setHideTextField(false);
                        formik.setFieldValue('op', '');
                      }
                      setShowHCenterListField(e.target.value === 'public_care_name');
                      setShowIndangerOption(e.target.value === 'silver_in_danger' || e.target.value === 'silver_in_pharmacy');
                      setShowPharmacyOrNotOption(e.target.value === 'silver_in_pharmacy');
                      setShowGenderSelection(e.target.value === 'silver_gender');
                      setShowCertSelection(e.target.value === 'ai_speaker_certificate_yn');
                      setShowDelDateSelectOption(e.target.value === 'public_care_del_date');
                      formik.setFieldValue('search_content', '');
                      formik.handleChange(e)
                    }}
                    value={formik.values.search_item}
                  >
                    {
                      categorySearch.items.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))
                    }
                  </TextField>
                  <Box sx={{px: 1}}>
                    {
                      hideTextField
                        ? <Typography />
                        : <TextField size="small" placeholder="텍스트를 입력해 주세요."
                            name="search_content" onChange={formik.handleChange} value={formik.values.search_content}
                        />
                    }
                    {
                      showHCenterListField && (
                        <TextField
                          size="small" select SelectProps={{native: true}} variant="outlined"
                          label="" name="search_content" sx={{ml: 1, width: 140}} onChange={formik.handleChange}
                          value={formik.values.search_content}
                        >
                          {
                            hCenterList?.map((option) => (
                              <option key={option} value={option}>{option}</option>
                            ))
                          }
                        </TextField>
                      )
                    }
                    {
                      showIndangerOption && (
                        <Box>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              {(showPharmacyOrNotOption
                                ? [{label: '없음', value: '없음'}, {label: '혈압', value: '혈압'}, {label: '당뇨', value: '혈당'}, {label: '고지혈증', value: '체지방'}]
                                : [{label: '없음', value: '없음'}, {label: '혈압', value: '혈압'}, {label: '혈당', value: '혈당'}, {label: '체지방', value: '체지방'}]
                              ).map((item) => {
                                const isPropSelected = selectedDangerProps.includes(item.value)
                                return (
                                  <FormControlLabel
                                    value={item.value}
                                    control={<Checkbox color="primary" checked={isPropSelected} value={isPropSelected}
                                      onChange={(e): void => {
                                        handleSelectedOneRow(e, item.value)
                                      }}
                                    />}
                                    label={item.label}
                                    labelPlacement="start"
                                  />
                                )
                              })}
                            </FormGroup>
                          </FormControl>
                          <TextField
                            size="small" select SelectProps={{native: true}} variant="outlined"
                            sx={{ml: 1, width: 140}}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            name="op"
                            value={formik.values.op}
                            label=""
                          >
                            {
                              ['AND', 'OR'].map((option) => (
                                <option key={option} value={option}>{option}</option>
                              ))
                            }
                          </TextField>
                        </Box>
                      )
                    }
                    {
                      showGenderSelection && (
                        <RadioGroup name="search_content" sx={{flexDirection: 'row'}} onChange={formik.handleChange}
                          value={formik.values.search_content}
                        >
                          {['남', '여'].map((item) => (
                            <FormControlLabel checked={item === formik.values.search_content}
                              control={(<Radio color="primary" sx={{ml: 0}} />)} key={item}
                              value={item}
                              label={(
                                <Typography color="textPrimary" variant="body1">{item}</Typography>)}
                            />
                          ))}
                        </RadioGroup>
                      )
                    }
                    {
                      showCertSelection && (
                        <RadioGroup name="search_content" sx={{flexDirection: 'row'}} onChange={formik.handleChange}
                          value={formik.values.search_content}
                        >
                          {['인증', '미인증'].map((item) => (
                            <FormControlLabel checked={item === formik.values.search_content}
                              control={(<Radio color="primary" sx={{ml: 0}} />)} key={item}
                              value={item}
                              label={(
                                <Typography color="textPrimary" variant="body1">{item}</Typography>)}
                            />
                          ))}
                        </RadioGroup>
                      )
                    }
                    {
                      showDelDateSelectOption && (
                        <>
                          <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
                            id="del_from_date" value={formik.values.del_from_date} onChange={formik.handleChange}
                          />
                          <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
                            id="del_end_date" value={formik.values.del_end_date} onChange={formik.handleChange}
                            sx={{mx: 1}}
                          />
                        </>
                      )
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              {hasRegionSearch === false && <Button variant="outlined" sx={{mt: 1, mr: 2}} onClick={handleResetForm}>초기화</Button>}
            </Grid>
          </Grid>

          {noticeCategorySearchOptions && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
                  <Box sx={{p: 1}}><Typography width={TITLE_WIDTH} align="right">구분</Typography></Box>
                  <Box sx={{display: 'flex'}}>
                    <TextField
                      size="small" select SelectProps={{native: true}} variant="outlined"
                      label="" name="notify_type" sx={{ml: 1, width: 140}} onChange={formik.handleChange}
                      value={formik.values.notify_type}
                    >
                      {
                      noticeCategorySearchOptions.items.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))
                    }
                    </TextField>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

          {simpleSelectTitle && (
          <Grid container>
            <Grid item>
              <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
                <Box sx={{p: 1}}><Typography width={TITLE_WIDTH} align="right">{simpleSelectTitle}</Typography></Box>
                <Box sx={{display: 'flex'}}>
                  <TextField
                    size="small" select SelectProps={{native: true}} variant="outlined"
                    label="" name="simpleSelect" sx={{ml: 1, width: 140}} onChange={formik.handleChange}
                    value={formik.values.simpleSelect}
                  >
                    {
                      simpleSelectOptions.items.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))
                    }
                  </TextField>
                </Box>
              </Box>
            </Grid>
          </Grid>
          )}

          {simpleRadioTitle && (
          <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
            <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">{simpleRadioTitle}</Typography></Box>
            <RadioGroup name="simpleRadio" sx={{flexDirection: 'row'}} onChange={formik.handleChange}
              value={formik.values.simpleRadio}
            >
              {simpleRadioOptions.items.map((item) => (
                <FormControlLabel checked={item.value === formik.values.simpleRadio}
                  control={(<Radio color="primary" sx={{ml: 0}} />)} key={item.value}
                  value={item.value}
                  label={(
                    <Typography color="textPrimary" variant="body1">{item.label}</Typography>)}
                />
              ))}
            </RadioGroup>
          </Box>
          )}

          <Box sx={{display: 'flex', alignItems: 'center', bgcolor: 'background.paper', height: HEIGHT}}>
            <Box sx={{ml: 2, mr: 1}}><Typography width={TITLE_WIDTH} align="right">기간 검색</Typography></Box>

            {/* <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="from_date" value={formik.values.from_date} onChange={formik.handleChange}
            />
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="end_date" value={formik.values.end_date} onChange={formik.handleChange}
              sx={{mx: 1}}
            /> */}
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="from_date" value={formik.values.from_date} onChange={(e) => {
                if (formik.values.end_date === '' || formik.values.end_date < formik.values.from_date) {
                  formik.values.end_date = e.target.value
                }
                return formik.handleChange(e)
              }}
            />
            <TextField size="small" type="date" InputLabelProps={{shrink: true, }}
              id="end_date" value={formik.values.end_date} onChange={(e) => {
                if (formik.values.from_date === '' || formik.values.from_date > formik.values.end_date) {
                  formik.values.from_date = e.target.value
                }
                return formik.handleChange(e)
              }}
              sx={{mx: 1}}
            />
            <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleDateOptionsChange}>
              {
                dateOptions.map((item) => (
                  <ToggleButton sx={{width: 72}} value={item.id} key={item.id}>{item.label}</ToggleButton>
                ))
              }
            </ToggleButtonGroup>
          </Box>

          <Box sx={{display: 'flex', justifyContent: 'center', p: 1, m: 1, bgcolor: 'background.paper', }}>
            <Button variant="outlined" type="submit" sx={{width: 100}}>검색</Button>
            <Divider />
          </Box>
        </Box>
      </form>
    </Card>
  )
}
