// prettier-ignore
// export const HIDE_SIDEBAR = false // true

export const SIDEBAR_X: number = 200 // HIDE_SIDEBAR ? 0 : 200 // 200 // 280
export const NAVBAR_Y_PX: number = 64
export const CONTENT_LAYOUT_PY = 2
export const TABLE_SEARCH_PLACEHOLDER = '검색어를 입력해 주세요'
export const SIDEBAR_3TH_DEPTH_TEXT_ML = '8px'
export const SIDEBAR_DEPTH_GAP = 16

export const CONTENT_CONTAINER_PADDING = 1
export const SX_CONTENT_CONTAINER = {p: 1}
export const CONTENT_CONTAINER_MAX_WIDTH = 'xl' // xl | false

export const VERTICAL_TITLE_BAR = {
  borderLeft: '4px solid #1C73E8',
  pl: 0.5,
  lineHeight: '120%'
}

const BAR_COLOR = '#6b6b6b'
export const VERTICAL_TITLE_BAR_SECTION = {
  borderLeft: `4px solid ${BAR_COLOR}`,
  pl: 0.5,
  lineHeight: '120%'
}

export const VERTICAL_TITLE_BAR_SECTION_JOIN = {
  borderLeft: `4px solid ${BAR_COLOR}`,
  pl: 0.5,
  lineHeight: '120%',
  mb: 2,
}


export const RIGHT_VERTICAL_BAR_COUNT = {
  borderRight: '2px solid #808080',
  pr: 2,
  lineHeight: '140%'
}

const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
}
export const APP_THEME = THEMES.LIGHT


export const AppMsg = {
  NoResponseServer: '서버에서 응답을 받지 못했습니다.'
}

export const TEXT_WHITE = '#FFFFFF'
export const MAIN_COLOR = '#26AAD6'
export const SIDEBAR_COLOR = '#FFFFFF'
export const SIDEBAR_TEXT = 'text.secondary'
export const GRAPH_USE = '#26AAD6'
export const GRAPH_DISUSE = '#FF9B06'
export const NAVBAR_COLOR = MAIN_COLOR // '#26AAD6'
export const OVERVIEW_NUMBERS = '#414141' // 'success.dark'
export const OVERVIEW_NUMBERS_2 = MAIN_COLOR

export const KEY_USER = 'user'

export enum AppTypeCareWell {
  OpManagerWeb, // 운영관리자WEB
  HCenterManagerWeb, // 경로당관리자WEB
  InstallerMobile = 2,
  CallCenterTablet = 3,
}


export enum AppPorts {
  OpManagerWeb = 3000, // 운영관리자WEB
  HCenterManagerWeb = 3001, // 경로당관리자WEB
  InstallerMobile = 3002, // 설치관리자Mobile
  CallCenterTablet = 3003, // 콜센터관리자Tablet
}


// export enum AppPorts {
//   OpManagerWeb = 3009, // 운영관리자WEB
//   HCenterManagerWeb = 3000, // 경로당관리자WEB
//   InstallerMobile = 3006, // 설치관리자Mobile
//   CallCenterTablet = 3003 // 콜센터관리자Tablet
// }




// export enum AppPorts {
//   OpManagerWeb = 3001, // 운영관리자WEB
//   HCenterManagerWeb = 3000 // 경로당관리자WEB
// }

export const AppTypeNames = ['운영관리자WEB', '경로당관리자WEB']

const installerUrl = 'm.phc.care';
// const hcUrl = process.env.REACT_APP_PUB_ENV === 'DEV' ? 'testhc-hc.care' : 'testhc-hc.care';
// // const smsUrl = process.env.REACT_APP_PUB_ENV === 'DEV' ? 'testhc-sms.care' : 'testhc-sms.care';
// const hcUrl = process.env.REACT_APP_PUB_ENV === 'DEV' ? 'hc.care' : 'hc.care';
// const smsUrl = process.env.REACT_APP_PUB_ENV === 'DEV' ? 'sms.care' : 'sms.care';
const hcUrl = process.env.REACT_APP_PUB_ENV === 'DEV' ? 'hc-dev.care' : 'hc-dev.care';
const smsUrl = process.env.REACT_APP_PUB_ENV === 'DEV' ? 'sms-dev.care' : 'sms-dev.care';

const tonggyeUrl = 'tonggye.care';

export const getAppTypeByUrl = (): AppTypeCareWell => {
  // 설치관리자
  if (window.location.href.includes(AppPorts.InstallerMobile.toString())
      || window.location.href.includes(installerUrl)) {
    return AppTypeCareWell.InstallerMobile
  }

  // 경로당관리자
  if (window.location.href.includes(AppPorts.HCenterManagerWeb.toString())
  || window.location.href.includes(hcUrl)
  || window.location.href.includes(tonggyeUrl)) {
    return AppTypeCareWell.HCenterManagerWeb
  }

  // 콜센터관리자
  if (window.location.href.includes(AppPorts.CallCenterTablet.toString())) {
    return AppTypeCareWell.CallCenterTablet
  }

  if (window.location.href.includes(AppPorts.OpManagerWeb.toString())
  || window.location.href.includes(smsUrl)) {
    return AppTypeCareWell.OpManagerWeb
  }

  return AppTypeCareWell.OpManagerWeb
}

export enum AccountType {
  Operator, // 운영관리자
  HCenterManager, // 경로당관리자
  KHealthMember, // 건강증진개발원
  InstallManager, // 설치 관리자
  CallCenterManager,
}

export const accountTypeNames = ['슈퍼관리자', '경로당관리자', '건강증진개발원', '설치관리자', '센터관리자', '지자체관리자']
export const getAccountComment = (type:AccountType) : string => accountTypeNames[type]

// https://develop-im.tistory.com/21

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const myRegexPhone = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
// export const myRegexSpecialLetter = new RegExp('/[`~!@#$%^&*|\\\'\";:\/?]/gi')
export const myRegexPostNumber = /^[0-9]{5}$/

// id: 영문, 숫자, No 공백
// id 체크 정규식 : 숫자, 영문만 입력 가능
export const myRegexId = /^[0-9a-z]+$/

// day of birth
export const myRegexDob = /^(?:(?:19|20)\d\d)[\\/.-]?(?:0[1-9]|1[012])[\\/.-]?(?:0[1-9]|[12]\d|3[01])$/;

// export const myRegesEmail = /^[a-z0-9\.\-_]+@([a-z0-9\-]+\.)+[a-z]{2,6}$/
export const myRegexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

// 비밀번호 규칙
// 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 8자리 이상 입력
export const myRegexPassword = /(?=.*\d{1,50})(?=.*[~`!@#$%^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,50}$/
// 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 4자리 이상 입력
// msg: 비밀번호는 4자리 이상으로, 알파벳 대소문자, 숫자, 특수문자로 구성하여야 합니다.
export const myRegexPassword4 = /(?=.*\d{1,50})(?=.*[~`!@#$%^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{4,50}$/

// only 한글
export const myRegexHanguel = /^[가-힣]+$/

// 생년월일 (1950-01-01)
export const myRegexBirthday = /^\(?([0-9]{4})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/

export const CSS_CENTER = {
  justifyContent: 'center',
  display: 'flex'
}

export interface DatePair {
  sDate: Date;
  eDate: Date;
}

export interface SelectInputItem {
  label: string
  value: string
}

export interface SelectInputOptions {
  items: SelectInputItem[]
}

export interface TableItem {
  label: string
  value: string
}

export interface TableOptions {
  items: TableItem[]
}

export const NavItemPY = '5px'

export const PAGE_LIMITS = [10, 25, 50, 100]

export enum UserFormMode {
  Unknown,
  Create, // 등록
  Update, // 수정
  Read, // 상세보기
  ReadQuit, // 탈퇴회원 상세보기
  // ReadProfile,
  // EditProfile
}

export enum OverviewMode {
  Unknown,
  Recipient,
  AiSpeaker,
}

export const urlRecipientExcelFormat = 'https://seniorcallcenter.s3.ap-northeast-2.amazonaws.com/z_frontend_files/%E1%84%83%E1%85%A2%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8C%E1%85%A1_%E1%84%83%E1%85%A1%E1%84%89%E1%85%AE%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8_%E1%84%92%E1%85%A7%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx'

// export const aiSpeakerModelNames = ['IF-H1100', 'TEMP-001']

export const urlSpeakerBulkExcel = 'https://seniorcallcenter.s3.ap-northeast-2.amazonaws.com/z_frontend_files/%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%80%E1%85%A9%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8_%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8_230303.xlsx'

export const emergencyCallGlobalEnabled = true // false
export const keyEmergencyCallGlobalDisabled = 'emcDisabled'

export const shouldCheckTempPassword = 'checkTempPassword'

export const emergencyProcessUpdateStatus = '정상접수'

export const urlTokenEditProfile = 'edit-profile'

