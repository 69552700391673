import {Breadcrumbs, Grid, Link, Typography} from '@material-ui/core'
import ChevronRightIcon from '../../../icons/ChevronRight'
import {Link as RouterLink} from 'react-router-dom'


interface Props {
  title: string
  paths: string[]
  links: string[]
}

export default function PageHeader(props: Props) {
  const {title, paths, links} = props

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item>
        <Typography color="textPrimary" variant="h5">{title}</Typography>
      </Grid>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{mt: 1, mr: 1}}>
          {
            paths.map((item, index) => (
              links[index]
                ? <Link key={item} color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2">{item}</Link>
                : <Typography key={item} color="textSecondary" variant="subtitle2">{item}</Typography>
            ))
          }
        </Breadcrumbs>
      </Grid>
    </Grid>
  )
}
