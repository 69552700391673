import {useEffect, useState} from 'react'
import type {ChangeEvent, FC, MouseEvent} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import {
  Card, TableBody, TableCell, TableHead, TableRow, Typography, Box, Button, Grid, Link, Divider, Checkbox, IconButton, Pagination, CircularProgress
} from '@material-ui/core'
import Scrollbar from '../../../../components/Scrollbar'
import {CSS_CENTER, PAGE_LIMITS, VERTICAL_TITLE_BAR, RIGHT_VERTICAL_BAR_COUNT} from '../../../consts'
import MyRowsPerPage from '../../MyRowsPerPage'
import {HCenterManager, Recipient} from '../../../types/user'
import {applyStringSortG, handleClickSort} from '../../../utils/table'
import {MyTableA, getSortIcon, RecordDetailsIcon2, ROW_DETAILS_COLOR, SX_TABLE_HEADER_A} from '../../../commonStyles'
import HCenterManagerDeleteModal from './HCenterManagerDeleteModal'
import SimpleYesNoModal from '../../widgets/modals/SimpleYesNoModal'
import {toast} from 'react-hot-toast'
import XlsxControl from '../../widgets/XlsxControl'
import {apiClient} from '../../../api/common'
import {carewellAuthApi} from '../../../api/carewellAuthApi'
import {deleteHCenterManagerApiUrl, deleteHCenterManagerReasonOptions} from '../../../typesServiceDesign';
import XlsxControlV2 from '../../widgets/XlsxControlV2'
import { QUIT_TYPE } from 'src/__CareWell__/commons/commons'

const applyPagination = (
  products: HCenterManager[],
  page: number,
  limit: number
): HCenterManager[] => products
  .slice(page * limit, page * limit + limit)

const HEIGHT = 72
const TITLE_WIDTH = 80

interface Props {
  records: HCenterManager[]
  sheetHeader?: string[]
  sheetKeyNames?: string[]
  sortColumns?: string[]
  doUpdateTable: () => void
  searchDone: boolean
  page: number,
  limit: number,
  total: number,
  quitList: any[],
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  getData?: (idx:number, config:any) => any
  loading: boolean
}

export default function HCenterManagerListTable(props: Props) {
  const {records, sheetHeader, sheetKeyNames, sortColumns, doUpdateTable, searchDone, page, limit, setPage, setLimit, total, quitList, getData, loading, ...other} = props
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [actionRowId, setActionRowId] = useState('') // 삭제, 비번초기화 등을 위한 row id

  const [sheetRows, setSheetRows] = useState([])

  const [sortStats, setSortStats] = useState(Array(sortColumns.length).fill(0))


  const [popupInitPw, setPopupInitPw] = useState(false)
  const [popupDeleteUser, setPopupDeleteUser] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectRows, setSelectRows] = useState<any[]>([])

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    // console.log('handlePageChange newPage: ', newPage - 1)
    setPage(newPage - 1)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10))
  }

  const onRowsPerPageChange = (limitNumber: number): void => {
    setLimit(limitNumber)
  }

  const handleExportRowsToExcel = () => {
    console.log('handleExportRowsToExcel: ', selectedRows)
  }

  const handleCellClick = (item: HCenterManager) => {
    // console.log('handleCell Click - ')
    navigate(`/dashboard/users/hcenter-managers/${item.public_care_admin_id}/details-quit`)
  }

  const handleDelete = (item: HCenterManager) => {
    //
    // console.log('handleDelete id: ', item)
    setPopupDeleteUser(true)
    setActionRowId(item.public_care_admin_id)
  }

  const handleDeleteRow = () => {
    // console.log('handleDeleteRow id: ', actionRowId)
  }

  const handleInitPassword = (item: HCenterManager) => {
    // console.log('handleInitPassword id: ', actionRowId)
    setPopupInitPw(true)
    setActionRowId(item.public_care_admin_id)
  }

  const requestInitPassword = async () => {
    try {
      const url = '/factory_reset_public_care_admin_pwd'
      const res = await apiClient.post(url, {public_care_admin_id: actionRowId})
      // console.log(`[API ${url}]: `, res.data)

      if (res.data.state === 'ok') toast.success('비밀번호가 초기화되었습니다.')
      else toast.error('비밀번호 초기화에 실패하였습니다.')
    } catch (err) {
      console.error(err)
    }

    setPopupInitPw(false)
  }

  const onFinishDeleteUser = (success:boolean) => {
    if (success) {
      doUpdateTable()
    }
  }

  const getChecked = (item) => {
    const list = selectRows.filter((v) => v.public_care_admin_id === item.public_care_admin_id)
    return list.length > 0;
  }

  const addRows = (item) => {
    const list = [...selectRows, item]
    // console.log(list)
    setSelectRows(list)
    setSheetRows(list)
  }

  const removeRows = (item) => {
    const list = selectRows.filter((v) => v.public_care_admin_id !== item.public_care_admin_id)
    // console.log(list)
    setSelectRows(list)
    setSheetRows(list)
  }

  const handleSelectedOneRow = (
    event: ChangeEvent<HTMLInputElement>,
    item: any
  ): void => {
    if (event.target.checked) {
      addRows(item)
    } else {
      removeRows(item)
    }
  }

  useEffect(() => {
    handleClickSort(6, sortStats, setSortStats)
  }, [])

  const enableBulkActions = selectedRows.length > 0
  const selectedSomeRows = selectedRows.length > 0
    && selectedRows.length < records.length
  const selectedAllRows = selectedRows.length === records.length

  return (
    <Card {...other}>
      <Box>
        <Scrollbar>
          <Box sx={{minWidth: 900}}>
            <Grid container justifyContent="space-between">
              <Grid item sx={{ml: 2, mb: 0}}>
                <Box sx={{display: 'flex', alignItems: 'center', p: 0, m: 0, bgcolor: 'background.paper', height: HEIGHT}}>
                  <Box sx={{p: 1, ml: 2}}>
                    <Typography width={TITLE_WIDTH} align="right" sx={{...RIGHT_VERTICAL_BAR_COUNT}}>{`전체: ${total}`}</Typography>
                  </Box>
                  <Box sx={{p: 1}}>
                    <MyRowsPerPage rowsPerPage={limit} setRowsPerPage={setLimit} rowsPerPageOptions={PAGE_LIMITS} onRowsPerPageChange={onRowsPerPageChange} />
                  </Box>
                  <Box sx={{p: 1}} />
                </Box>
              </Grid>
              <Grid item sx={{mr: 2, pt: 2}}>
                {sheetHeader && <XlsxControlV2
                  rows={sheetRows} LoadData={getData} pageTotal={Math.ceil(total / limit)} setIsLoading={setIsLoading} modalType={QUIT_TYPE.CENTER}
                  filename="탈퇴한 관리자 현황"
                />}
                {' '}
              </Grid>
            </Grid>
            <MyTableA size="small">
              <TableHead>
                <TableRow sx={SX_TABLE_HEADER_A}>
                  <TableCell padding="checkbox">
                    {/* <Checkbox checked={selectedAllRows} indeterminate={selectedSomeRows} onChange={handleSelectAllProducts} /> */}
                  </TableCell>
                  <TableCell align="inherit">
                    지역
                    {/* <IconButton size="small" onClick={() => handleClickSort(1, sortStats, setSortStats)}>{getSortIcon(sortStats[0])}</IconButton> */}
                  </TableCell>
                  <TableCell>
                    경로당 관리자 ID
                    {/* <IconButton size="small" onClick={() => handleClickSort(4, sortStats, setSortStats)}>{getSortIcon(sortStats[3])}</IconButton> */}
                  </TableCell>
                  <TableCell>
                    이름
                    {/* <IconButton size="small" onClick={() => handleClickSort(4, sortStats, setSortStats)}>{getSortIcon(sortStats[3])}</IconButton> */}
                  </TableCell>
                  <TableCell>
                    관리
                    {/* <IconButton size="small" onClick={() => handleClickSort(2, sortStats, setSortStats)}>{getSortIcon(sortStats[1])}</IconButton> */}
                  </TableCell>
                  <TableCell>
                    탈퇴 사유
                    {/* <IconButton size="small" onClick={() => handleClickSort(5, sortStats, setSortStats)}>{getSortIcon(sortStats[4])}</IconButton> */}
                  </TableCell>
                  <TableCell>
                    탈퇴일
                    {/* <IconButton size="small" onClick={() => handleClickSort(6, sortStats, setSortStats)}>{getSortIcon(sortStats[5])}</IconButton> */}
                  </TableCell>
                  <TableCell>
                    탈퇴 처리자
                    {/* <IconButton size="small" onClick={() => handleClickSort(7, sortStats, setSortStats)}>{getSortIcon(sortStats[6])}</IconButton> */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  quitList && quitList.map((item) => {
                    const isProductSelected = getChecked(item)
                    return (
                      <TableRow hover key={item.public_care_admin_id} selected={isProductSelected}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isProductSelected}
                            onChange={(e): void => handleSelectedOneRow(e, item)}
                          />
                        </TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.public_care_area}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.public_care_admin_id}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.public_care_username}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.public_care_name}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}><Typography fontSize={12}>{item.public_care_del_reason}</Typography></TableCell>
                        <TableCell onClick={() => handleCellClick(item)}><Typography fontSize={14}>{item.public_care_del_date}</Typography></TableCell>
                        <TableCell onClick={() => handleCellClick(item)}><Typography fontSize={14}>{item.public_care_del_user}</Typography></TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </MyTableA>
            <Divider />

            <Box display="flex" justifyContent="center" sx={{my: 3}}>
              {
                loading || quitList == null ? <Box display="flex" justifyContent="center" sx={{my: 3}}><CircularProgress /></Box>
                  : quitList && quitList.length === 0 && <Box display="flex" justifyContent="center" sx={{my: 3}}><Typography>{searchDone ? '조회 내역이 없습니다.' : '등록된 내역이 없습니다.'}</Typography></Box>
              }
              {/* {quitList && quitList.length === 0 && (searchDone ? <Typography>조회 내역이 없습니다.</Typography> : <Typography>등록된 내역이 없습니다</Typography>)} */}
            </Box>

            <Box sx={{...CSS_CENTER, my: 2}}>
              <Pagination
                showFirstButton showLastButton siblingCount={5}
                count={parseInt(String((total + (limit - 1)) / limit), 10)}
                page={page + 1}
                onChange={handlePageChange}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Box>
      <SimpleYesNoModal open={popupInitPw} setOpen={setPopupInitPw} message="비밀번호를 초기화 하시겠습니까?" onOk={requestInitPassword} />
      <HCenterManagerDeleteModal open={popupDeleteUser} setOpen={setPopupDeleteUser}
        reasonOptions={deleteHCenterManagerReasonOptions} apiUrl={deleteHCenterManagerApiUrl}
        rowId={actionRowId}
        userId={carewellAuthApi.loggedInUser.admin_user_id} onFinish={onFinishDeleteUser}
      />
    </Card>
  )
}
