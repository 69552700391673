import { ChangeEvent, MouseEvent, useState } from 'react'

import {
  Box,
  Button,
  Card, Checkbox, CircularProgress, Divider,
  Grid,
  IconButton,
  Pagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Scrollbar from '../../../../components/Scrollbar'
import { carewellAuthApi } from '../../../api/carewellAuthApi'
import { getSortIcon, MyTableA, SX_TABLE_HEADER_A } from '../../../commonStyles'
import { AccountType, CSS_CENTER, PAGE_LIMITS, RIGHT_VERTICAL_BAR_COUNT } from '../../../consts'
import { AiSpeaker } from '../../../types/speaker'
import { applyPagination, applyStringSortG, handleClickSort } from '../../../utils/table'
import MyRowsPerPage from '../../MyRowsPerPage'
import SimpleYesModal from '../../widgets/modals/SimpleYesModal'
import SpeakerAssignModal from '../../widgets/modals/SpeakerAssignModal'
import SpeakerStatusEditModal from '../../widgets/modals/SpeakerStatusEditModal'
import SpeakerList from 'src/__CareWell__/pages/speakers/SpeakerList'
import { getDateToString } from 'src/__CareWell__/commons/commons'
import useScrollReset from 'src/hooks/useScrollReset'


const baseTableUrl = '/dashboard/speakers'


const HEIGHT = 72
const TITLE_WIDTH = 100

interface Props {
  sheetHeader?: string[]
  sheetKeyNames?: string[]
  sheetFilename?: string
  sortColumns?: string[]
  doUpdateTable: () => void
  loadingDone: boolean
  setPage?: (p:number) => void
  setLimit?: (p:number) => void
  page?:number
  limit?:number
  speakerList?: any[]
  fullCnt?:number
}

// 스피커 현황
export default function SpeakerOverviewTableV2(props: Props) {
  const {page, setPage, limit, setLimit, fullCnt, speakerList, sortColumns, sheetFilename, doUpdateTable, loadingDone, ...other} = props
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [currentItem, setCurrentItem] = useState<AiSpeaker>()
  const [actionRowId, setActionRowId] = useState('') // 삭제, 비번초기화 등을 위한 row id

  const [sheetRows, setSheetRows] = useState([])

  const [sortStats, setSortStats] = useState(sortColumns ? Array(sortColumns.length).fill(0) : [])


  const [popupSpeakerStatusEdit, setPopupSpeakerStatusEdit] = useState(false)
  const [popupSpeakerAssignModal, setPopupSpeakerAssignModal] = useState(false)

  const [popupSpeakerNotValid, setPopupSpeakerNotValid] = useState(false);

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage - 1)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10))
  }

  const onRowsPerPageChange = (limitNumber: number): void => {
    setLimit(limitNumber)
  }

  const handleExportRowsToExcel = () => {
    console.log('handleExportRowsToExcel: ', selectedRows)
  }
  const handleCellClick = (item: AiSpeaker) => {
    if (item.center_id) {
      navigate(`/dashboard/speakers/${item.ai_speaker_sn}/details?id=${item.center_id}`)
    } else {
      navigate(`/dashboard/speakers/${item.ai_speaker_sn}/details?id=${item.center_id}`)
      // assign된 대상자가 없을때만
      setPopupSpeakerNotValid(true);
      // setCurrentItem(item);
      // setPopupSpeakerAssignModal(true)
    }
  }

  const isDev = process.env.NODE_ENV === 'development';

  const convertSelectedRowsForSheet = (finalRows0: AiSpeaker[], rows0: string[]) => {
    console.log('selected Count: ', rows0.length)
    const finalSheetRows = []
    finalRows0.forEach((row) => {
      if (rows0.includes(row.ai_speaker_sn)) {
        const sheetRow = []
        finalSheetRows.push(sheetRow)
      }
    })
    setSheetRows(finalSheetRows)
  }

  // const handleSelectAllProducts = (event: ChangeEvent<HTMLInputElement>): void => {
  //   const newRows = event.target.checked ? records.map((val) => val.ai_speaker_sn) : []
  //   setSelectedRows(newRows)
  //   convertSelectedRowsForSheet(speakerList, newRows)
  // }

  // const handleSelectedOneRow = (
  //   rowId: string
  // ): void => {
  //   if (!selectedRows.includes(rowId)) {
  //     setSelectedRows((prevSelected) => [...prevSelected, rowId])
  //     setCurrentItem(records.filter((record: AiSpeaker) => record.ai_speaker_sn === rowId)[0]);
  //     convertSelectedRowsForSheet(speakerList, [...selectedRows, rowId])
  //   } else {
  //     setSelectedRows((prevSelected) => prevSelected.filter((id) => id !== rowId))
  //     setCurrentItem(null);
  //     convertSelectedRowsForSheet(speakerList, selectedRows.filter((id) => id !== rowId))
  //   }

  //   console.log('handleSelectedOneRow: ', rowId)
  // }

  // const selectedSomeRows = selectedRows.length > 0
  //   && selectedRows.length < records.length
  // const selectedAllRows = selectedRows.length === records.length

  // const isOperator = carewellAuthApi.getAccountType() === AccountType.Operator
  return (
    <Card {...other}>
      <Box>
        <Scrollbar id="scroll_table">
          <Box sx={{minWidth: 900}}>
            <Grid container justifyContent="space-between">
              <Grid item sx={{ml: 2, mb: 0}}>
                <Box sx={{display: 'flex', alignItems: 'center', p: 0, m: 0, bgcolor: 'background.paper', height: HEIGHT}}>
                  <Box sx={{p: 1, ml: 2}}>
                    <Typography width={TITLE_WIDTH} align="right" sx={{...RIGHT_VERTICAL_BAR_COUNT}}>{`전체: ${fullCnt}`}</Typography>
                  </Box>
                  <Box sx={{p: 1}}>
                    <MyRowsPerPage rowsPerPage={limit} setRowsPerPage={setLimit} rowsPerPageOptions={PAGE_LIMITS} onRowsPerPageChange={onRowsPerPageChange} />
                  </Box>
                  <Box sx={{p: 1}} />
                </Box>
              </Grid>
              {/* <Grid item sx={{mr: 2, pt: 2}}>
                {(isOperator || isDev) && <Button variant="outlined" onClick={() => setPopupSpeakerStatusEdit(true)}>기기 상태변경</Button> }
              </Grid> */}
              {/* <Grid item sx={{mr: 2, pt: 2}}> */}
              {/** 엑셀 다운로드 */}
              {/* {sheetHeader && (<XlsxControl rows={sheetRows} filename={sheetFilename} />) } */}
              {/* </Grid> */}
            </Grid>
            <MyTableA size="small">
              <TableHead>
                <TableRow sx={SX_TABLE_HEADER_A}>
                  <TableCell align="inherit">No</TableCell>
                  {/* <TableCell padding="checkbox">
                    <Checkbox checked={selectedAllRows} indeterminate={selectedSomeRows} onChange={handleSelectAllProducts} />
                  </TableCell> */}
                  <TableCell>스피커 아이디</TableCell>
                  <TableCell>시리얼 번호</TableCell>
                  <TableCell>설치 경로당명</TableCell>
                  {/* <TableCell>
                    기기상태
                    <IconButton size="small" onClick={() => handleClickSort(1, sortStats, setSortStats)}>{getSortIcon(sortStats[0])}</IconButton>
                  </TableCell> */}
                  <TableCell>
                    최근 연결 시간
                    {/* <IconButton size="small" onClick={() => handleClickSort(2, sortStats, setSortStats)}>{getSortIcon(sortStats[1])}</IconButton> */}
                  </TableCell>
                  <TableCell>
                    서비스인증
                    {/* <IconButton size="small" onClick={() => handleClickSort(3, sortStats, setSortStats)}>{getSortIcon(sortStats[2])}</IconButton> */}
                  </TableCell>
                  <TableCell>등록일</TableCell>
                  <TableCell>버전</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  speakerList && speakerList.map((item, index) => {
                    const isProductSelected = selectedRows.includes(item.ai_speaker_sn)

                    return (
                      <TableRow hover key={item.ai_speaker_sn} selected={isProductSelected}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                        <TableCell onClick={() => handleCellClick(item)}>{(limit * page) + index + 1}</TableCell>
                        {/* <TableCell align="center" padding="checkbox">
                          <Checkbox color="primary" checked={isProductSelected} value={isProductSelected}
                            onChange={(e): void => handleSelectedOneRow(item.ai_speaker_sn)}
                          />
                        </TableCell> */}
                        <TableCell onClick={() => handleCellClick(item)}>{item.center_id}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_sn}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.center_name}</TableCell>
                        {/* <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_created_date}</TableCell> */}
                        {/* <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_status}</TableCell> */}
                        <TableCell onClick={() => handleCellClick(item)}>{getDateToString(item.ai_speaker_check_alive)}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_regist_yn}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{getDateToString(item.ai_speaker_regist_date)}</TableCell>
                        <TableCell onClick={() => handleCellClick(item)}>{item.ai_speaker_fw}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </MyTableA>
            <Divider />
            {
              loadingDone || speakerList == null ? <Box display="flex" justifyContent="center" sx={{my: 3}}><CircularProgress /></Box>
                : speakerList && speakerList.length === 0 && <Box display="flex" justifyContent="center" sx={{my: 3}}><Typography>조회 내역이 없습니다.</Typography></Box>
            }
            {/* <Box display="flex" justifyContent="center" sx={{my: 3}}>
              {speakerList && speakerList.length === 0 && (
                loadingDone
                  ? <Typography>조회 내역이 없습니다.</Typography>
                  : <Typography />
              )}
            </Box> */}

            {/* <Box display="flex" justifyContent="center" sx={{my: 3}}>
              {!loadingDone && <CircularProgress />}
            </Box> */}

            <Box sx={{...CSS_CENTER, my: 2}}>
              <Pagination
                showFirstButton showLastButton siblingCount={5}
                count={Math.ceil(fullCnt / limit)}
                page={page + 1}
                onChange={handlePageChange}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Box>
      {/* <SimpleYesNoModal open={popupInitPw} setOpen={setPopupInitPw} message="비밀번호를 초기화 하시겠습니까?" onOk={requestInitPassword} /> */}
      {/* <HCenterManagerDeleteModal open={popupDeleteUser} setOpen={setPopupDeleteUser} rowId={actionRowId} */}
      {/*  userId={carewellAuthApi.loggedInUser.admin_user_id} onFinish={onFinishDeleteUser} */}
      {/* /> */}
      {/* <SpeakerStatusEditModal isOverviewMode initialValue={currentItem} open={currentItem && popupSpeakerStatusEdit} setOpen={setPopupSpeakerStatusEdit} />
      <SpeakerAssignModal triggerTableUpdate={doUpdateTable} initialValue={currentItem} open={popupSpeakerAssignModal} setOpen={setPopupSpeakerAssignModal} />
      <SimpleYesModal open={popupSpeakerNotValid} setOpen={setPopupSpeakerNotValid} message="스피커 정보가 유효하지 않거나 미할당된 스피커입니다." onOk={() => {
        setPopupSpeakerNotValid(false);
      }}
      /> */}
      {/* <SpeakerStatusEditModal open={popupSpeakerStatusEdit2} setOpen={setPopupSpeakerStatusEdit2} /> */}
    </Card>
  )
}
