import {Box, Grid, Typography, Card, CardHeader, CardContent, Stack, Paper, Divider} from '@material-ui/core'
import {experimentalStyled, useTheme} from '@material-ui/core/styles'
import {SpeakerStockData} from '../../../types/speaker'

interface SpeakStatusUnit {
  title: string
  elementValue: number
}

const NumberUnit: (props: SpeakStatusUnit) => JSX.Element = (props: SpeakStatusUnit) => {
  const { title, elementValue } = props
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <Typography sx={{display: 'block'}}>1111</Typography>
      <Typography>2222</Typography>
      <Box sx={{ color: 'primary.main', fontSize: 22 }}>
        $280,000 — $310,000
      </Box>

      {/* <Typography color="textSecondary" variant="overline">{title}</Typography> */}
      {/* <Typography color="textPrimary" variant="h5"> */}
      {/*  {elementValue} */}
      {/* </Typography> */}
      {/* <Typography */}
      {/*  color="textSecondary" */}
      {/*  variant="caption" */}
      {/* > */}
      {/*  vs. */}
      {/*  111개 */}
      {/*  &nbsp; */}
      {/*  last year */}
      {/* </Typography> */}
    </Box>
  )
}

const Item = experimentalStyled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const StackSpacing = 1

interface Props {
  stock: SpeakerStockData
  fullData: boolean
}

export default function SpeakerOverviewNumbers(props: Props) {
  const {stock, fullData} = props
  const isFull = fullData
  const verticalLineStyle = {
    alignItems: 'center',
    borderRight: (theme) => (
      {
        md: `1px solid ${theme.palette.divider}`
      }
    ),
    borderBottom: (theme) => (
      {
        xs: `1px solid ${theme.palette.divider}`,
        md: 'none'
      }
    ),
    // display: 'flex',
    // justifyContent: 'space-between',
    p: 1
  }

  return (
    <Card>
      {/* {isFull && (
      <Grid container sx={{
        pt: 1,
        pb: 1,
      }}
      >
        <Stack spacing={StackSpacing} sx={{
          width: 'calc(14.3% - 1.1px)',
        }}
        >
          <Item><Typography variant="h6">전체</Typography></Item>
          <Item>{stock.full_cnt}</Item>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={StackSpacing} sx={{
          width: 'calc(14.3% - 1.1px)',
        }}
        >
          <Item><Typography variant="h6">입고</Typography></Item>
          <Item>{stock.stock_cnt}</Item>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={StackSpacing} sx={{
          width: 'calc(14.3% - 1.1px)',
        }}
        >
          <Item><Typography variant="h6">출고</Typography></Item>
          <Item>{stock.sail_cnt}</Item>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={StackSpacing} sx={{
          width: 'calc(14.3% - 1.1px)',
        }}
        >
          <Item><Typography variant="h6">AS</Typography></Item>
          <Item>{stock.as_cnt}</Item>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={StackSpacing} sx={{
          width: 'calc(14.3% - 1.1px)',
        }}
        >
          <Item><Typography variant="h6">중고</Typography></Item>
          <Item>{stock.used_cnt}</Item>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={StackSpacing} sx={{
          width: 'calc(14.3% - 1.1px)',
        }}
        >
          <Item><Typography variant="h6">폐기</Typography></Item>
          <Item>{stock.release_cnt}</Item>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={StackSpacing} sx={{
          width: 'calc(14.3% - 1.1px)',
        }}
        >
          <Item><Typography variant="h6">기타</Typography></Item>
          <Item>{stock.etc_cnt}</Item>
        </Stack>
      </Grid>
      )} */}

      { (!isFull) && (
      <Grid container>
        <Grid item xs={4} sx={verticalLineStyle}>
          <Stack spacing={StackSpacing}>
            <Item><Typography variant="h6">전체</Typography></Item>
            <Item>{stock.full_cnt}</Item>
          </Stack>
        </Grid>
        <Grid item xs={4} sx={verticalLineStyle}>
          <Stack spacing={StackSpacing}>
            <Item><Typography variant="h6">사용</Typography></Item>
            <Item>{stock.use_cnt}</Item>
          </Stack>
        </Grid>
        <Grid item xs={4} sx={verticalLineStyle}>
          <Stack spacing={StackSpacing}>
            <Item><Typography variant="h6">미사용</Typography></Item>
            <Item>{stock.nouse_cnt}</Item>
          </Stack>
        </Grid>
      </Grid>
      )}
    </Card>
  )
}

