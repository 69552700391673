import { User } from '../../types/user';
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from '../../utils/jwt';
import {
  AccountType,
  accountTypeNames,
  AppTypeCareWell,
  AppTypeNames,
  getAppTypeByUrl,
  KEY_USER,
  shouldCheckTempPassword
} from '../consts';
import { deleteCookie, getCookie, setCookie } from '../utils/cookieTools';
import {
  apiClient,
  HCenterManagerInfo
} from './common';
// const users = [
//   {
//     id: '5e86809283e28b96d2d38537',
//     avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
//     email: 'test001',
//     name: 'Jane Rotanson',
//     password: 'info1!',
//     plan: 'Premium'
//   }
// ]

// prettier-ignore
class CarewellAuthApi {
  ///
  loggedInUser: any

  constructor() {
    const myStorage = window.localStorage
    const userJson = myStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      // console.log('Response from server: ', userJson)
      // console.log('>> logged in user: ', this.loggedInUser)
      // console.log(this.loggedInUser);
    } else {
      this.loggedInUser = null
    }
    // console.log('>>>> ', myStorage.getItem(`${KEY_USER}`))
  }

  getAdminName(): any {
    const userJson = window.localStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      if (this.loggedInUser.accountType === AccountType.HCenterManager) return (this.loggedInUser as HCenterManagerInfo).public_care_username
      return (this.loggedInUser as any).admin_username;
    }
    return null;
  }

  getAdminId(): any {
    const userJson = window.localStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      if (this.loggedInUser.accountType === AccountType.HCenterManager) return (this.loggedInUser as HCenterManagerInfo).public_care_admin_id
      return (this.loggedInUser as any).admin_user_id;
    }
    return null;
  }

  getAccountInfo(): any {
    const userJson = window.localStorage.getItem(`${KEY_USER}`)
    if (userJson) {
      this.loggedInUser = JSON.parse(userJson)
      if (this.loggedInUser.accountType === AccountType.HCenterManager) return this.loggedInUser as HCenterManagerInfo
      return this.loggedInUser as any;
    }
    return null;
  }

  getAccountType(): AccountType {
    if (this.loggedInUser) {
      return this.loggedInUser.accountType
    }
    return AccountType.Operator
  }

  getAccountTypeName(): string {
    return accountTypeNames[this.loggedInUser.accountType]
  }

  hasHiddenSidebar(): boolean {
    return this.loggedInUser.accountType === AccountType.CallCenterManager || this.loggedInUser.accountType === AccountType.InstallManager
  }

  async login({email, password}): Promise<string> {
    // console.log('> login .. ')
    // console.log('loc: ', window.location.href)

    let appType = AppTypeCareWell.OpManagerWeb
    let url = '/admin_logindo'
    let getUrl = '/get_admin_info'
    let queryIdName = 'admin_user_id'
    let nameTableName = 'admin_username'
    // 경로당 관리자 WEB
    if (getAppTypeByUrl() === AppTypeCareWell.HCenterManagerWeb) {
      appType = AppTypeCareWell.HCenterManagerWeb
      url = '/public_care_admin_logindo'
      queryIdName = 'public_care_admin_id'
      nameTableName = 'public_care_username'
      getUrl = '/get_public_care_admin_info'
    }

    // console.log('app type: ', AppTypeNames[appType])
    // console.log(`> LOGIN: ${url}, id=${email}, password=${password}`)

    const reqData = {
      [queryIdName]: email,
      password,
    };
    console.log(reqData)
    const preRes = await apiClient.post(url, reqData)

    const refToken = (await apiClient.post('/create_token')).data.result

    // console.log(refToken)

    setCookie('access_token', preRes.data.access_token, 1)
    setCookie('refresh_token', refToken, 1)

    const config = {
      headers: {
        'x-access-token': getCookie('access_token'),
        'x-refresh-token': getCookie('refresh_token'),
      }
    }

    const res = await apiClient.post(getUrl, reqData, config)
    // console.log(`[API ${getUrl}]: `, res.data)
    // console.log(res);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {status, state, error, admin_username} = res.data
    // console.log(res)
    const isSuccess = status === 'ok' || state === 'ok'
    if (isSuccess) {
      // console.log(res);
      const tempLoggedInUser = {
        id: email,
        avatar: null,
        email,
        name: '',
        plan: ''
      }
      let accountType0 = AccountType.Operator
      if (res.data.admin_role) {
        // TODO: fix later
        if (res.data.admin_role === accountTypeNames[3]) accountType0 = AccountType.InstallManager
        else if (res.data.admin_role === accountTypeNames[4]) accountType0 = AccountType.CallCenterManager
      } else if (res.data.public_care_admin_id) {
        accountType0 = AccountType.HCenterManager
        // if (res.data.public_care_job === accountTypeNames[1]) accountType0 = AccountType.HCenterManager
        if (res.data.public_care_job === accountTypeNames[2]) accountType0 = AccountType.KHealthMember
      }

      // console.log('logged in 계정타입: ', accountTypeNames[accountType0])

      const loggedInUser = {
        ...res.data,
        ...tempLoggedInUser,
        accountType: accountType0,
        name: res.data[nameTableName],
        admin_uid: res.data.admin_uid
      }

      // console.log('loggedInUser: ', loggedInUser)
      this.loggedInUser = loggedInUser
      const myStorage = window.localStorage
      myStorage.setItem(KEY_USER, JSON.stringify(this.loggedInUser))
      // console.log('loggedInUser has saved.', this.loggedInUser)
      if (AccountType.HCenterManager === accountType0 && res.data.public_care_temp_password_yn === 'true') {
        localStorage.setItem(shouldCheckTempPassword, 'true')
      }
    }

    return new Promise((resolve, reject) => {
      try {
        // Find the user
        // const user = users.find((_user) => _user.email === email);


        if (isSuccess === false) {
          const isStop = status === 'stop' || state === 'stop'

          if (isStop) {
            reject(new Error('비밀번호를 5회 이상 틀렸습니다.'))
            return
          }
          console.log(res.data)
          if (res.data.count == null) {
            reject(new Error('등록되지 않은 사용자입니다.'))
            return
          }

          reject(new Error(`비밀번호가 틀렸습니다.(${res.data.count}/5)`))
          return
        }

        // Create the access token
        const accessToken = sign(
          {userId: email},
          JWT_SECRET,
          {expiresIn: JWT_EXPIRES_IN}
        )

        resolve(accessToken)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  me(accessToken): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const {userId} = decode(accessToken) as any

        // Find the user
        // const user = users.find((_user) => _user.id === userId);

        if (!this.loggedInUser) {
          reject(new Error('Invalid authorization token'))
          return
        }

        resolve(this.loggedInUser)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  async logout() {
    let url = '/admin_logout'
    if (getAppTypeByUrl() === AppTypeCareWell.HCenterManagerWeb) {
      url = '/public_care_logout'
    }
    const res = await apiClient.post(url, {
      id: this.getAdminId(),
    })
    if (res.data.state === 'ok') {
      this.loggedInUser = null
      deleteCookie('access_token')
      deleteCookie('refresh_token')
      localStorage.clear()
    }
  }
}

export const carewellAuthApi = new CarewellAuthApi();
