import { Button, Link } from '@material-ui/core'
import DvrIcon from '@material-ui/icons/Dvr'
import RateReviewIcon from '@material-ui/icons/RateReview'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { AccountType } from '../../consts'
import useAuth from '../../../hooks/useAuth'
import { carewellAuthApi } from '../../api/carewellAuthApi'

export const ButtonChatbot = () => (
  <Button
    sx={{
      color: 'primary.contrastText',
      // backgroundColor: 'white'
    }}
    size="small"
    startIcon={<RateReviewIcon />}
  >
    <Link color="#FFFFFF" component={RouterLink} to="/dashboard" variant="subtitle2">챗봇 관리</Link>
  </Button>
)

export const ButtonVitalReaction = () => (
  <Button
    sx={{
      color: 'primary.contrastText',
      // backgroundColor: 'white'
    }}
    size="small"
    startIcon={<DvrIcon />}
  >
    <Link color="#FFFFFF" component={RouterLink} to="/dashboard/vital-reaction" variant="subtitle2">생체반응 모니터링</Link>
  </Button>
)

export function ButtonUser() {
  // console.log('ButtonUser loggedInUser: ', carewellAuthApi.loggedInUser)
  const navigate = useNavigate();
  return (
    <Button
      sx={{
        color: 'primary.contrastText',
        // backgroundColor: 'white'
      }}
      size="small"
      onClick={() => {
        if (carewellAuthApi.getAccountType() === AccountType.Operator) {
          navigate('/dashboard/my-info/view')
        }
      }}
    >
      {`${carewellAuthApi.loggedInUser.name}`}
      {'   '}
      {/* <small>님 환영합니다</small> */}
      <span><small>님 환영합니다</small></span>
    </Button>
  )
}

export function ButtonLogout() {
  ///
  const {logout} = useAuth()
  const navigate = useNavigate()

  const handleLogout = async () => {
    // console.log('handleLogout -')
    await logout()
    await carewellAuthApi.logout()
    navigate('/')
  }

  return (
    <Button sx={{color: 'primary.contrastText'}} size="small" onClick={handleLogout}>로그아웃</Button>
  )
}
