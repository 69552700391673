export type SpeakerStatus =
  | 'stored' // 입고
  | 'released' // 출고
  | 'as' // AS
  | 'used' // 중고
  | 'disuse'; // 폐기

export type AiSpeakerStatus =
  | '정상' // ???
  | '입고'
  | '출고'
  | 'AS'
  | '중고'
  | '폐기';

export interface AiSpeakerHistory {
  ai_speaker_id: string;
  ai_speaker_sn: string;
  speaker_status: string;
  speaker_use_start_date: string;
  speaker_use_end_date: string;
  speaker_is_certified: string;
}

export interface AiSpeaker {
  // ai_speaker_id: string // ":"001",
  // ai_speaker_created_date: string // ":"2021-10-07 14:10:09",
  // ai_speaker_password: string // ":"1234",
  // ai_speaker_sn: string // ":"001",
  // ai_speaker_model_name: string // ":"model_name",
  // ai_speaker_regist_date: string // ":"2001.01.01",
  // ai_speaker_macaddress: string // ":"adfewfwefwf",
  // ai_speaker_status: string // ":"정상",
  // ai_speaker_network_status: string // ":"연결",
  // ai_speaker_certificate_yn: string // ":"인증",
  // ai_speaker_remark: string // ":"테스트기기",
  // ai_speaker_user_aggrement_yn: string // ":"동의",
  // ai_speaker_user_aggrement_date: string // ":"2001.01.01
  ai_speaker_history: AiSpeakerHistory[];
  ai_speaker_certificate_yn: string; // ""
  ai_speaker_created_date: string; //  "2021-12-03 06:40:29"
  ai_speaker_id: string; //  "ㅓㅗㅓㅓ"
  ai_speaker_installer_id: string; //  ""
  ai_speaker_macaddress: string; //  "ㅓㅓ"
  ai_speaker_model_name: string; //  "IF-H1100"
  ai_speaker_network_status: string; //  ""
  ai_speaker_password: string; //  "ㅓㅓㅓ"
  ai_speaker_regist_writer_id: string; //  ""
  ai_speaker_regist_writer_name: string; //  ""
  ai_speaker_remark: string; //  ""
  ai_speaker_sn: string; //  "ㅓㅓ"
  ai_speaker_affiliation: string; //  "ㅓㅓ"
  ai_speaker_smart_center: string;
  ai_speaker_status: string; //  "입고"
  ai_speaker_use_yn: string;
  ai_speaker_regist_yn: string;
  ai_speaker_regist_date: string;
  ai_speaker_user_aggrement_date: string; //  ""
  ai_speaker_user_aggrement_yn: string; //  ""
  admin_id: string;
  center_id: string;
  center_name: string;
  cpe_id: string;
  cpe_tel: string;
  cpe_reg_date: string;
}

export interface User {
  senior_center_speaker_sn : string;
  senior_center_user_create_date : string;
  senior_center_user_id : string;
  senior_center_user_status : string;
}

export interface AiSpeakerLoginInfo {
  // ai_speaker_model_name: string;
  ai_speaker_sn: string;
  ai_speaker_id: string;
  ai_speaker_password: string;
  password2: string;
  senior_center: string;
}

export const initialValuesAiSpeakerLoginInfo: AiSpeakerLoginInfo = {
  // ai_speaker_model_name: '',
  ai_speaker_sn: '',
  ai_speaker_id: '',
  ai_speaker_password: '',
  password2: '',
  senior_center: '',
}

export const initialValuesAiSpeaker: AiSpeaker = {
  ai_speaker_certificate_yn: '', // string // ""
  ai_speaker_created_date: '', //  string //  "2021-12-03 06:40:29"
  ai_speaker_id: '', //  string //  "ㅓㅗㅓㅓ"
  ai_speaker_installer_id: '', //  string //  ""
  ai_speaker_macaddress: '', //  string //  "ㅓㅓ"
  ai_speaker_model_name: 'IF-S1100', //  string
  ai_speaker_network_status: '', //  string //  ""
  ai_speaker_password: '', //  string //  "ㅓㅓㅓ"
  ai_speaker_regist_writer_id: '', //  string //  ""
  ai_speaker_regist_writer_name: '', //  string //  ""
  ai_speaker_remark: '', //  string //  ""
  ai_speaker_sn: '', //  string //  "ㅓㅓ"
  ai_speaker_affiliation: '',
  ai_speaker_smart_center: '',
  ai_speaker_status: '입고', // string
  ai_speaker_use_yn: '',
  ai_speaker_regist_yn: '',
  ai_speaker_user_aggrement_date: '', //  string //  ""
  ai_speaker_user_aggrement_yn: '', //  string //  ""
  ai_speaker_regist_date: '',
  admin_id: '',
  center_id: '',
  center_name: '',
  ai_speaker_history: [],
  cpe_id: '',
  cpe_tel: '',
  cpe_reg_date: '',
};

export interface SpeakerAddData {
  modelName: string;
  speakerId: string;
  speakerPassword: string;
  serialNumber: string;
  macAddress: string;
  createdAt: string;
  status: string; // SpeakerStatus
}

// export type SpeakerStatus =
//   | 'stored' // 입고
//   | 'released' // 출고
//   | 'as' // AS
//   | 'used' // 중고
//   | 'disuse' // 폐기
export function speakerStatusString(enumStr: string): string {
  switch (enumStr) {
    case 'stored':
      return '입고';
    case 'released':
      return '출고';
    case 'as':
      return 'AS';
    case 'used':
      return '중고';
    case 'disuse':
      return '폐기';
    default:
      return '입고';
  }
}

export interface ReqAiSpeakerCreate {
  ai_speaker_id: string; // " : "010o30333",
  ai_speaker_password: string; // "  : "oo",
  ai_speaker_sn: string; // "  : "oo",
  ai_speaker_model_name: string; // "  : "oo",
  ai_speaker_regist_date: string; // "  : "oo",
  ai_speaker_macaddress: string; // "  : "oo",
  ai_speaker_status: string; // "  : "oo",
  ai_speaker_network_status: string; // "  : "oo",
  ai_speaker_certificate_yn: string; // "  : "oo",
  ai_speaker_remark: string; // "  : "oo",
  ai_speaker_user_aggrement_yn: string; // "  : "oo",
  ai_speaker_user_aggrement_date: string; // "  : "oo"
}

export interface SpeakerStockData {
  state: string; // ": "ok",
  full_cnt: number; // ": "8",
  use_cnt: number;
  nouse_cnt: number;
  // stock_cnt: string; // ": "6",
  // sail_cnt: string; // ": "1",
  // as_cnt: string; // ": "0",
  // used_cnt: string; // ": "0",
  // release_cnt: string; // t": "0"
  // etc_cnt: string;
}

export const initialValuesSpeakerStock: SpeakerStockData = {
  state: '', // : string // ": "ok"
  full_cnt: 0, // ": "8"
  use_cnt: 0,
  nouse_cnt: 0,
};
