import { Button } from '@material-ui/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MODAL_TYPE, getDateToStringUTC } from 'src/__CareWell__/commons/commons';
import useAuth from 'src/hooks/useAuth';
import * as XLSX from 'xlsx-js-style';

interface Props<Type> {
  rows?: Type[],
  modalType : string,
  xlsxDisabled: boolean,
  setDownloadLoading: (isLoading:boolean)=>void,
  setDonwloadLoadingText: (text:string)=>void,
}

const SheetJSFT = [
  'xlsx', 'xlsb', 'xlsm', 'xls', 'xml', 'csv', 'txt', 'ods', 'fods', 'uos', 'sylk', 'dif', 'dbf', 'prn', 'qpw', '123', 'wb*', 'wq*', 'html', 'htm'
].map((x) => `.${x}`).join(',')

export default function StatisticsXlsxV2<Type>(props:Props<Type>) {
  const {rows, modalType, xlsxDisabled, setDownloadLoading, setDonwloadLoadingText} = props;
  const changeExcelData = (results) => {
    let list = [];
    setDownloadLoading(true)
    setDonwloadLoadingText('다운로드 중')
    setTimeout(() => {
      setDownloadLoading(false)
    }, 10000)
    switch (modalType) {
      case MODAL_TYPE.SENIOR:
        list = results && results.map((item, index) => ({경로당명: item.senior_center_name,
          이름: item.senior_name,
          성별: item.senior_gender,
          복약유무: item.senior_medi === '없음' ? '무' : '유',
          위험군대상자: item.senior_medi}))
        break;
      case MODAL_TYPE.PRESSURE:
        list = results && results.map((item, index) => ({소속기관: item.public_care_name,
          경로당명: item.senior_center_name,
          이름: item.senior_name,
          성별: item.senior_gender,
          혈압측정일자: getDateToStringUTC(new Date(item.measure_date)),
          수축기: item.systolic,
          이완기: item.diastole,
          맥박: item.pulse}))
        break;
      case MODAL_TYPE.SUGAR:
        list = results && results.map((item, index) => ({소속기관: item.public_care_name,
          경로당명: item.senior_center_name,
          이름: item.senior_name,
          성별: item.senior_gender,
          혈당측정일자: getDateToStringUTC(new Date(item.measure_date)),
          식전: item.classification === 'MES_BEFORE' ? item.value : '',
          식후1시간: item.classification === 'MES_AFTER1H' ? item.value : '',
          식후2시간: item.classification === 'MES_AFTER2H' ? item.value : ''}))
        break;
      case MODAL_TYPE.WEIGHT:
        list = results && results.map((item, index) => ({소속기관: item.public_care_name,
          경로당명: item.senior_center_name,
          이름: item.senior_name,
          성별: item.senior_gender,
          체지방측정일자: getDateToStringUTC(new Date(item.measure_date)),
          체중: item.weight,
          근육량: item.muscle_mass,
          체지방량: item.body_fat,
          BMI: item.bmi}))
        break;
      default:
        break;
    }
    return list;
  }
  const exportFile = async (data) => {
    let filename = '사용자정보'
    if (modalType === MODAL_TYPE.SENIOR) filename = '경로당사용자정보'
    else if (modalType === MODAL_TYPE.PRESSURE) filename = '혈압측정정보'
    else if (modalType === MODAL_TYPE.SUGAR) filename = '혈당측정정보'
    else if (modalType === MODAL_TYPE.WEIGHT) filename = '체지방측정정보'

    const excelRows = await changeExcelData(rows)
    const ws = XLSX.utils.json_to_sheet(excelRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const date = new Date();
    const filen = `${filename}${date.getTime()}.xlsx`;
    XLSX.writeFile(wb, filen);
    setDownloadLoading(false)
    setDonwloadLoadingText('다운로드 중')
  }
  useEffect(() => {
  }, [rows]);
  return (
    <div className="row">
      <div className="col-xs-12">
        <Button id="xlsxButton" variant="outlined" onClick={() => exportFile(rows)} disabled={xlsxDisabled}>
          {/* <Box component="img" sx={{height: 28, width: 30}} src="/static/icons/excel.svg" /> */}
          개별 엑셀 다운로드
        </Button>
      </div>
    </div>
  )
}
