export interface AttendInfo {
  name: any;
  all_count: any;
  attend_count: any;
}

export const getDateToString = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
}
