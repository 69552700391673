import {Typography, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core'
import {useEffect, useState} from 'react'

type Props = {
  rowsPerPageOptions: number[]
  rowsPerPage: number
  setRowsPerPage: (number) => void
  onRowsPerPageChange: (number) => void
}

export default function MyRowsPerPage(props: Props) {
  const {rowsPerPageOptions, rowsPerPage, setRowsPerPage, onRowsPerPageChange} = props

  const handleChange = (event) => {
    // setAge(event.target.value);
    const newRows = event.target.value
    console.log('> handleChange: ', newRows)
    onRowsPerPageChange(newRows)
    setRowsPerPage(newRows)
  }

  // useEffect(() => {
  //   console.log('rows: ', rowsPerPageOptions)
  // }, [])

  return (
    <FormControl variant="standard" sx={{ minWidth: 140 }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={rowsPerPage}
        onChange={handleChange}
        label="Age"
      >
        {
            rowsPerPageOptions.map((value, index) => <MenuItem key={value} value={value}>{`${value}개씩 보기`}</MenuItem>)
          }
      </Select>
    </FormControl>
  )
}
